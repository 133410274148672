import api from '@/services/api';
export default
{
    deletePregunta(uuid_pregunta){
        let url = `/api/preguntas/destroyPregunta/${uuid_pregunta}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    deleteImagenRespuesta(uuid_respuesta){
        let url = `/api/preguntas/destroyImagenRespuesta/${uuid_respuesta}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    deleteRespuesta(uuid_respuesta){
        let url = `/api/preguntas/destroyRespuesta/${uuid_respuesta}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    saveOptionMultipleUnicaRespuesta(formData){
        let url          = `/api/preguntas/saveOptionMultipleUnicaRespuesta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    desactivarRespuestaCorrecta(formData){
        let url          = `/api/preguntas/desactivarRespuestaCorrecta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    activarRespuestaCorrecta(formData){
        let url          = `/api/preguntas/activarRespuestaCorrecta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    getRespuestasByUuidPregunta(uuid_pregunta)
    {
        let url          = `/api/preguntas/respuestas/${uuid_pregunta}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getPreguntas()
    {
        let url          = `/api/preguntas/all`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getPreguntasDetallesExamen(uuid_examen)
    {
        let url          = `/api/preguntas/get-preguntas-detalles-examen/${uuid_examen}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    storeRespuesta(formData){
        let url          = `/api/preguntas/storeAnswer`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    deleteImagen(id){
        let url = `/api/preguntas/destroyImagen/${id}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    deleteAudio(id){
        let url = `/api/preguntas/destroyAudio/${id}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    storeFileImagenRespuesta(formData){
        let url          = `/api/preguntas/updateFileImagenRespuesta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    storeFileImagen(formData){
        let url          = `/api/preguntas/updateFileImagen`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    storeFileAudio(formData){
        let url          = `/api/preguntas/updateFileAudio`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    storeQuestion(formData){
        let url          = `/api/preguntas/storeQuestion`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },

    updateVideo(formData){
        let url          = `/api/preguntas/updateVideo`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
}