<template>
  <b-container fluid>
    <!--modal importar correos-->
        <b-modal no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
                 hide-footer persistent
                 ref="visibleModalImportarCorreos"
                 title="Importar correos electrónicos"
                 v-model="visibleModalImportarCorreos"
        >
          <b-alert v-model="visibleModalConfirmacion" variant="light" show>
            <b-button variant="primary" disabled>
              <b-spinner small type="grow"></b-spinner>
              {{ mensajeConfirmacion }}
            </b-button>
          </b-alert>
          <Notificacion ref="notificacionModalMensaje"/>
          <b-card
              tag="article"
              class="mb-2">

            <b-card-text>
              <b-row class="my-1">
                <b-col cols="4">
                  <label for="input-titulo">Nombre importación:</label>
                </b-col>
                <b-col cols="8">
                  <b-form-input
                      v-model="Form.nombre_importacion" id="nombre_importacion"
                      placeholder="Captura el nombre de la importación"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col cols="4">
                  <label for="input-titulo">Correos a importar:</label>
                </b-col>
                </b-row>
              <b-row class="my-1">
                <b-col cols="12">
                  <b-form-textarea
                      id="correos"
                      v-model="Form.correos"
                      placeholder="Capturar los correos a importar"
                      rows="5"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row class="justify-content-end">
                <div class="d-flex align-items-right">
                  <b-button
                      variant="success"
                      size="sm"
                      class="float-right mx-2 my-1"
                      @click="guardarImportacion()"
                      title="Guardar importacion"
                      :disabled="disabledBtnsImportarCorreos"
                  >
                    Guardar
                  </b-button>
                  <b-button
                      variant="primary"
                      size="sm"
                      class="float-right mx-2 my-1"
                      title="Cancelar"
                      @click="visibleModalImportarCorreos=false"
                      :disabled="disabledBtnsImportarCorreos"
                  >
                    Cancelar
                  </b-button>
                </div>
              </b-row>
            </b-card-text>
          </b-card>
        </b-modal>
    <!-- fin modal importar correos   -->

<!--modal dataTable-->
    <b-modal no-close-on-backdrop no-close-on-esc  centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             :title= tituloModalDataTable
             v-model="visibleModalDataTable"
    >
      <b-alert v-model="visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionModalMensaje"/>
      <b-card
          tag="article"
          class="mb-2">

        <b-card-text>
          <b-row class="d-flex align-items-end justify-content-end my-1">
            <div class="col-sm-12 my-2">
              <b-form-input
                  id="filter-input"
                  v-model="filterDataTableModal"
                  type="search"
                  placeholder="Buscar"
              >
              </b-form-input>
            </div>
          </b-row>
          <b-row class="justify-content-end">
            <b-col cols="12">

              <b-table class="table data-table"
                       :items="dataTableModal"
                       :fields="dataTableModalFields"
                       :current-page="currentPageDataTableModal"
                       :per-page="perPageDataTableModal"
                       :filter="filterDataTableModal"
                       :filter-included-fields="filterOnDataTableModal"
                       :striped="true"
                       hover
                       show-empty
                       :small="true"
              >
                <template #empty="scope">
                  <h5 class="text-center">
                    {{ scope.emptyText='Por el momento no existe información que mostrar.' }}
                  </h5>
                </template>
                <template #emptyfiltered="scope">
                  <h5 class="text-center">
                    {{ scope.emptyFilteredText='No existe información que coincida con los parámetros de búsqueda.' }}
                  </h5>
                </template>
                <template #cell(descripcion)="row">
                  <div v-html="row.value"></div>
                </template>
              </b-table>
              <div class="d-flex justify-content-between row p-2">
                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                  <div>
                    <b-pagination
                        v-model="currentPageDataTableModal"
                        :total-rows="totalRowsModal"
                        :per-page="perPageDataTableModal"
                        align="fill"
                        size="sm"
                        class="my-0"
                    >
                    </b-pagination>
                  </div>
                </div>
              </div>

            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>
<!--fin modal dataTable-->




    <!--modal confirmar eliminar imagen de respuesta-->
<!--    <b-modal no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title="Confirmar eliminar pregunta"
             v-model="visibleModalDataTable"
    >
      <b-alert v-model="visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionModalMensaje"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea eliminar la pregunta?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarPregunta()"
                  title="Eliminar pregunta"
                  :disabled="disabledBtnsEliminarPregunta"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="visibleModalDataTable=false"
                  :disabled="disabledBtnsEliminarPregunta"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>-->
    <!-- fin modal eliminar imagen de respuesta   -->
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body">
            <b-alert
                show
                variant="primary"
                dismissible>
              Importaciones
            </b-alert>
            <!--ventana confirmacion general-->
            <b-alert v-model="modalConfirmacion" variant="light" show>
              <b-button variant="primary" disabled>
                <b-spinner small type="grow"></b-spinner>
                {{ mensajeConfirmacion }}
              </b-button>
            </b-alert>
            <Notificacion ref="notificacion"/>
            <!--cerrar ventana confirmacion general-->
          </div>
          <div class="col-sm-12 my-2">
            <div>
              <b-card no-body>
                <b-tabs card>
                  <!--INICIO TAB1-->
                  <b-tab title="Dashboard" active>
                    <b-card-text>
                      <b-form>
                        <b-row class="justify-content-end">
                          <b-col sm="12" >
                          <div class="col-sm-12">
                            <b-button
                                variant="success"
                                size="sm"
                                @click="showModalImportarCorreos()"
                                title="Importar correos"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                   class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z"/>
                                <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z"/>
                              </svg>
                            </b-button>
                          </div>
                          </b-col>
                        </b-row>
                        <b-row class="d-flex align-items-end justify-content-end my-1">
                          <div class="col-sm-12 my-2">
                            <b-form-input
                                id="filter-input"
                                v-model="filterDataTable"
                                type="search"
                                placeholder="Buscar"
                            >
                            </b-form-input>
                          </div>
                        </b-row>
                        <b-row>
                          <b-col cols="12">

                            <b-table class="table data-table"
                                     :items="dataTable"
                                     :fields="dataTableFields"
                                     :current-page="currentPageDataTable"
                                     :per-page="perPageDataTable"
                                     :filter="filterDataTable"
                                     :filter-included-fields="filterOnDataTable"
                                     :striped="true"
                                     hover
                                     show-empty
                                     :small="true"
                            >
                              <template #empty="scope">
                                <h5 class="text-center">
                                  {{ scope.emptyText='Por el momento no hay preguntas que mostrar.' }}
                                </h5>
                              </template>
                              <template #emptyfiltered="scope">
                                <h5 class="text-center">
                                  {{ scope.emptyFilteredText='No hay preguntas que coincidan con los parámetros de búsqueda.' }}
                                </h5>
                              </template>
                              <template #cell(descripcion)="row">
                                <div v-html="row.value"></div>
                              </template>

                              <template #cell(actions)="row">
                                <div class="d-flex justify-content-around align-items-center">
                                  <b-button
                                      variant="primary"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="showModalDataTable(row.item)"
                                      class="mr-1"
                                      title="Correos importados">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
                                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z"/>                                    </svg>
                                  </b-button>

                                  <b-button
                                      variant="warning"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="showModalCorreosExistentes(row.item)"
                                      class="mr-1"
                                      title="Correos existentes">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
                                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1.5a.5.5 0 0 1-1 0V11a.5.5 0 0 1 1 0Zm0 3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"/>                                    </svg>
                                  </b-button>

                                </div>
                              </template>

                            </b-table>
                            <div class="d-flex justify-content-between row p-2">
                              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div>
                                  <b-pagination
                                      v-model="currentPageDataTable"
                                      :total-rows="totalRows"
                                      :per-page="perPageDataTable"
                                      align="fill"
                                      size="sm"
                                      class="my-0"
                                  >
                                  </b-pagination>
                                </div>
                              </div>
                            </div>

                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>
                  </b-tab>
                  <!--FIN TAB1-->

                </b-tabs>
              </b-card>
              <!--FIN DEL CUERPO TABS-->
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import toast from "@/mixins/ToastMixin.js";
import PreguntaService from "@/services/PreguntaService";
import Importaciones from "@/services/Importaciones";
import 'moment/locale/es';
import Notificacion from '@/components/Notificacion.vue'


export default {
  components: {
    Notificacion
  },
  mixins: [toast],
  data() {
    return {
      /* dataTable Modal*/
      dataTableModalFields: [
        //{label: '#', key: 'id', class: 'text-left'},
        {label: 'Correo', key: 'email', class: 'text-left'},
       // {label: 'Acciones', key: 'actions', class: 'text-center'}
      ],
      dataTableModal: [],
      totalRowsModal: 1,
      currentPageDataTableModal: 1,
      perPageDataTableModal: 9,
      filterDataTableModal: null,
      filterOnDataTableModal: [],
      tituloModalDataTable:null,
      /* dataTable Modal*/



      dataTableFields: [
        {label: '#', key: 'id', class: 'text-left'},
        {label: 'Importación', key: 'nombre_importacion', class: 'text-left'},
        {label: 'Acciones', key: 'actions', class: 'text-center'}
      ],
      totalRows: 1,
      currentPageDataTable: 1,
      perPageDataTable: 5,
      filterDataTable: null,
      filterOnDataTable: [],
      //reactivos
      modalConfirmacion: false,
      mensajeConfirmacion: null,
      dataTable: [],
      activo: false,

      visibleModalDataTable:false,
      visibleModalImportarCorreos:false,
      disabledBtnsImportarCorreos:false,
      visibleModalConfirmacion:false,
      uuid_preguntaEliminar:null,
      Form: {
        nombre_importacion: null,
        correos: null
      }

    }
  },
  created() {

  },
  mounted() {
    this.getImportaciones();
  },
  methods: {
    async guardarImportacion()
    {
      if (this.Form.nombre_importacion===null || this.Form.nombre_importacion.length === 0) {
        this.$refs.correos.$el.focus();
        this.showNotificacion('¡Debe capturar el nombre de la importación!', 'danger');
        return false;
      }
      else if (this.Form.correos===null || this.Form.correos.length === 0) {
        this.$refs.correos.$el.focus();
        this.showNotificacion('¡Debe capturar el nombre de la importación!', 'danger');
        return false;
      }
      this.disabledBtnsImportarCorreos = true
      let formData = new FormData();
      formData.append("nombre_importacion", this.Form.nombre_importacion);
      formData.append("correos", this.Form.correos);
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.visibleModalConfirmacion = true
      this.disabledBtnGuardarPregunta = true
      const response = await Importaciones.storeImportacion(formData);
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.dataTable = data
        this.totalRows = total;
      }
      this.visibleModalConfirmacion = false
      this.visibleModalImportarCorreos = false

      this.showNotificacion(message, color)
    },
    showModalImportarCorreos()
    {
      this.disabledBtnsImportarCorreos = false
      this.visibleModalImportarCorreos  = true
    },
    showModalDataTable(item){
      //console.log(item.users)
      this.filterOnDataTableModal = []
      this.dataTableModal = item.users
      this.totalRowsModal = this.dataTableModal.length
      this.tituloModalDataTable = "Correos importados"
      this.visibleModalDataTable = true
    },
    showModalCorreosExistentes(item){
     // console.log(item.correos_existentes)
      this.filterOnDataTableModal = []
      this.dataTableModal = item.correos_existentes != null ? item.correos_existentes.map((email) => ({ email })): []
      this.totalRowsModal =  item.correos_existentes != null ? item.correos_existentes.count : 0
      this.tituloModalDataTable = "Correos existentes"
      this.visibleModalDataTable = true
    },
    showNotificacion(message, tipo) {
      this.$refs.notificacion.mostrar(message, tipo, 3000)
    },
    abrirModalEliminarPregunta(uuid_pregunta){
      this.visibleModalDataTable=true
      this.uuid_preguntaEliminar = uuid_pregunta
    },
    async eliminarPregunta(){
      try {
        this.disabledBtnsEliminarPregunta = true
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.visibleModalConfirmacion = true
        const response = await PreguntaService.deletePregunta(this.uuid_preguntaEliminar);
        let {
          success,
          message,
          color
          ,preguntas
          ,cantidadPreguntas
        } = response.data;
        if (success == true) {
          //this.toast('b-toaster-bottom-center', true, message, color );
          this.showNotificacion(message, color)
          this.visibleModalDataTable = false;
          this.visibleModalConfirmacion = false

          this.dataTable = []
          this.dataTable = preguntas;
          this.totalRows = cantidadPreguntas;
          //queda pendiente eliminar fisicamente el archivo en
          //cloud storage
          //
        }
      } catch (error) {
        this.showNotificacion('Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      }
    },
    async getImportaciones() {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      this.dataTable = []
      const response = await Importaciones.getImportaciones();

      this.modalConfirmacion = false
      this.dataTable = response.data.data;
      this.totalRows = response.data.totalRegistros;
    }
    ,redirectPreguntaEdit(rowPregunta){
      let uuid_pregunta = rowPregunta.uuid_pregunta
      let descripcion = rowPregunta.descripcion
      let url_imagen = rowPregunta.url_imagen
      let url_audio = rowPregunta.url_audio
      if(rowPregunta.tiporeactivo_id===1) {
        this.$router.push({
          name: 'opcionMultipleUnaRespuesta',
          params: {uuid_pregunta, descripcion, url_imagen, url_audio}
        });
      }
      else if(rowPregunta.tiporeactivo_id===2) {
        this.$router.push({
          name: 'reactivoVariasRespuestas',
          params: {uuid_pregunta, descripcion, url_imagen, url_audio}
        });
      }
      else if(rowPregunta.tiporeactivo_id===3) {
        this.$router.push({
          name: 'reactivoRelacionarColumnas',
          params: {uuid_pregunta, descripcion, url_imagen, url_audio}
        });
      }
    }
  }


}
</script>
<style lang="scss" scoped>
$studioAPPColor: #952f81;
.btn-studioAPP {
  color: $studioAPPColor !important;
  border: 1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active {
  background-color: #dadada !important;
  color: white !important;
}
.btn-primary.active, .btn-primary:active {
  background-color: #FFA500; /* Color de fondo cuando es pulsado */
  border-color: #FFA500; /* Color de borde cuando es pulsado */
}
.vue-editor__wrapper {
  height: 100px;
}
#editor1,
#editor2 {
  height: 550px;
}
</style>