import api from '@/services/api';
export default {
    getConceptosbyUuid_pregunta_fk(uuid_pregunta_fk)
    {
        let url          = `/api/preguntas/reactivo-ordenamiento/${uuid_pregunta_fk}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    deleteConceptoReactivoOrdenamiento(formData){
        let url          = `/api/preguntas/reactivo-ordenamiento/delete-concepto`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    updateConceptoReactivoOrdenamiento(formData){
        let url          = `/api/preguntas/reactivo-ordenamiento/updateConcepto`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    updateIndexConceptoOrdenamiento(formData){
        let url          = `/api/preguntas/reactivo-ordenamiento/updateIndexConcepto`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    storeConceptoReactivoOrdenamiento(formData){
        let url          = `/api/preguntas/reactivo-ordenamiento/storeConcepto`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    storeReactivoOrdenamiento(formData){
        let url          = `/api/preguntas/reactivo-ordenamiento/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    getConceptos()
    {
        let url          = `/api/preguntas/reactivo-ordenamiento/get-Conceptos`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
}
