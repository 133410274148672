<template>
  <div class="row"><!--
    <div class="col-2">
      <div class="form-group">
        <div
            class="btn-group-vertical buttons"
            role="group"
            aria-label="Basic example"
        >
          <button class="btn btn-secondary" @click="add">Add</button>
          <button class="btn btn-secondary" @click="replace">Replace</button>
        </div>

        <div class="form-check">
          <input
              id="disabled"
              type="checkbox"
              v-model="enabled"
              class="form-check-input"
          />
          <label class="form-check-label" for="disabled">DnD enabled</label>
        </div>
      </div>
    </div>-->

    <div class="col-8">
      <h5>Describe el proceso para enviar un correo: {{ draggingInfo }}</h5>
<!--      <button class="btn btn-secondary" @click="show">ver</button>-->

      <draggable
          @input="onListChange"
          v-model="list"
          :disabled="!enabled"
          class="list-group"
          ghost-class="ghost"
          :move="checkMove"
          @start="dragging = true"
          @end="dragging = false"

      >
        <div
            class="list-group-item"
            v-for="element in list"
            :key="element.name"
        >
          {{ element.name }}
        </div>
      </draggable>
    </div>
<!--
    <rawDisplayer class="col-3" :value="list" title="List" />-->
  </div>
</template>

<script>
import draggable from "vuedraggable";
let id = 1;
export default {
  name: "simple",
  display: "Simple",
  order: 0,
  components: {
    draggable
  },
  data() {
    return {
      data:null,
      enabled: true,
      list: [
        { name: "Escribe el correo que enviarás", id: 0 },
        { name: "Da clic en el botón de Redactar", id: 1 },
        { name: "Abrir gmail", id: 2 },
        { name: "Da clic en el botón enviar", id: 3 },
        { name: "Llena los datos de destinatario y asunto", id: 4 },
        //{ name: "Abrir gmail", id: 5 },
      ],
      dragging: false
    };
  },
  mounted(){
    console.log("lista de elementos:")
    console.log(this.list)
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  methods: {
    add: function() {
      this.list.push({ name: "Juan " + id, id: id++ });
    },
    replace: function() {
      this.list = [{ name: "Edgard", id: id++ }];
    },
    checkMove(e) {
      console.log("Future index: " + e.draggedContext.futureIndex);
      console.log("element: " + e.draggedContext.element.id);
      //console.log("lista actual" + this.list)


    },
    onListChange(){
      console.log("lista" + JSON.stringify(this.list, null, 2));
    },
    show(){
      console.log("data")
      console.log(this.list)
    }
  }
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>