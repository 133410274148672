export default {
 methods: {
    toast(toaster, append = false,content,color)
    {
            this.counter++
            this.$bvToast.toast(content, {
                title: 'Avanza',
                autoHideDelay: 3000,
                toaster: toaster,
                variant: color,
                appendToast: append
            });
    },
    wait(){
         this.$bvToast.toast('Procesando...Favor de esperar', {
             title: 'Asiste',
             autoHideDelay: 3000,
             toaster: 'b-toaster-bottom-center',
             variant: 'info',
             appendToast: true,
             remaining:true
         });
    },
 },
}
