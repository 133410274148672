<template>
  <b-container fluid>
    <!--modal confirmar eliminar imagen de respuesta-->
    <b-modal no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalEliminarPregunta"
             title="Confirmar eliminar pregunta"
             v-model="modalEliminarPregunta"
    >
      <b-alert v-model="modalConfirmacionEliminarPregunta" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionEliminarPregunta"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea eliminar la pregunta?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarPregunta()"
                  title="Eliminar pregunta"
                  :disabled="disabledBtnsEliminarPregunta"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalEliminarPregunta=false"
                  :disabled="disabledBtnsEliminarPregunta"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>
    <!-- fin modal eliminar imagen de respuesta   -->
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body">
            <b-alert
                show
                variant="primary"
                dismissible>
              Dashboard de Exámenes
            </b-alert>
            <!--ventana confirmacion general-->
            <b-alert v-model="modalConfirmacion" variant="light" show>
              <b-button variant="primary" disabled>
                <b-spinner small type="grow"></b-spinner>
                {{ mensajeConfirmacion }}
              </b-button>
            </b-alert>
            <Notificacion ref="notificacion"/>
            <!--cerrar ventana confirmacion general-->
          </div>
          <div class="col-sm-12 my-2">
            <div>
              <b-card no-body>
                <b-tabs card>
                  <!--INICIO TAB1-->
                  <b-tab title="Exámenes" active>
                    <b-card-text>

                      <b-form>
                        <b-row>
                          <b-col cols="12">

                            <b-table class="table data-table"
                                     :items="resultSetExamenes"
                                     :fields="reactivos_fields"
                                     :current-page="currentPage_reactivos"
                                     :per-page="perPage_reactivos"
                                     :filter="filter_reactivos"
                                     :filter-included-fields="filterOn_reactivos"
                                     :striped="true"
                                     hover
                                     show-empty
                                     :small="true"
                            >
                              <template #empty="scope">
                                <h5 class="text-center">
                                  {{ scope.emptyText = 'Por el momento no hay preguntas que mostrar.' }}
                                </h5>
                              </template>
                              <template #cell(tiempo_minutos_responder)="row">
                                {{row.value}} min.
                              </template>
                              <template #cell(titulo)="row">
                                <div v-html="row.value"></div>
                              </template>
                              <template #cell(actions)="row">
                                <div class="d-flex justify-content-around align-items-center">
                                  <b-button
                                      variant="success"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="redirectExamenEdit(row.item)"
                                      class="mr-1"
                                      title="Editar Examen">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <path
                                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                      <path fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                    </svg>
                                  </b-button>
                                  <b-button
                                      variant="danger"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="abrirModalEliminarPregunta(row.item.uuid_pregunta)"
                                      class="mr-1"
                                      title="Eliminar pregunta">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                           class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                                      </svg>
                                    </svg>
                                  </b-button>

                                </div>
                              </template>

                            </b-table>
                            <div class="d-flex justify-content-between row p-2">
                              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div>
                                  <b-pagination
                                      v-model="currentPage_reactivos"
                                      :total-rows="totalRows"
                                      :per-page="perPage_reactivos"
                                      align="fill"
                                      size="sm"
                                      class="my-0"
                                  >
                                  </b-pagination>
                                </div>
                              </div>
                            </div>

                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>
                  </b-tab>
                  <!--FIN TAB1-->

                </b-tabs>
              </b-card>
              <!--FIN DEL CUERPO TABS-->
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import toast from "@/mixins/ToastMixin.js";
//import PreguntaService from "@/services/PreguntaService";
import ExamenService from "@/services/ExamenService";
import 'moment/locale/es';
import Notificacion from '@/components/Notificacion.vue'
//import crearExamen from "@/views/Examenes/crearExamen.vue";


export default {
  components: {
    Notificacion
  },
  mixins: [toast],
  data() {
    return {
      reactivos_fields: [
        {label: 'Título', key: 'titulo', class: 'text-left'},
        {label: 'Valor examen', key: 'valorExamen', class: 'text-center'},
        {label: 'Duración examen', key: 'tiempo_minutos_responder', class: 'text-center'},
        {label: 'Acciones', key: 'actions', class: 'text-center'}
      ],
      totalRows: 1,
      currentPage_reactivos: 1,
      perPage_reactivos: 5,
      filter_reactivos: null,
      filterOn_reactivos: [],
      //reactivos
      modalConfirmacion: false,
      mensajeConfirmacion: null,
      resultSetExamenes: [],
      disabledBtnInscribirse: false,
      activo: false,
      modalEditarSesion: false,
      modalAbrirEncuesta: false,
      nombre_sesion_actual: null,
      sesion_id_actual: null,
      modalDeleteSesion: false,

      modalEliminarPregunta: false,
      modalConfirmacionEliminarPregunta: false,
      disabledBtnsEliminarPregunta: false,
      uuid_preguntaEliminar: null,

    }
  },
  created() {
    this.objAudio = new Audio();
  },

  methods: {
    showNotificacion(message, tipo) {
      this.$refs.notificacion.mostrar(message, tipo, 3000)
    },
    abrirModalEliminarPregunta(uuid_pregunta) {
      this.modalEliminarPregunta = true
      this.uuid_preguntaEliminar = uuid_pregunta
    },
    async getExamenes() {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      this.resultSetExamenes = []
      const response = await ExamenService.getExamenes();
      this.modalConfirmacion = false
      this.resultSetExamenes = response.data.examenes;
      this.totalRows = response.data.cantidadExamenes;
    }
    , redirectExamenEdit(row) {
      let id = row.id
      let tiempo_minutos_responder = row.tiempo_minutos_responder
      let titulo = row.titulo
      let uuid_examen = row.uuid_examen
      let valorExamen = row.valorExamen
      let instrucciones = row.instrucciones
      //console.log(row)
      this.$router.push({
        name: 'crearExamen',
        params: {
          id,
          tiempo_minutos_responder,
          titulo,
          uuid_examen,
          valorExamen,
          instrucciones
        }
      });
    }
  },
  mounted() {
    this.getExamenes();
  },

}
</script>
<style lang="scss" scoped>
$studioAPPColor: #952f81;
.btn-studioAPP {
  color: $studioAPPColor !important;
  border: 1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active {
  background-color: #dadada !important;
  color: white !important;
}

.btn-primary.active, .btn-primary:active {
  background-color: #FFA500; /* Color de fondo cuando es pulsado */
  border-color: #FFA500; /* Color de borde cuando es pulsado */
}

.vue-editor__wrapper {
  height: 100px;
}

#editor1,
#editor2 {
  height: 550px;
}
</style>