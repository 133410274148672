import api from '@/services/api';
export default {
    getRespuestasColumnaAByUuidPregunta(uuid_columnaA)
    {
        let url          = `/api/preguntas/reactivoColumna/getRespuestasColumnaA/${uuid_columnaA}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getPreguntas(uuid_reactivo_fk)
    {
        let url          = `/api/preguntas/reactivo-columna/get-preguntas/${uuid_reactivo_fk}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getRespuestas(uuid_reactivo_fk)
    {
        let url          = `/api/preguntas/reactivo-columna/get-respuestas/${uuid_reactivo_fk}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    storeReactivoColumnaA(formData){
        let url          = `/api/preguntas/reactivo-columna/store-columnaA`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },

    storeRespuestaReactivoColumnaA(formData){
        let url          = `/api/preguntas/reactivo-columna/store-respuesta-columnaA`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },


    storeRespuestas(formData){
        let url          = `/api/preguntas/reactivo-columna/store-respuestas`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    deleteRespuesta(formData){
        let url          = `/api/preguntas/reactivo-columna/delete-respuesta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    deletePregunta(formData){
        let url          = `/api/preguntas/reactivo-columna/delete-pregunta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
}
