<template>
  <b-container fluid>
    <b-modal id="EliminarConcepto" no-close-on-backdrop no-close-on-esc centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title="Eliminar concepto"
             v-model="FormModalEliminarConcepto.visibleModalCrearConcepto"
    >
      <b-alert v-model="FormModalEliminarConcepto.visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ FormModalEliminarConcepto.mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <NotificacionModal ref="notificacionModal"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row class="my-1">
            <b-col cols="12">
              <label for="label-concepto-ordenamiento">¿Desea eliminar el concepto: {{FormModalEliminarConcepto.concepto}}?</label>
            </b-col>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarConcepto()"
                  title="Eliminar concepto"
                  :disabled="FormModalEliminarConcepto.disabledBtns"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="FormModalEliminarConcepto.visibleModalCrearConcepto=false"
                  :disabled="FormModalEliminarConcepto.disabledBtns"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="EditarConcepto" no-close-on-backdrop no-close-on-esc centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title="Actualizar concepto"
             v-model="FormModalEditConcepto.visibleModalCrearConcepto"
    >
      <b-alert v-model="FormModalEditConcepto.visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ FormModalEditConcepto.mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <NotificacionModal ref="notificacionModal"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="label-concepto-ordenamiento">Concepto:</label>
            </b-col>
            <b-col cols="8">
              <b-form-input
                  v-model="FormModalEditConcepto.concepto" id="concepto_ordenamiento"
                  placeholder="Captura el concepto"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="actualizarConcepto()"
                  title="Actualizar concepto"
                  :disabled="FormModalEditConcepto.disabledBtns"
              >
                Guardar
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="FormModalEditConcepto.visibleModalCrearConcepto=false"
                  :disabled="FormModalEditConcepto.disabledBtns"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>
    <b-modal id="CrearConcepto" no-close-on-backdrop no-close-on-esc centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title="Crear concepto"
             v-model="FormModalCrearConcepto.visibleModalCrearConcepto"
    >
      <b-alert v-model="FormModalCrearConcepto.visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ FormModalCrearConcepto.mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <NotificacionModal ref="notificacionModal"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="label-concepto-ordenamiento">Concepto:</label>
            </b-col>
            <b-col cols="8">
              <b-form-input
                  v-model="FormModalCrearConcepto.concepto" id="concepto_ordenamiento"
                  placeholder="Captura el concepto"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarConcepto()"
                  title="Guardar concepto"
                  :disabled="FormModalCrearConcepto.disabledBtns"
              >
                Guardar
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="FormModalCrearConcepto.visibleModalCrearConcepto=false"
                  :disabled="FormModalCrearConcepto.disabledBtns"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>


    <b-row id="Dashboard">
      <b-col cols="12">
        <div class="card card-block">
          <div id="AlertGeneral" class="card-body">
            <b-alert
                show
                variant="primary"
                >
              Agregar Pregunta de ordenamiento
            </b-alert>
            <!--ventana confirmacion general-->
            <b-alert v-model="modalConfirmacion" variant="light" show>
              <b-button variant="primary" disabled>
                <b-spinner small type="grow"></b-spinner>
                {{ mensajeConfirmacion }}
              </b-button>
            </b-alert>
            <Notificacion ref="notificacion"/>

            <!--cerrar ventana confirmacion general-->
          </div>
          <div id="tabGeneral " class="col-sm-12 my-2">
            <div>
              <b-card no-body>
                <b-tabs card>
                  <!--INICIO TAB1-->
                  <b-tab id="tabPlanteamiento" title="Planteamiento" active>
                    <b-card-text>
                      <b-form>

                        <b-row id="textAreaPlanteamiento">
                          <b-col cols="12">
                            <div id="editor1ED">
<!--                              <vue-editor v-model="Form.descripcion"
                                          ref="descripcion"
                                          id="editor1"
                                          :editorToolbar="customToolbar"
                                          placeholder="Capturar el planteamiento del reactivo"
                                          :style="{padding:'10px'}"
                              >
                              </vue-editor>-->
                              <b-form-textarea
                                  id="formDescripcion"
                                  ref="formDescripcion"
                                  v-model="Form.descripcion"
                                  placeholder="Capturar el planteamiento"
                                  rows="4"
                                  max-rows="6"
                              ></b-form-textarea>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row id="botonesFormulario" class="justify-content-end">
                          <div class="d-flex align-items-right">
                            <b-button
                                variant="success"
                                size="sm"
                                class="float-right mx-2 my-1"
                                @click="guardarPlanteamiento()"
                                title="Guardar Pregunta"
                                :disabled="Form.disabledBtns"
                            >
                              Guardar
                            </b-button>
                            <b-button
                                variant="primary"
                                size="sm"
                                class="float-right mx-2 my-1"
                                title="Cancelar"
                                @click="resetFormReactivoOrdenamiento"
                                v-if="Form.visibleBtnNuevaPregunta===true"
                            >
                              Nueva Pregunta
                            </b-button>
                          </div>
                        </b-row>
                      </b-form>
                    </b-card-text>
                  </b-tab>

                  <b-tab id="tabConceptos" title="Conceptos" :disabled="FormModalCrearConcepto.disabledTabConcepto">
                    <b-card-text>
                      <b-form>
                        <b-row class="justify-content-end">
                          <b-col sm="12">
                            <div class="col-sm-12">
                              <b-button
                                  variant="success"
                                  size="sm"
                                  @click="showModalCrearConcepto()"
                                  title="Crear concepto"
                              >
                                Agregar concepto
<!--                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                     class="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                                </svg>-->
                              </b-button>
                            </div>
                          </b-col>
                        </b-row>

<!--                        <b-row class="d-flex align-items-end justify-content-end my-1">
                          <div class="col-sm-12 my-2">
                            <b-form-input
                                id="filter-input-dashboard"
                                v-model="FormModalCrearConcepto.filter"
                                type="search"
                                placeholder="Buscar"
                                :disabled="FormModalCrearConcepto.disabledTable"
                            >
                            </b-form-input>
                          </div>
                        </b-row>-->
                        <b-row class="mt-2">
                          <b-col cols="12">

                            <b-table class="table data-table"
                                     :items="FormModalCrearConcepto.data"
                                     :fields="FormModalCrearConcepto.fields"
                                     :current-page="FormModalCrearConcepto.currentPage"
                                     :per-page="FormModalCrearConcepto.perPage"
                                     :filter="FormModalCrearConcepto.filter"
                                     :filter-included-fields="FormModalCrearConcepto.filterOn"
                                     :striped="true"
                                     hover
                                     show-empty
                                     :small="true"
                                     :disabled="FormModalCrearConcepto.disabledTable"
                            >
                              <template #empty="scope">
                                <h5 class="text-center">
                                  {{ scope.emptyText = 'Por el momento no existen conceptos que mostrar.' }}
                                </h5>
                              </template>
                              <template #emptyfiltered="scope">
                                <h5 class="text-center">
                                  {{
                                    scope.emptyFilteredText = 'No existen conceptos que coincidan con los parámetros de búsqueda.'
                                  }}
                                </h5>
                              </template>
                              <template #cell(concepto)="row">
                                <div v-html="row.value"></div>
                              </template>
                              <template #cell(actions)="row">
                                <div class="d-flex align-content-end">
                                  <b-button
                                      variant="success"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="showModalEditarConcepto(row.item)"
                                      class="mr-1"
                                      title="Editar concepto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                           class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                      </svg>
                                    </svg>
                                  </b-button>

                                  <b-button
                                      variant="danger"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="showModalEliminarConcepto(row.item)"
                                      class="mr-1"
                                      title="Eliminar concepto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                           class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                                      </svg>
                                    </svg>
                                  </b-button>

                                </div>
                              </template>

                            </b-table>
                            <div class="d-flex justify-content-between row p-2">
                              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div>
                                  <b-pagination
                                      v-model="FormModalCrearConcepto.currentPage"
                                      :total-rows="FormModalCrearConcepto.totalRows"
                                      :per-page="FormModalCrearConcepto.perPage"
                                      align="fill"
                                      size="sm"
                                      class="my-0"
                                  >
                                  </b-pagination>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>
                  </b-tab>

                  <b-tab id="tabOrdenamiento" title="Ordenamiento" :disabled="FormOrdenamiento.disabledTabOrdenamiento">
                    <b-card-text>
                      <b-form>
                        <b-alert
                            show
                            variant="info"
                        >
                          Arrastra cada elemento a su orden correcto, dentro de la lista:
                        </b-alert>
                        <b-alert v-model="FormOrdenamiento.visibleModalConfirmacion" variant="light" show>
                          <b-button variant="primary" disabled>
                            <b-spinner small type="grow"></b-spinner>
                            {{ FormOrdenamiento.mensajeConfirmacion }}
                          </b-button>
                        </b-alert>

                        <b-row class="justify-content-end">
                          <b-col sm="12">
                            <draggable
                                @input="actualizarIndexElemento"
                                v-model="FormOrdenamiento.list"
                                :disabled="FormOrdenamiento.disabled"
                                class="list-group"
                                ghost-class="ghost"
                                @start="dragging = true"
                                @end="dragging = false"
                            >
<!--                              <draggable
                                @input="actualizarIndexElemento"
                                v-model="FormOrdenamiento.list"
                                :disabled="FormOrdenamiento.disabled"
                                :move="checkMove"
                                class="list-group"
                                ghost-class="ghost"
                                @start="dragging = true"
                                @end="dragging = false"
                            >-->
                              <div
                                  class="list-group-item"
                                  v-for="element in this.FormOrdenamiento.list"
                                  :key="element.id"
                              >
                                {{ element.concepto }}
                              </div>
                            </draggable>
                          </b-col>
                        </b-row>

                      </b-form>
                    </b-card-text>
                  </b-tab>


                </b-tabs>
              </b-card>
              <!--FIN DEL CUERPO TABS-->
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
//import toast from "@/mixins/ToastMixin.js";
import Grupos from "@/services/Grupos";
import 'moment/locale/es';
import Notificacion from '@/components/Notificacion.vue'
//import {VueEditor} from "vue2-editor";
import ReactivoOrdenamientoService from "@/services/ReactivoOrdenamiento";
import NotificacionModal from "@/components/NotificacionModal.vue";
import draggable from "vuedraggable";

export default {
  components: {
    NotificacionModal,
    Notificacion,
    //VueEditor,
    draggable
  },
//  mixins: [toast],
  data() {
    return {
      Form: {
        descripcion: (this.$route.params.descripcion) ? (this.$route.params.descripcion) : null,
        disabledBtns: false,
        uuid_pregunta: (this.$route.params.uuid_pregunta) ? (this.$route.params.uuid_pregunta) : 0,
        tiporeactivo_id: 4,  //reactivo ordenamiento
        textobtnGuardar: "Guardar",
        visibleBtnNuevaPregunta:false,
      },
      FormModalCrearConcepto:
          {
            concepto: null,
            visibleModalCrearConcepto: false,
            visibleModalConfirmacion: false,
            mensajeConfirmacion: null,
            disabledBtns: false,
            filter: null,
            data: [],
            fields: [
              {label: 'Concepto', key: 'concepto', class: 'text-left'},
              {label: 'Acciones', key: 'actions', class: 'text-left'}
            ],
            currentPage: 1,
            perPage: 5,
            filterOn: [],
            totalRows: 0,
            disabledTabConcepto:true,
            disabledTable:true,
          },
      FormModalEditConcepto:
          {
            concepto:null,
            visibleModalCrearConcepto: false,
            visibleModalConfirmacion: false,
            mensajeConfirmacion: null,
            disabledBtns: false,
            uuid_concepto:null,
            uuid_pregunta_fk:null
          },
      FormModalEliminarConcepto:
          {
            concepto:null,
            visibleModalCrearConcepto: false,
            visibleModalConfirmacion: false,
            mensajeConfirmacion: null,
            disabledBtns: false,
            uuid_concepto:null,
            uuid_pregunta_fk:null
          },
      FormOrdenamiento: {
        disabled: false,
        list: [],
        visibleModalConfirmacion: false,
        mensajeConfirmacion: null,
        disabledTabOrdenamiento:true
      },
      /* dataTable Modal*/
      visibleModalGruposCorreo: false,
      dataTableModal: [],
      totalRowsModal: 1,
      currentPageDataTableModal: 1,
      perPageDataTableModal: 9,
      filterDataTableModal: null,
      filterOnDataTableModal: [],
      tituloModalDataTable: null,
      selectedIds: [],

      totalRows: 1,
      currentPageDataTable: 1,
      perPageDataTable: 5,
      filterDataTable: null,
      filterOnDataTable: [],
      //reactivos
      modalConfirmacion: false,
      mensajeConfirmacion: null,
      dataTable: [],
      activo: false,
      importacionSelected: null,
      idImportacionSelected: null,
      idGrupoSelected: null,
      dataImportaciones: [],
      visibleModalDataTable: false,
      visibleModalCrearGrupo: false,
      disabledBtnsCrearGrupo: false,
      visibleModalConfirmacion: false,
      uuid_preguntaEliminar: null,

      tituloGrupo: null,
      customToolbar:
          [
            [{header: [false, 1, 2, 3, 4, 5, 6]}],
            ["bold", "italic", "underline", "strike"],
            [{color: []}],
            [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
            [
              {align: ""},
              {align: "center"},
              {align: "right"},
              {align: "justify"}
            ],
            [{indent: "-1"}, {indent: "+1"}],
            ["clean"]
          ],

      dragging: false,
      data: null,
      enabled: true,

    }
  },
  created() {

  },
  mounted() {
    if (this.$route.params.uuid_pregunta !== undefined) {
      this.getConceptosUuid_pregunta(this.$route.params.uuid_pregunta)
    }
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  methods: {
    async getConceptosUuid_pregunta($uuid_pregunta_fk){
      this.Form.visibleBtnNuevaPregunta = true

      this.mensajeConfirmacion = "Favor de esperar un momento, procesando información...";
      this.modalConfirmacion = true
      const response = await ReactivoOrdenamientoService.getConceptosbyUuid_pregunta_fk($uuid_pregunta_fk);
      //console.log(response)
      this.modalConfirmacion = false
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalCrearConcepto.disabledTabConcepto = false
        this.FormModalCrearConcepto.data = data
        this.FormOrdenamiento.list = []
        this.FormModalCrearConcepto.data.forEach(concepto => {
          this.FormOrdenamiento.list.push(concepto);
        });
        this.FormModalCrearConcepto.totalRows = total
        if(this.FormModalCrearConcepto.totalRows>=1){
          this.FormModalCrearConcepto.disabledTable = false
          this.FormOrdenamiento.disabledTabOrdenamiento = false
        }
        else{
          this.FormModalCrearConcepto.disabledTable = true
          this.FormOrdenamiento.disabledTabOrdenamiento = true
        }
        this.showNotificacion(message, color);
      }
    },
    resetFormReactivoOrdenamiento(){
      this.FormModalCrearConcepto.disabledTable = true
      this.FormModalCrearConcepto.concepto=null
      this.FormModalCrearConcepto.visibleModalCrearConcepto=false
      this.FormModalCrearConcepto.visibleModalConfirmacion=false
      this.FormModalCrearConcepto.mensajeConfirmacion=null
      this.FormModalCrearConcepto.disabledBtns=false
      this.FormModalCrearConcepto.filter=null
      this.FormModalCrearConcepto.data=[]
      this.FormModalCrearConcepto.currentPage=1
      this.FormModalCrearConcepto.perPage=5
      this.FormModalCrearConcepto.filterOn=[]
      this.FormModalCrearConcepto.totalRows=0

      this.FormModalEditConcepto.concepto=null
      this.FormModalEditConcepto.visibleModalCrearConcepto= false
      this.FormModalEditConcepto.visibleModalConfirmacion= false
      this.FormModalEditConcepto.mensajeConfirmacion= null
      this.FormModalEditConcepto.disabledBtns= false
      this.FormModalEditConcepto.uuid_concepto=null
      this.FormModalEditConcepto.uuid_pregunta_fk=null

      this.FormOrdenamiento.disabled= false
      this.FormOrdenamiento.list= []
      this.FormOrdenamiento.visibleModalConfirmacion= false
      this.FormOrdenamiento.mensajeConfirmacion= null

      this.FormModalEliminarConcepto.concepto=null
      this.FormModalEliminarConcepto.visibleModalCrearConcepto= false
      this.FormModalEliminarConcepto.visibleModalConfirmacion= false
      this.FormModalEliminarConcepto.mensajeConfirmacion= null
      this.FormModalEliminarConcepto.disabledBtns= false
      this.FormModalEliminarConcepto.uuid_concepto=null
      this.FormModalEliminarConcepto.uuid_pregunta_fk=null

      this.Form.descripcion= null
      this.Form.disabledBtns= false
      this.Form.uuid_pregunta= 0
      this.Form.tiporeactivo_id= 4
      this.Form.textobtnGuardar= "Guardar"

      this.dragging= false
      this.data= null
      this.enabled= true

      this.Form.visibleBtnNuevaPregunta = false
      this.FormOrdenamiento.disabledTabOrdenamiento = true
      this.FormModalCrearConcepto.disabledTabConcepto = true
    },
    checkMove(e) {
      console.log("Future index: " + e.draggedContext.futureIndex);
      console.log("element: " + e.draggedContext.element.id);
    },

    async actualizarIndexElemento() {
      let formData = new FormData();
      formData.append("uuid_pregunta_fk", this.Form.uuid_pregunta);
      formData.append("lista_conceptos", JSON.stringify(this.FormOrdenamiento.list));
      this.FormOrdenamiento.disabled = true
      this.FormOrdenamiento.mensajeConfirmacion = "Favor de esperar un momento, actualizando ordenamiento...";
      this.FormOrdenamiento.visibleModalConfirmacion = true
      const response = await ReactivoOrdenamientoService.updateIndexConceptoOrdenamiento(formData);
      this.FormOrdenamiento.visibleModalConfirmacion = false
      let {
        success,
        message,
        color,
          data,
          total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalCrearConcepto.data = data
        this.FormOrdenamiento.list = []
        this.FormModalCrearConcepto.data.forEach(concepto => {
          this.FormOrdenamiento.list.push(concepto);
        });
        this.FormModalCrearConcepto.totalRows = total
        this.showNotificacion(message, color);
        this.FormOrdenamiento.disabled = false

      }
    }
    , show() {
      console.log("data")

    },
    showModalEditarConcepto(item){
      this.FormModalEditConcepto.visibleModalCrearConcepto = true
      this.FormModalEditConcepto.concepto = item.concepto
      this.FormModalEditConcepto.uuid_concepto = item.uuid_concepto
      this.FormModalEditConcepto.uuid_pregunta_fk = item.uuid_pregunta_fk
    },
    showModalEliminarConcepto(item) {
      this.FormModalEliminarConcepto.visibleModalCrearConcepto = true
      this.FormModalEliminarConcepto.concepto = item.concepto
      this.FormModalEliminarConcepto.uuid_concepto = item.uuid_concepto
      this.FormModalEliminarConcepto.uuid_pregunta_fk = item.uuid_pregunta_fk
    },
    resetFormModalCrearConcepto() {
      this.FormModalCrearConcepto.concepto = null
      this.FormModalCrearConcepto.visibleModalConfirmacion = false
      this.FormModalCrearConcepto.filter = null
      this.FormModalCrearConcepto.disabledBtns = false
      this.FormModalCrearConcepto.data = []
      this.FormModalCrearConcepto.currentPage = 1
      this.FormModalCrearConcepto.perPage = 5
      this.FormModalCrearConcepto.filterOn = []
      this.FormModalCrearConcepto.totalRows = 0

    },
    async showModalCrearConcepto() {
      this.FormModalCrearConcepto.visibleModalCrearConcepto = true
      this.resetFormModalCrearConcepto()
    },
    async eliminarConcepto()
    {
      this.FormModalEliminarConcepto.disabledBtns = true
      this.FormModalEliminarConcepto.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormModalEliminarConcepto.visibleModalConfirmacion = true
      let formData = new FormData();

      formData.append("uuid_concepto", this.FormModalEliminarConcepto.uuid_concepto);
      formData.append("uuid_pregunta_fk", this.FormModalEliminarConcepto.uuid_pregunta_fk);
      const response = await ReactivoOrdenamientoService.deleteConceptoReactivoOrdenamiento(formData);
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalEliminarConcepto.concepto = null
        this.FormModalEliminarConcepto.visibleModalConfirmacion = false
        this.FormModalEliminarConcepto.disabledBtns = false

        this.FormModalCrearConcepto.data = data
        this.FormOrdenamiento.list = []
        this.FormModalCrearConcepto.data.forEach(concepto => {
          this.FormOrdenamiento.list.push(concepto);
        });
        this.FormModalCrearConcepto.totalRows = total
        this.FormModalEliminarConcepto.visibleModalCrearConcepto = false
        this.showNotificacion(message, color);
      }
    },
    async actualizarConcepto()
    {
      if (this.FormModalEditConcepto.concepto === null || this.FormModalEditConcepto.concepto === 0) {
        this.showNotificacionModal('¡Debe capturar el concepto!', 'danger');
        return false;
      }
      this.FormModalEditConcepto.disabledBtns = true
      this.FormModalEditConcepto.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormModalEditConcepto.visibleModalConfirmacion = true
      let formData = new FormData();
      formData.append("concepto", this.FormModalEditConcepto.concepto);
      formData.append("uuid_concepto", this.FormModalEditConcepto.uuid_concepto);
      formData.append("uuid_pregunta_fk", this.FormModalEditConcepto.uuid_pregunta_fk);
      const response = await ReactivoOrdenamientoService.updateConceptoReactivoOrdenamiento(formData);
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalEditConcepto.concepto = null
        this.FormModalEditConcepto.visibleModalConfirmacion = false
        this.FormModalEditConcepto.disabledBtns = false

        this.FormModalCrearConcepto.data = data
        this.FormOrdenamiento.list = []
        this.FormModalCrearConcepto.data.forEach(concepto => {
          this.FormOrdenamiento.list.push(concepto);
        });
        this.FormModalCrearConcepto.totalRows = total
        this.FormModalEditConcepto.visibleModalCrearConcepto = false
        this.showNotificacion(message, color);
      }
    },
    async guardarConcepto() {
      if (this.FormModalCrearConcepto.concepto === null || this.FormModalCrearConcepto.concepto === 0) {
        this.showNotificacion('¡Debe capturar el concepto!', 'danger');
        return false;
      }
      this.FormModalCrearConcepto.disabledBtns = true
      this.FormModalCrearConcepto.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormModalCrearConcepto.visibleModalConfirmacion = true
      let formData = new FormData();
      formData.append("concepto", this.FormModalCrearConcepto.concepto);
      formData.append("uuid_pregunta_fk", this.Form.uuid_pregunta);
      const response = await ReactivoOrdenamientoService.storeConceptoReactivoOrdenamiento(formData);
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalCrearConcepto.concepto = null
        this.FormModalCrearConcepto.visibleModalConfirmacion = false
        this.FormModalCrearConcepto.disabledBtns = false
        this.FormModalCrearConcepto.data = data
        this.FormOrdenamiento.list = []
        this.FormModalCrearConcepto.data.forEach(concepto => {
          this.FormOrdenamiento.list.push(concepto);
        });
        this.FormModalCrearConcepto.totalRows = total
        if(this.FormModalCrearConcepto.totalRows>=1){
          this.FormModalCrearConcepto.disabledTable = false
        }
        else{
          this.FormModalCrearConcepto.disabledTable = true
        }
        this.showNotificacionModal(message, color);
      }
    },


    async guardarPlanteamiento() {
      if (this.Form.descripcion === null || this.Form.descripcion.length === 0) {
        this.showNotificacion('¡Debe capturar el planteamiento!', 'danger');
        this.$refs.formDescripcion.focus();
        return false;
      }
      this.Form.disabledBtns = true
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true

      let formData = new FormData();
      formData.append("descripcion", this.Form.descripcion);
      formData.append("uuid_pregunta", this.Form.uuid_pregunta);
      formData.append("tiporeactivo_id", this.Form.tiporeactivo_id);

      const response = await ReactivoOrdenamientoService.storeReactivoOrdenamiento(formData);
      let {
        success,
        uuid_pregunta,
        message,
        color
      } = response.data;
      if (success) {
        this.Form.textobtnGuardar = "Actualizar"
        this.Form.disabledBtns = false
        this.Form.uuid_pregunta = uuid_pregunta
        this.Form.visibleBtnNuevaPregunta = true

        this.modalConfirmacion = false
        this.disabledBtnGuardarPregunta = false

        this.FormModalCrearConcepto.disabledTabConcepto = false
        this.FormOrdenamiento.disabledTabOrdenamiento = false
        this.showNotificacion(message, color);
      }
    },
    cancelar() {
      this.Form.descripcion = null
    },
    async guardarGrupo() {
      if (this.Form.nombre_grupo === null || this.Form.nombre_grupo.length === 0) {
        this.$refs.nombre_grupo.$el.focus();
        this.showNotificacion('¡Debe capturar el nombre del grupo!', 'danger');
        return false;
      }
      this.disabledBtnsCrearGrupo = true
      let formData = new FormData();
      formData.append("nombre_grupo", this.Form.nombre_grupo);
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.visibleModalConfirmacion = true
      this.disabledBtnGuardarPregunta = true
      const response = await Grupos.storeGrupo(formData);
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.dataTable = data
        this.totalRows = total;
      }
      this.visibleModalConfirmacion = false
      this.visibleModalCrearGrupo = false

      this.showNotificacion(message, color)
    },
    showModalCrearGrupo() {
      this.disabledBtnsCrearGrupo = false
      this.visibleModalCrearGrupo = true
    },
    async showModalDataTable(item) {
      this.mensajeConfirmacion = "Favor de esperar un momento, procesando información..."
      this.modalConfirmacion = true
      //console.log(item)
      this.dataImportaciones = []
      this.importacionSelected = null
      this.idGrupoSelected = null
      this.tituloGrupo = item.nombre_grupo
      this.idGrupoSelected = item.id
      const response = await Grupos.getImportaciones();
      this.dataImportaciones = response.data.data
      this.modalConfirmacion = false

      console.log("grupo selected---->")
      console.log(item)
      //console.log(item.users)
      /*      this.filterOnDataTableModal = []
            this.dataTableModal = item.users
            this.totalRowsModal = this.dataTableModal.length*/
      this.tituloModalDataTable = "Agregar correos desde importación"
      this.visibleModalDataTable = true

    },

    async showModalCorreosGrupo(item) {
      this.mensajeConfirmacion = "Favor de esperar un momento, procesando información..."
      this.modalConfirmacion = true
      //console.log(item)
      this.dataImportaciones = []
      this.importacionSelected = null
      this.idGrupoSelected = null
      this.tituloGrupo = item.nombre_grupo
      this.idGrupoSelected = item.id
      const response = await Grupos.getCorreosGrupo(this.idGrupoSelected)
      this.dataTableModal = []
      this.dataTableModal = response.data.data
      this.modalConfirmacion = false

      this.totalRowsModal = this.dataTableModal != null ? this.dataTableModal.length : 0
      this.visibleModalGruposCorreo = true

    },


    getImportacionSelected(id) {

      const importacionSelected = this.dataImportaciones.find((importacion) => importacion.uuid_importacion === id);
      this.idImportacionSelected = importacionSelected.id
      this.dataTableModal = []
      this.dataTableModal = importacionSelected.users
      this.totalRowsModal = this.dataTableModal != null ? this.dataTableModal.length : 0
      //console.log(this.totalRowsModal)
    },
    async agregarCorreosImportacion() {
      /*      console.log("id importacion")
            console.log(this.idImportacionSelected)

            console.log("id grupo")
            console.log(this.idGrupoSelected)*/
      let formData = new FormData();
      formData.append("idImportacion", this.idImportacionSelected);
      formData.append("idGrupo", this.idGrupoSelected);
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.visibleModalConfirmacion = true
      this.disabledBtnsCrearGrupo = true
      const response = await Grupos.storeGrupoImportacion(formData);
      let {
        success,
        message,
        color,

      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.visibleModalDataTable = false
        //this.visibleModalCrearGrupo = false
      }
      this.showNotificacion(message, color)

    },
    showModalCorreosExistentes(item) {
      // console.log(item.correos_existentes)
      this.filterOnDataTableModal = []
      this.dataTableModal = item.correos_existentes != null ? item.correos_existentes.map((email) => ({email})) : []
      this.totalRowsModal = item.correos_existentes != null ? item.correos_existentes.count : 0
      this.tituloModalDataTable = "Correos existentes"
      this.visibleModalDataTable = true
    },
    isSelected(id) {
      return this.selectedIds.includes(id);
    },
    toggleSelection(id) {
      if (this.isSelected(id)) {
        this.selectedIds = this.selectedIds.filter((selectedId) => selectedId !== id);
      } else {
        this.selectedIds.push(id);
      }
    },
    showNotificacion(message, tipo) {
      this.$refs.notificacion.mostrar(message, tipo, 3000)
    },
    showNotificacionModal(message, tipo) {
      this.$refs.notificacionModal.mostrar(message, tipo, 3000)
    },
    async getGrupos() {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      this.dataTable = []
      const response = await Grupos.getGrupos();

      this.modalConfirmacion = false
      this.dataTable = response.data.data;
      //this.totalRows = response.data.totalRegistros;
    }
  }


}
</script>
<style lang="scss" scoped>
$studioAPPColor: #952f81;
.btn-studioAPP {
  color: $studioAPPColor !important;
  border: 1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active {
  background-color: #dadada !important;
  color: white !important;
}

.btn-primary.active, .btn-primary:active {
  background-color: #FFA500; /* Color de fondo cuando es pulsado */
  border-color: #FFA500; /* Color de borde cuando es pulsado */
}

.vue-editor__wrapper {
  height: 100px;
}

#editor1,
#editor2 {
  height: 550px;
}
</style>