<template>
  <b-container fluid>
    <ventanaAgregarRespuestaColumnaB
        :customToolbar="customToolbar"
        :ventanaModalRespuestaParameter="ventanaModalRespuestaParameter"
        :ventanaModalConfirmacion="ventanaModalConfirmacion"
        :mensajeConfirmacion="mensajeConfirmacion"
        :disabledBotonVentanaModal="disabledBotonVentanaModal"
        :textoBotonVentanaModal="textoBotonVentanaModal"
        @formData="handleFormSubmitted"
        @update:ventanaModalRespuestaParameter="estatusVentanaModal"
        @respuestaColumnaB="respuestaColumnaB=$event"
    ></ventanaAgregarRespuestaColumnaB>

    <b-modal id="modalEliminarPregunta" no-close-on-backdrop hide-header-close no-close-on-esc centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title="Eliminar pregunta"
             v-model="FormColumnaA.visibleModalEliminarPregunta"
    >
      <b-alert v-model="FormColumnaA.visibleModalEliminarConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ FormColumnaA.mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <NotificacionModal ref="notificacionModalInterno"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row class="my-1">
            <b-col cols="12">
              <label for="label-concepto-ordenamiento">¿Desea eliminar la pregunta: {{FormColumnaA.descripcion}}?</label>
            </b-col>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarPregunta()"
                  title="Eliminar pregunta"
                  :disabled="FormColumnaA.disabledBtns"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="FormColumnaA.visibleModalEliminarRespuesta=false"
                  :disabled="FormColumnaA.disabledBtns"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalEliminarRespuesta" no-close-on-backdrop hide-header-close no-close-on-esc centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title="Eliminar opción"
             v-model="FormModalEliminarRespuesta.visibleModalEliminarRespuesta"
    >
      <b-alert v-model="FormModalEliminarRespuesta.visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ FormModalEliminarRespuesta.mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <NotificacionModal ref="notificacionModalInterno"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row class="my-1">
            <b-col cols="12">
              <label for="label-concepto-ordenamiento">¿Desea eliminar la respuesta: {{FormModalEliminarRespuesta.descripcion}}?</label>
            </b-col>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarRespuesta()"
                  title="Eliminar opción"
                  :disabled="FormModalEliminarRespuesta.disabledBtns"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="FormModalEliminarRespuesta.visibleModalEliminarRespuesta=false"
                  :disabled="FormModalEliminarRespuesta.disabledBtns"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>


    <b-modal id="modalCrearRespuesta" no-close-on-backdrop hide-header-close no-close-on-esc hide-footer persistent centered size="lg"
             ref="visibleModalDataTable"
             title="Crear Respuesta"
             v-model="FormRespuesta.visibleModalCrearRespuesta"
    >
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="label-opcion">Respuesta:</label>
            </b-col>
            <b-col cols="8">
              <b-form-input
                  v-model="FormRespuesta.descripcion" id="descripcion"
                  ref="formRespuestaDescripcion"
                  placeholder="Captura la opción de respuesta"
                  :disabled = "FormRespuesta.disabledBtns"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarRespuesta()"
                  title="Guardar respuesta"
                  :disabled="FormRespuesta.disabledBtns"
              >
                Guardar
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="cancelarModalRespuesta"
                  :disabled="FormRespuesta.disabledBtns"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>

          <b-row class="d-flex align-items-end justify-content-end my-1">
            <div class="col-sm-12 my-2">
              <b-form-input
                  id="filter-input-dashboard"
                  v-model="FormRespuesta.filter"
                  type="search"
                  placeholder="Buscar"
              >
              </b-form-input>
            </div>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-table class="table data-table"
                       :items="FormRespuesta.data"
                       :fields="FormRespuesta.fields"
                       :current-page="FormRespuesta.currentPage"
                       :per-page="FormRespuesta.perPage"
                       :filter="FormRespuesta.filter"
                       :filter-included-fields="FormRespuesta.filterOn"
                       :striped="true"
                       hover
                       show-empty
                       :small="true"
              >
                <template #empty="scope">
                  <h5 class="text-center">
                    {{ scope.emptyText = 'Por el momento no existen opciones de categorías que mostrar.' }}
                  </h5>
                </template>
                <template #emptyfiltered="scope">
                  <h5 class="text-center">
                    {{
                      scope.emptyFilteredText = 'No existen opciones de categorías que coincidan con los parámetros de búsqueda.'
                    }}
                  </h5>
                </template>
                <template #cell(opcion)="row">
                  <div v-html="row.value"></div>
                </template>
                <template #cell(actions)="row">
                  <div class="d-flex align-content-end">
                    <b-button
                        variant="success"
                        v-b-modal.modal-prevent-closing
                        size="sm"
                        @click="showModalEditarRespuesta(row.item)"
                        class="mr-1"
                        title="Editar opción">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-trash2-fill" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                      </svg>
                    </b-button>

                    <b-button
                        variant="danger"
                        v-b-modal.modal-prevent-closing
                        size="sm"
                        @click="showModalEliminarRespuesta(row.item)"
                        class="mr-1"
                        title="Eliminar respuesta">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-trash2-fill" viewBox="0 0 16 16">
                          <path
                              d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                        </svg>
                      </svg>
                    </b-button>
                  </div>
                </template>
              </b-table>
              <div class="d-flex justify-content-between row p-2">
                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                  <div>
                    <b-pagination
                        v-model="FormRespuesta.currentPage"
                        :total-rows="FormRespuesta.totalRows"
                        :per-page="FormRespuesta.perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                    >
                    </b-pagination>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-alert v-model="FormRespuesta.visibleModalConfirmacion" variant="light" show>
                <b-button variant="primary" disabled>
                  <b-spinner small type="grow"></b-spinner>
                  {{ FormRespuesta.mensajeConfirmacion }}
                </b-button>
              </b-alert>
              <Notificacion ref="notificacionModal"/>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <!-- modal capturar opción de Pregunta-ColumnaA-->
    <b-modal id="modalAgregarRespuesta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalCapturarPregunta"
             title="Agregar Pregunta |Columna A"
             v-model="modalCapturarPregunta"
    >
      <b-alert v-model="modalConfirmacionPregunta" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionRespuesta"/>
      <b-card
          tag="article"
          class="mb-2"
      >
        <b-row>
          <b-col cols="12">
            <div id="editor2ED">
              <vue-editor v-model="pregunta_columnaA"
                          ref="pregunta_columnaA"
                          id="editorPreguntaColumnaA"
                          :editorToolbar="customToolbar"
                          placeholder="Captura la pregunta"
                          class="mb-5"
              >
              </vue-editor>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="w-100">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-left  mt-5"
                  @click="guardarPreguntaColumnaA()"
                  :title=textobtnGuardarRespuesta
                  :disabled="disabledBtnGuardarPreguntaColumnaA"
              >
                {{ textobtnGuardarRespuesta }}
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-left  ml-2 mt-5"
                  @click="modalCapturarPregunta=false"
                  title="Cancelar"
              >
                Cancelar
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <!-- fin modal capturar opción de Pregunta-ColumnaA-->



    <b-row id="dashboardGeneral">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body">
            <b-alert
                show
                variant="primary"
                dismissible>
              Agregar Pregunta Relacionar columnas.
            </b-alert>
            <!--ventana confirmacion general-->

            <!--cerrar ventana confirmacion general-->
          </div>
          <div class="col-sm-12 my-2">
            <div>
              <b-card no-body>
                <b-tabs card>
                  <!--INICIO TAB1-->
                  <b-tab id="tabPlanteamiento" title="Planteamiento" active>
                    <b-card-text>
                      <b-form>
                        <b-row>
                          <b-col cols="12">
                            <div id="editor1ED">
                              <!--                              <vue-editor v-model="reactivo"
                                                                        ref="reactivo"
                                                                        id="editor1"
                                                                        :editorToolbar="customToolbar"
                                                                        placeholder="Ingresa la pregunta"
                                                                        :style="{padding:'10px'}"
                                                                        @input="checkLengthPregunta"
                                                            >
                                                            </vue-editor>-->
                              <b-form-textarea
                                  id="formPlateamientoReactivo"
                                  ref="formPlateamientoReactivo"
                                  v-model="FormPlanteamiento.reactivo"
                                  placeholder="Capturar el planteamiento"
                                  rows="4"
                                  max-rows="6"
                              ></b-form-textarea>
                            </div>
                            <b-button
                                variant="primary"
                                size="sm"
                                class="float-right mx-2 my-1"
                                @click="resetPlanteamiento()"
                                title="Nueva Pregunta"
                                v-if="FormPlanteamiento.visibleBtnNuevaPregunta===true"
                            >
                              Nueva Pregunta
                            </b-button>

                            <b-button
                                variant="success"
                                size="sm"
                                class="float-right mx-2 my-1"
                                @click="guardarPlanteamiento()"
                                title="Guarda Pregunta"
                                :disabled="FormPlanteamiento.disabledBtns"
                            >
                              Guardar
                            </b-button>


                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>
                  </b-tab>
                  <!--FIN TAB1-->
                  <!--INICIO TAB2-->
                  <b-tab id="tabColumnas" title="Columnas" :disabled="FormColumnaA.disabledTabColumnas">
                    <b-card-text>
                      <b-alert
                          show
                          variant="success"
                          dismissible
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                             class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16"

                        >
                          <path
                              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                        </svg>
                        Agregar primero las preguntas, posteriormente capturar las opciones de respuestas para las preguntas, previamente capturadas. Después de crear las opciones, seleccionar la respuesta que corresponda a cada pregunta.
                      </b-alert>
                      <b-row class="my-1">
                        <b-col cols="2">
                          <label for="label-opcion">Pregunta:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-textarea
                              ref="formColumnaADescripcion"
                              v-model="FormColumnaA.descripcion"
                              placeholder="Captura la pregunta..."
                              rows="1"
                              :disabled="FormColumnaA.disabledBtns"
                          ></b-form-textarea>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12">
                          <b-button
                              variant="primary"
                              v-b-modal.modal-prevent-closing
                              size="sm"
                              @click="showModalCrearRespuestas()"
                              class="float-left"
                              title="Agregar opción de respuesta"
                              :disabled="FormRespuesta.disabledBtnRespuesta"
                          >
                              Agregar opción de respuesta
<!--                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-pencil-square" viewBox="0 0 16 16">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                   class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                <path
                                    d="M8.235 1.559a.5.5 0 0 0-.47 0l-7.5 4a.5.5 0 0 0 0 .882L3.188 8 .264 9.559a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882L12.813 8l2.922-1.559a.5.5 0 0 0 0-.882l-7.5-4zm3.515 7.008L14.438 10 8 13.433 1.562 10 4.25 8.567l3.515 1.874a.5.5 0 0 0 .47 0l3.515-1.874zM8 9.433 1.562 6 8 2.567 14.438 6 8 9.433z"/>
                              </svg>
                            </svg>-->
                          </b-button>

                          <b-button
                              variant="primary"
                              size="sm"
                              class="float-right  mt-0 mb-2 d-inline-block"
                              title="Cancelar"
                              @click="resetFormColumnaA"
                              :disabled="FormColumnaA.disabledBtns"
                          >
                            Cancelar
                          </b-button>
                          <b-button
                              variant="success"
                              size="sm"
                              class="float-right  mr-3 mt-0 mb-2 d-inline-block"
                              @click="guardarPreguntaColumnaA()"
                              :title=FormColumnaA.textoBtn
                              :disabled="FormColumnaA.disabledBtns"
                          >
                            Guardar
                          </b-button>
                        </b-col>
                      </b-row>


                      <b-row>
                        <b-col cols="12">
                          <b-table class="table data-table"
                                   :items="FormColumnaA.data"
                                   :fields="FormColumnaA.fields"
                                   :current-page="FormColumnaA.currentPage"
                                   :per-page="FormColumnaA.perPage"
                                   :filter="FormColumnaA.filter"
                                   :filter-included-fields="FormColumnaA.filterOn"
                                   :striped="true"
                                   hover
                                   show-empty
                                   :small="true"
                                   :disabled="FormRespuesta.disabledBtnRespuesta"
                          >
                            <template #empty="scope">
                              <h5 class="text-center">
                                {{ scope.emptyText = 'Por el momento no existen preguntas que mostrar.' }}
                              </h5>
                            </template>
                            <template #emptyfiltered="scope">
                              <h5 class="text-center">
                                {{
                                  scope.emptyFilteredText = 'No existen preguntas que coincidan con los parámetros de búsqueda.'
                                }}
                              </h5>
                            </template>
                            <template #cell(descripcion)="row">
                              <div v-html="row.value"></div>
                            </template>
                            <template #cell(respuesta)="row">
                              <b-form-select
                                  v-model="FormColumnaA.dataSelected[`${row.item.id}`]"
                                  :options="FormRespuesta.data"
                                  text-field="descripcion"
                                  value-field="uuid_columnaB"
                                  class="mb-3"
                                  size="sm"
                                  @change="guardarRespuestaSeleccionada(row,FormColumnaA.dataSelected[`${row.item.id}`])"
                              >
                              </b-form-select>

                            </template>
                            <template #cell(actions)="row">
                              <div class="text-center">
                                <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                          @click="editPregunta(row.item)" class="mr-1" title="Editar pregunta">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                       class="bi bi-pencil-square" viewBox="0 0 16 16">
                                    <path
                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd"
                                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                  </svg>
                                </b-button>
                                <b-button variant="danger" v-b-modal.modal-prevent-closing size="sm"
                                          @click="showModalEliminarPregunta(row.item)" class="mr-1"
                                          title="Eliminar pregunta">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                       class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                                  </svg>
                                </b-button>


                              </div>
                            </template>

                          </b-table>
                          <div class="d-flex justify-content-between row p-2">
                            <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                              <div>
                                <b-pagination
                                    v-model="FormColumnaA.currentPage"
                                    :total-rows="FormColumnaA.totalRows"
                                    :per-page="FormColumnaA.perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                >
                                </b-pagination>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card-text>
                    <b-row>
                      <b-col cols="12">
                    <b-alert v-model="FormColumnaA.visibleModalConfirmacion" variant="light" show>
                      <b-button variant="primary" disabled>
                        <b-spinner small type="grow"></b-spinner>
                        {{ FormColumnaA.mensajeConfirmacion }}
                      </b-button>
                    </b-alert>
                    <Notificacion ref="notificacionModal"/>
                      </b-col>
                    </b-row>
                  </b-tab>

                </b-tabs>
                <b-row>
                  <b-col cols="12">
                    <b-alert v-model="modalConfirmacion" variant="light" show>
                      <b-button variant="primary" disabled>
                        <b-spinner small type="grow"></b-spinner>
                        {{ mensajeConfirmacion }}
                      </b-button>
                    </b-alert>
                    <Notificacion ref="notificacion"/>
                  </b-col>
                </b-row>

              </b-card>
              <!--FIN DEL CUERPO TABS-->
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

  </b-container>
</template>
/* eslint-disable vue/no-unused-vars */
<script>
import toast from "@/mixins/ToastMixin.js";
import PreguntaService from "@/services/PreguntaService";
import Notificacion from '@/components/Notificacion.vue'
import ventanaAgregarRespuestaColumnaB
  from '@/components/reactivos/relacionarColumnas/ventanaAgregarRespuestaColumnaB.vue'
import {VueEditor} from "vue2-editor";
import ReactivoColumnaService from "@/services/ReactivoColumnaService";
import NotificacionModal from "@/components/NotificacionModal.vue";

export default {
  components: {
    NotificacionModal,
    Notificacion,
    VueEditor,
    ventanaAgregarRespuestaColumnaB
  },
  mixins: [toast],
  data() {
    return {
      customToolbar:
          [
            [{header: [false, 1, 2, 3, 4, 5, 6]}],
            ["bold", "italic", "underline", "strike"],
            [{color: []}],
            [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
            [
              {align: ""},
              {align: "center"},
              {align: "right"},
              {align: "justify"}
            ],
            [{indent: "-1"}, {indent: "+1"}],
            ["clean"]
          ],
      FormColumnaA: {
        disabledTabColumnas:true,
        dataSelected:[],
        visibleModalEliminarPregunta:false,
        uuid_reactivo_fk: null,
        mensaje_confirmacion: null,
        uuid_columnaA: 0,
        descripcion: null,
        uuid_columnaB_respuesta: null,
        visibleModalConfirmacion: false,
        data: [],
        disabledBtns: false,
        fields: [
          {label: 'Pregunta', key: 'descripcion', class: 'text-left',},
          {label: 'Respuesta', key: 'respuesta', class: 'text-center',},
          {label: 'Acciones', key: 'actions', class: 'text-center'}
        ],
        currentPage: 1,
        perPage: 5,
        filterOn: [],
        totalRows: 0,
        filter: null,
        textoBtn: null
      },

      FormRespuesta: {
        disabledBtnRespuesta:true,
        visibleModalCrearRespuesta:false,
        uuid_reactivo_fk: null,
        mensaje_confirmacion: null,
        uuid_columnaB: 0,
        descripcion: null,
        visibleModalConfirmacion: false,
        visibleModalEliminarConfirmacion: false,
        data: [],
        disabledBtns: false,
        fields: [
          {label: 'Respuesta', key: 'descripcion', class: 'text-left', width:100},
          {label: 'Acciones', key: 'actions', class: 'text-center'}
        ],
        currentPage: 1,
        perPage: 5,
        filterOn: [],
        totalRows: 0,
        filter: null,
        textoBtn: null
      },
      FormModalEliminarRespuesta:{
        visibleModalEliminarRespuesta:false,
        uuid_reactivo_fk: null,
        mensaje_confirmacion: null,
        uuid_columnaB: 0,
        descripcion: null,
        visibleModalConfirmacion: false,
        disabledBtns: false,
      },

      FormPlanteamiento: {
        reactivo: null,
        uuid_pregunta: 0,
        disabledBtns: false,
        visibleBtnNuevaPregunta:false,
      },
      preguntasColumnaA: [],

      pregunta_columnaA: null,
      disabledPregunta: true,
      modalConfirmacionPregunta: false,
      respuestaColumnaB: null,
      uuid_pregunta_columnaA: null,
      totalPreguntasColumnaA: 1,
      disabledBtnGuardarPreguntaColumnaA: false,


      ventanaModalRespuestaParameter: false,
      ventanaModalConfirmacion: false,
      disabledBotonVentanaModal: false,
      textoBotonVentanaModal: "Guardar",
      /*variables Generales*/
      disabledBtnCancelar: false,
      /*VARIABLES DE BARRA DE PROGRESO*/
      barWidth: null,
      duration: null,
      currentTime: null,
      isTimerPlaying: false,
      /* variables modal capturar pregunta*/
      modalCapturarPregunta: false,
      /* fin variables modal capturar pregunta*/



      /*VARIABLES VIDEO*/
      textobtnGuardarVideo: "Guardar video",
      modalConfirmacionVideo: false,
      disabledBtnGuardarVideo: true,
      modalYoutube: false,
      video_save: 0,
      mensajeConfirmacion: null,
      modalConfirmacion: false,

      disabledBarraMultimedia: true,


      /* activar desactivar botones, textbox,textarea */
      visibleBtnDelVideo: true,


      tituloVideoYoutube: null,
      userSelectedVideo: 0,
      idVideoSelected: null,
      youtubeItems: [],
      youtubeItems_count: 0,


      //VARIABLES PARA IMAGEN
      disabledBtnsEliminarImagen: false,
      modalEliminarImagen: false,
      textobtnGuardarImagen: "Guardar archivo",
      disabledBtnGuardarImagen: false,
      modalConfirmacionImagen: false,
      primer_guardado_nameFileImagen: 0,
      nombre_archivo_original_imagen: null,
      url_imagen: null,
      imagenNueva: null,
      imagen: null,
      editarImagen: false,
      enabledToolbarImagen: false,
      modalZoomImagen: false,
      modalImagen: false,
      objImagen: null,
      //FIN VARIABLES PARA IMAGEN

      //VARIABLES PARA AUDIO
      disabledBtnsEliminarAudio: false,
      modalAudio: false,
      modalEliminarAudio: false,
      textobtnGuardarAudio: "Guardar archivo",
      disabledBtnGuardarAudio: false,
      primer_guardado_nameFileAudio: 0,
      nombre_archivo_original_audio: null,
      enabledToolbarAudio: false,
      modalConfirmacionAudio: false,
      audio_play: null,
      objAudio: null,
      audioNuevo: null,
      audio: null,
      editarAudio: false,
      url_audio: null,
      //fin vars para audio

      //variables pregunta
      textobtnGuardarPregunta: "Guardar Pregunta",
      disabledBtnGuardarPregunta: true,
      primer_guardado_pregunta: 0,
      contenidoPreguntaOriginal: null,

      //fin variables pregunta

      //variables RESPUESTA
      modalConfirmacionEliminarImagenRespuesta: false,
      modalEliminarImagenRespuesta: false,
      objImagenRespuesta: null,
      modalZoomImagenRespuesta: false,
      primer_guardado_nameFileImagenRespuesta: 0,
      nombre_archivo_original_imagenRespuesta: null,
      editarImagenRespuesta: false,
      disabledBarraMultimediaRespuesta: true,
      textobtnGuardarImagenRespuesta: "Guardar archivo",
      disabledBtnGuardarImagenRespuesta: false,
      url_imagenRespuesta: null,
      imagenNuevaRespuesta: null,
      modalImagenRespuesta: false,
      modalConfirmacionImagenRespuesta: false,

      maximoRespuestasOkPermitidas: 1,
      totalRespuestasOk: 0,
      respuesta: null,
      dataTablePreguntas: [],
      disabledBtnGuardarRespuesta: true,
      disabledBtnNuevaRespuesta: true,
      textobtnGuardarRespuesta: "Guardar Respuesta",
      disabledRespuesta: true,
      uuid_respuesta: 0,
      modalEliminarRespuesta: false,
      modalConfirmacionRespuesta: false,
      disabledBtnsEliminarRespuesta: false,
      //fin variables RESPUESTA

      reactivo: null,
      editarActividadUnidad: true,


      currentPage_reactivos: 1,
      perPage_reactivos: 5,
      filter_reactivos: null,
      filterOn_reactivos: [],
      //reactivos

      disabledBtnInscribirse: false,
      activo: false,
      modalEditarSesion: false,
      modalAbrirEncuesta: false,
      nombre_sesion_actual: null,
      sesion_id_actual: null,
      modalDeleteSesion: false,
      encuesta: {
        ponencia_id: null
      },
      modalAgregarInvitado: false,
      modalVerComprobante: true,
      modalEsperar: false,
      previewObservacionesValidacion: '',
      modalValidarIngreso: false,
      textoSnackbar: '',
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter: null,
      filterOn: [],
      resultSet: [],
      search: '',

      //editor de texto Pregunta


    }
  },

  methods: {
     cancelarModalRespuesta(){
      this.FormRespuesta.visibleModalCrearRespuesta=false
      console.log(this.FormPlanteamiento.uuid_pregunta)
       this.getRespuestas(this.FormPlanteamiento.uuid_pregunta,0);
    },


    async guardarRespuestaSeleccionada(item,id){
      console.log("item.item.uuid_columnaA")
      console.log(item.item.uuid_columnaA)
      console.log("uuid_columnaB_respuesta")
      console.log(id)
      this.FormColumnaA.disabledBtns = true
      this.FormColumnaA.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormColumnaA.visibleModalConfirmacion = true
      let formData = new FormData();
      formData.append("uuid_columnaA", item.item.uuid_columnaA);
      formData.append("uuid_columnaB_respuesta", id);
      formData.append("uuid_reactivo_fk", this.FormPlanteamiento.uuid_pregunta);
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacionPregunta = true
      const response = await ReactivoColumnaService.storeRespuestaReactivoColumnaA(formData);
      let {
        success,
        message,
        color,
          data,
        totalRows

      } = response.data;
      this.modalConfirmacionPregunta = false
      if (success) {

        this.FormColumnaA.disabledBtns = false
        this.FormColumnaA.visibleModalConfirmacion = false
        this.FormColumnaA.data = data
        this.FormColumnaA.totalRows = totalRows
        this.inicializarDataRespuestas()
        this.showNotificacion(message, color)

      }


    },

    inicializarDataRespuestas(){
      this.FormColumnaA.dataSelected = []
      this.FormColumnaA.data.forEach(columnaA => {
        this.FormColumnaA.dataSelected[`${columnaA.id}`] = columnaA.uuid_columnaB_respuesta;
      });
      console.log("dataSelected")
      console.log()
    },

    showModalEliminarPregunta(preguntaSelected){
      this.FormColumnaA.descripcion = preguntaSelected.descripcion
      this.FormColumnaA.uuid_columnaA = preguntaSelected.uuid_columnaA
      this.FormColumnaA.uuid_reactivo_fk = this.FormPlanteamiento.uuid_pregunta
      this.FormColumnaA.visibleModalEliminarPregunta = true
    },
    editPregunta(preguntaSelected) {
      this.FormColumnaA.descripcion = preguntaSelected.descripcion
      this.FormColumnaA.uuid_columnaA = preguntaSelected.uuid_columnaA
    },
    estatusVentanaModal(nuevoValor) {
      this.ventanaModalRespuestaParameter = nuevoValor
    },
    handleFormSubmitted(data) {
      console.log(data)
    },

    resetPlanteamiento() {
       this.FormPlanteamiento.visibleBtnNuevaPregunta = false
      this.FormColumnaA.disabledTabColumnas = true
      this.FormRespuesta.disabledBtnRespuesta = true
      this.FormPlanteamiento.reactivo = null
      this.FormPlanteamiento.uuid_pregunta = 0
      this.FormPlanteamiento.disabledBtns = null
      this.resetFormColumnaA()
      this.FormRespuesta.data = []
      this.FormRespuesta.totalRows = 0
      this.FormColumnaA.data = []
      this.FormColumnaA.totalRows = 0
    },

    async eliminarPregunta() {
      this.FormColumnaA.disabledBtns = true
      this.FormColumnaA.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormColumnaA.visibleModalEliminarConfirmacion = true
      let formData = new FormData();

      formData.append("uuid_columnaA", this.FormColumnaA.uuid_columnaA);
      formData.append("uuid_reactivo_fk", this.FormColumnaA.uuid_reactivo_fk);
      const response = await ReactivoColumnaService.deletePregunta(formData);
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormColumnaA.visibleModalEliminarConfirmacion = false
        this.FormColumnaA.disabledBtns = false
        this.FormColumnaA.descripcion = null
        this.FormColumnaA.uuid_columnaA = 0

        this.FormColumnaA.data = data
        this.FormColumnaA.totalRows = total
        this.FormColumnaA.visibleModalEliminarPregunta = false
        this.showNotificacion(message, color);
        this.inicializarDataRespuestas()
      }
    },

    async eliminarRespuesta() {
      this.FormModalEliminarRespuesta.disabledBtns = true
      this.FormModalEliminarRespuesta.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormModalEliminarRespuesta.visibleModalConfirmacion = true
      let formData = new FormData();

      formData.append("uuid_columnaB", this.FormModalEliminarRespuesta.uuid_columnaB);
      formData.append("uuid_reactivo_fk", this.FormModalEliminarRespuesta.uuid_reactivo_fk);
      const response = await ReactivoColumnaService.deleteRespuesta(formData);
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalEliminarRespuesta.visibleModalConfirmacion = false
        this.FormModalEliminarRespuesta.disabledBtns = false

        this.FormRespuesta.data = data
        this.FormRespuesta.totalRows = total
        this.FormModalEliminarRespuesta.visibleModalEliminarRespuesta = false
        this.showNotificacionModal(message, color);
      }
    },
    async guardarPlanteamiento() {
      if (this.FormPlanteamiento.reactivo === '' || this.FormPlanteamiento.reactivo === null) {
        this.$refs.formPlateamientoReactivo.focus();
        this.showNotificacion('¡Debe capturar el planteamiento!', 'danger');
        return false;
      }
      let formData = new FormData();
      formData.append("descripcion", this.FormPlanteamiento.reactivo);
      formData.append("uuid_pregunta", this.FormPlanteamiento.uuid_pregunta);
      formData.append("tiporeactivo_id", 3);
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      this.disabledBtnGuardarPregunta = true
      const response = await PreguntaService.storeQuestion(formData);
      let {
        success,
        uuid_pregunta,
        message
        , color
      } = response.data;
      this.modalConfirmacion = false
      if (success) {

          this.FormPlanteamiento.uuid_pregunta = uuid_pregunta
          this.FormPlanteamiento.visibleBtnNuevaPregunta = true
          this.FormColumnaA.disabledTabColumnas = false
          this.disabledBarraMultimedia = false
          this.modalConfirmacion = false

          this.disabledBtnGuardarPregunta = false
          this.textobtnGuardarPregunta = "Actualizar Pregunta"
          this.disabledRespuesta = false

      }
      this.showNotificacion(message, color)

    },
    resetFormColumnaA() {
      this.FormColumnaA.descripcion = null
      this.FormColumnaA.uuid_columnaA = 0
    },
    showNotificacionModal(message, tipo) {
      this.$refs.notificacionModal.mostrar(message, tipo, 3000)
    },
    showNotificacionModalInterno(message, tipo) {
      this.$refs.notificacionModalInterno.mostrar(message, tipo, 3000)
    },
    async guardarPreguntaColumnaA() {
      if (this.FormColumnaA.descripcion === '' || this.FormColumnaA.descripcion === null) {
        this.$refs.formColumnaADescripcion.focus()
        this.showNotificacionModal('¡Debe capturar la pregunta!', 'danger');
        return false;
      }
      this.FormColumnaA.disabledBtns = true
      this.FormColumnaA.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormColumnaA.visibleModalConfirmacion = true
      let formData = new FormData();
      formData.append("descripcion", this.FormColumnaA.descripcion);
      formData.append("uuid_reactivo_fk", this.FormPlanteamiento.uuid_pregunta);
      formData.append("uuid_columnaA", this.FormColumnaA.uuid_columnaA);
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacionPregunta = true
      console.log("this.FormPlanteamiento.uuid_pregunta-->")
      console.log(this.FormPlanteamiento.uuid_pregunta)
      const response = await ReactivoColumnaService.storeReactivoColumnaA(formData);
      let {
        success,
        message,
        color,
        preguntas_columnaA,
        cantidadPreguntasColumnaA
      } = response.data;
      this.modalConfirmacionPregunta = false
      if (success) {
        this.FormRespuesta.disabledBtnRespuesta = false
        this.FormColumnaA.uuid_columnaA = 0
        this.FormColumnaA.disabledBtns = false
        this.FormColumnaA.descripcion = null
        this.FormColumnaA.data = preguntas_columnaA;
        this.FormColumnaA.totalRows = cantidadPreguntasColumnaA;

        this.FormColumnaA.visibleModalConfirmacion = false
        this.showNotificacion(message, color)
        this.inicializarDataRespuestas()
      }

    },

    async guardarRespuesta() {
      if (this.FormRespuesta.descripcion === '' || this.FormRespuesta.descripcion === null) {
        this.$refs.formRespuestaDescripcion.focus()
        this.showNotificacionModal('¡Debe capturar la respuesta!', 'danger');
        return false;
      }
      this.FormRespuesta.disabledBtns = true
      this.FormRespuesta.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormRespuesta.visibleModalConfirmacion = true
      let formData = new FormData();
      formData.append("descripcion", this.FormRespuesta.descripcion);
      formData.append("uuid_reactivo_fk", this.FormRespuesta.uuid_reactivo_fk);
      formData.append("uuid_columnaB", this.FormRespuesta.uuid_columnaB);
      const response = await ReactivoColumnaService.storeRespuestas(formData);
      let {
        success,
        message,
        color,
        data,
        totalRows
      } = response.data;
      if (success) {
        this.FormRespuesta.visibleModalConfirmacion = false
        this.FormRespuesta.disabledBtns = false
        this.FormRespuesta.uuid_columnaB = 0
        this.FormRespuesta.descripcion = null
        this.FormRespuesta.data = data;
        this.FormRespuesta.totalRows = totalRows;

      }
      this.showNotificacionModal(message, color)
    },

    showModalEditarRespuesta(respuestaSelected){
      this.FormRespuesta.descripcion = respuestaSelected.descripcion
      this.FormRespuesta.uuid_columnaB = respuestaSelected.uuid_columnaB
    },

    showModalEliminarRespuesta(respuestaSelected){
      this.FormModalEliminarRespuesta.descripcion =respuestaSelected.descripcion
      this.FormModalEliminarRespuesta.uuid_reactivo_fk =this.FormPlanteamiento.uuid_pregunta
      this.FormModalEliminarRespuesta.uuid_columnaB = respuestaSelected.uuid_columnaB
      this.FormModalEliminarRespuesta.visibleModalEliminarRespuesta = true

    },


    showModalCrearRespuestas(){
      this.FormRespuesta.descripcion = null
      this.FormRespuesta.data = []
      this.FormRespuesta.totalRows = 0
      this.FormRespuesta.uuid_reactivo_fk = this.FormPlanteamiento.uuid_pregunta
      this.FormRespuesta.visibleModalCrearRespuesta = true
      this.getRespuestas(this.FormRespuesta.uuid_reactivo_fk,1);
    },
    async getRespuestas($uuid_reactivo_fk,$modalGeneral){
      this.FormRespuesta.mensajeConfirmacion = "Favor de esperar un momento, procesando información...";
      this.FormRespuesta.visibleModalConfirmacion = true
      const response = await ReactivoColumnaService.getRespuestas($uuid_reactivo_fk);
      this.FormRespuesta.visibleModalConfirmacion = false
      let {
        success,
        message,
        color,
        data,
        totalRows
      } = response.data;
      this.FormRespuesta.visibleModalConfirmacion = false
      if (success) {
        this.FormRespuesta.data = []
        this.FormRespuesta.data = data
        this.FormRespuesta.totalRows = 0
        this.FormRespuesta.totalRows = totalRows
        if($modalGeneral == 1) {
          this.showNotificacionModal(message, color);
        }
        else{
          this.showNotificacion(message, color);
        }
      }
    },

    async getPreguntasColumnaA($uuid_reactivo_fk){
      this.FormColumnaA.mensajeConfirmacion = "Favor de esperar un momento, procesando información...";
      this.FormColumnaA.visibleModalConfirmacion = true
      const response = await ReactivoColumnaService.getPreguntas($uuid_reactivo_fk);
      this.FormColumnaA.visibleModalConfirmacion = false
      let {
        success,
        data,
        totalRows
      } = response.data;

      if (success) {
        this.FormColumnaA.visibleModalConfirmacion = false
        this.FormColumnaA.data = data
        this.FormColumnaA.totalRows = totalRows
        await this.getRespuestas(this.$route.params.uuid_pregunta,0)
        this.inicializarDataRespuestas()
      }
    },


    showNotificacion(message, tipo) {
      this.$refs.notificacion.mostrar(message, tipo, 3000)
    },
  },
  mounted() {
    if (this.$route.params.descripcion != null) {
      this.FormColumnaA.disabledTabColumnas = false
      this.FormColumnaA.disabledBtns = false
      this.FormRespuesta.disabledBtnRespuesta = false
      this.FormPlanteamiento.visibleBtnNuevaPregunta = true
      this.FormPlanteamiento.reactivo = this.$route.params.descripcion
      this.FormPlanteamiento.uuid_pregunta = this.$route.params.uuid_pregunta
      this.getPreguntasColumnaA(this.$route.params.uuid_pregunta)
    }
    else{
      this.resetPlanteamiento()
    }
  },
  computed: {}
}
</script>
<style lang="scss" scoped>
$studioAPPColor: #952f81;
.btn-studioAPP {
  color: $studioAPPColor !important;
  border: 1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active {
  background-color: #dadada !important;
  color: white !important;
}

.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color: #B2007B !important;
}

.alert-fixed {
  position: fixed;
  bottom: 2%;
  left: 30%;
  z-index: 9999;
  border-radius: 5px;
}


.player {
  background: #eef3f7;
  width: 310px;
  min-height: 30px;
  // box-shadow: 0px 55px 75px -10px rgba(76, 70, 124, 0.5);
  // box-shadow: 0px 55px 105px 10px rgba(76, 70, 124, 0.35);
  box-shadow: 0px 15px 35px -5px rgba(50, 88, 130, 0.32);
  border-radius: 15px;
  padding: 30px;
  @media screen and (max-width: 576px), (max-height: 500px) {
    width: 95%;
    padding: 10px;
    margin-top: 75px;
    min-height: initial;
    padding-bottom: 30px;
    max-width: 400px;
  }

  &__top {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 4;
    @media screen and (max-width: 576px), (max-height: 500px) {
      flex-wrap: wrap;
    }
  }

  &-cover {
    width: 300px;
    height: 300px;
    margin-left: -70px;
    flex-shrink: 0;
    position: relative;
    z-index: 2;
    border-radius: 15px;
    // transform: perspective(512px) translate3d(0, 0, 0);
    // transition: all .4s cubic-bezier(.125, .625, .125, .875);
    z-index: 1;

    @media screen and (max-width: 576px), (max-height: 500px) {
      margin-top: -70px;
      margin-bottom: 25px;
      width: 290px;
      height: 230px;
      margin-left: auto;
      margin-right: auto;
    }

    &__item {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      position: absolute;
      left: 0;
      top: 0;

      &:before {
        content: "";
        background: inherit;
        width: 100%;
        height: 100%;
        box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
        display: block;
        z-index: 1;
        position: absolute;
        top: 30px;
        transform: scale(0.9);
        filter: blur(10px);
        opacity: 0.9;
        border-radius: 15px;
      }

      &:after {
        content: "";
        background: inherit;
        width: 100%;
        height: 100%;
        box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
        display: block;
        z-index: 2;
        position: absolute;
        border-radius: 15px;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
      box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
      user-select: none;
      pointer-events: none;
    }
  }

  &-controls {
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 576px), (max-height: 500px) {
      flex-direction: row;
      padding-left: 0;
      width: 100%;
      flex: unset;
    }

    &__item {
      display: inline-flex;
      font-size: 30px;
      padding: 5px;
      margin-bottom: 10px;
      color: #acb8cc;
      cursor: pointer;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: all 0.3s ease-in-out;

      @media screen and (max-width: 576px), (max-height: 500px) {
        font-size: 26px;
        padding: 5px;
        margin-right: 10px;
        color: #acb8cc;
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #fff;
        transform: scale(0.5);
        opacity: 0;
        box-shadow: 0px 5px 10px 0px rgba(76, 70, 124, 0.2);
        transition: all 0.3s ease-in-out;
        transition: all 0.4s cubic-bezier(0.35, 0.57, 0.13, 0.88);
      }

      @media screen and (min-width: 500px) {
        &:hover {
          color: #532ab9;

          &::before {
            opacity: 1;
            transform: scale(1.3);
          }
        }
      }

      @media screen and (max-width: 576px), (max-height: 500px) {
        &:active {
          color: #532ab9;

          &::before {
            opacity: 1;
            transform: scale(1.3);
          }
        }
      }

      .icon {
        position: relative;
        z-index: 2;
      }

      &.-xl {
        margin-bottom: 0;
        font-size: 95px;
        // filter: drop-shadow(0 2px 8px rgba(172, 184, 204, 0.3));
        // filter: drop-shadow(0 9px 6px rgba(172, 184, 204, 0.35));
        filter: drop-shadow(0 11px 6px rgba(172, 184, 204, 0.45));
        color: #fff;
        width: auto;
        height: auto;
        display: inline-flex;
        @media screen and (max-width: 576px), (max-height: 500px) {
          margin-left: auto;
          font-size: 75px;
          margin-right: 0;
        }

        &:before {
          display: none;
        }
      }

      &.-favorite {
        &.active {
          color: red;
        }
      }
    }
  }
}

[v-cloak] {
  display: none;
}

[v-cloak] > * {
  display: none;
}

.progress {
  width: 100%;
  margin-top: -15px;
  user-select: none;

  &__top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__duration {
    margin-bottom: 6px;
    color: #71829e;
    font-weight: 700;
    font-size: 14px;
    opacity: 0.5;
  }

  &__time {
    margin-top: 6px;
    color: #71829e;
    font-weight: 700;
    font-size: 14px;
    opacity: 0.7;
  }
}

.progress__bar {
  height: 6px;
  width: 100%;
  cursor: pointer;
  background-color: #d0d8e6;
  display: inline-block;
  border-radius: 10px;
}

.progress__current {
  height: inherit;
  width: 0%;
  background-color: #a3b3ce;
  border-radius: 10px;
}

.album-info {
  color: #71829e;
  flex: 1;
  padding-right: 60px;
  user-select: none;

  @media screen and (max-width: 576px), (max-height: 500px) {
    padding-right: 30px;
  }

  &__name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
    line-height: 1.3em;
    @media screen and (max-width: 576px), (max-height: 500px) {
      font-size: 18px;
      margin-bottom: 9px;
    }
  }

  &__track {
    font-weight: 400;
    font-size: 20px;
    opacity: 0.7;
    line-height: 1.3em;
    min-height: 52px;
    @media screen and (max-width: 576px), (max-height: 500px) {
      font-size: 18px;
      min-height: 50px;
    }
  }
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  transition: all .3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }
}

//scale out

.scale-out-enter-active {
  transition: all .35s ease-in-out;
}

.scale-out-leave-active {
  transition: all .35s ease-in-out;
}

.scale-out-enter {
  transform: scale(.55);
  pointer-events: none;
  opacity: 0;
}

.scale-out-leave-to {
  transform: scale(1.2);
  pointer-events: none;
  opacity: 0;
}


//scale in

.scale-in-enter-active {
  transition: all .35s ease-in-out;
}

.scale-in-leave-active {
  transition: all .35s ease-in-out;
}

.scale-in-enter {
  transform: scale(1.2);
  pointer-events: none;
  opacity: 0;
}

.scale-in-leave-to {
  transform: scale(.55);
  pointer-events: none;
  opacity: 0;
}

.btn-primary.active, .btn-primary:active {
  background-color: #FFA500; /* Color de fondo cuando es pulsado */
  border-color: #FFA500; /* Color de borde cuando es pulsado */
}

.vue-editor__wrapper {
  height: 100px;
}

#editor1,
#editor2 {
  height: 550px;
}

</style>