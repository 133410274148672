import { render, staticRenderFns } from "./dashboardAsignaciones.vue?vue&type=template&id=3bbc6891&scoped=true&"
import script from "./dashboardAsignaciones.vue?vue&type=script&lang=js&"
export * from "./dashboardAsignaciones.vue?vue&type=script&lang=js&"
import style0 from "./dashboardAsignaciones.vue?vue&type=style&index=0&id=3bbc6891&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bbc6891",
  null
  
)

export default component.exports