<template>
  <b-container fluid>

    <b-modal id="modalVerImagenRespuesta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="ModalCrearSesion"
             title="Ver Imagen de Respuesta"
             v-model="modalZoomImagenRespuesta">
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row>
            <b-img rounded :src="url_imagenRespuesta"

                   fluid
                   v-if="imagenNuevaRespuesta != null && editarImagenRespuesta===true">
            </b-img>
          </b-row>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalZoomImagenRespuesta=false"

              >
                Cerrar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalAgregarImagenRespuesta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalImagenRespuesta"
             title="Agregar archivo de Imagen a Respuesta"
             v-model="modalImagenRespuesta">
      <b-alert v-model="modalConfirmacionImagenRespuesta" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionImagenRespuesta"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="primary" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          A continuación seleccione la imagen
        </b-alert>
        <b-card-text>
          <b-row>
            <b-form-file class="my-3"
                         size="sm"
                         v-model="imagenNuevaRespuesta"
                         placeholder="Archivo adjunto..."
                         accept="image/*"
                         drop-placeholder="Arrastrar un archivo para adjuntar."
            ></b-form-file>


          </b-row>
          <b-row>
            <div id="image-containerRespuesta" @click="zoomImageRespuesta">
              <b-img rounded :src="url_imagenRespuesta"
                     height="200"
                     width="200"
                     fluid
                     v-if="editarImagenRespuesta===true"
                     title="Clic para ampliar imagen"
              >
              </b-img>
            </div>
          </b-row>
          <b-row>
            <b-button variant="danger" v-b-modal.modal-prevent-closing size="sm"
                      @click="abrirModalEliminarImagenRespuesta()" class="mt-2"
                      title="Eliminar imagen respuesta"
                      v-if="editarImagenRespuesta===true"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-trash2-fill" viewBox="0 0 16 16">
                <path
                    d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
              </svg>
            </b-button>
          </b-row>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarImagenRespuesta()"
                  title="Guarda archivo"
                  :disabled="disabledBtnGuardarImagenRespuesta"
              >
                {{ textobtnGuardarImagenRespuesta }}
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar "
                  @click="modalImagenRespuesta=false"
                  :disabled="disabledBtnCancelar"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalEliminarImagenRespuesta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalEliminarRespuesta"
             title="Confirmar eliminar imagen de respuesta"
             v-model="modalEliminarImagenRespuesta"
    >
      <b-alert v-model="modalConfirmacionEliminarImagenRespuesta" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionEliminarImagenRespuesta"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea eliminar la imagen?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarImagenRespuesta()"
                  title="Eliminar imagen"
                  :disabled="disabledBtnsEliminarRespuesta"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalEliminarImagenRespuesta=false"
                  :disabled="disabledBtnsEliminarRespuesta"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalEliminarRespuesta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalEliminarRespuesta"
             title="Confirmar eliminar respuesta"
             v-model="modalEliminarRespuesta"
    >
      <b-alert v-model="modalConfirmacionRespuesta" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionRespuesta"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea eliminar la respuesta seleccionada?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarRespuesta()"
                  title="Borrar respuesta"
                  :disabled="disabledBtnsEliminarRespuesta"

              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalEliminarRespuesta=false"
                  :disabled="disabledBtnsEliminarRespuesta"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalAgregarAudioPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="ModalAgregarAudio"
             title="Agregar archivo de Audio a Pregunta"
             v-model="modalAudio"
    >
      <b-alert v-model="modalConfirmacionAudio" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionAudio"/>

      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="primary" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          A continuación seleccione el archivo de audio
        </b-alert>
        <b-card-text>
          <b-row>
            <div v-if="editarAudio && audio != '#' "
                 style="display: flex; justify-content: space-evenly; align-items:center" class="my-2">
              <b-button @click="reemplazarAudio()" variant="outline-primary" class="btn-studioAPP" sm>Reemplazar audio
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg"
                     viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                  <path fill-rule="evenodd"
                        d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                </svg>
              </b-button>
            </div>
            <div v-else>
              <b-row>
                <b-form-file class="my-3"
                             size="sm"
                             v-model="audioNuevo"
                             :state="Boolean(audioNuevo)"
                             placeholder="Archivo adjunto..."
                             accept="audio/mp3"
                             drop-placeholder="Arrastrar un archivo para adjuntar."
                             :filter="filterAudioFile"
                ></b-form-file>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div id="audioModalAgregar" v-if="enabledToolbarAudio===true">
                    <!--INICIO DE BARRA DE PROGRESO-->
                    <div class="wrapper" id="appModalAudio">
                      <!--                  <div class="player">-->

                      <div class="progress" ref="progress">
                        <div class="progress__top">
                          <div class="progress__duration">{{ duration }}</div>
                        </div>
                        <div class="progress__bar" @click="clickProgress">
                          <div class="progress__current" :style="{ width : barWidth }"></div>
                        </div>
                        <div class="progress__time">{{ currentTime }}</div>
                      </div>
                      <!--                  </div>-->
                    </div>

                    <!--FIN    DE BARRA DE PROGRESO-->
                    <!-- controles de audio -->
                    <div id="controlesModalAudio" style="margin-top: 5px">
                      <b-button-toolbar key-nav aria-label="Toolbar with button groups"
                                        v-if="enabledToolbarAudio===true">
                        <b-button-group class="mx-1">
                          <b-button
                              class="btn-studioAPP"
                              variant="outline-warning"
                              size="sm"
                              @click="play()"
                              title="Reproducir audio"

                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#952f81"
                                 class="bi bi-play-btn"
                                 viewBox="0 0 16 16" v-if="audio_play==1">
                              <path
                                  d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                              <path
                                  d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#952f81"
                                 class="bi bi-play-btn"
                                 viewBox="0 0 16 16" v-if="audio_play==0">
                              <path
                                  d="M6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z"/>
                              <path
                                  d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                            </svg>
                          </b-button>

                          <b-button
                              class="btn-studioAPP"
                              variant="outline-warning"
                              size="sm"
                              @click="retrocederAudio()"
                              title="Regresar 10 seg."
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                 class="bi bi-trash2-fill" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                    d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                              <path
                                  d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                            </svg>
                          </b-button>
                          <b-button
                              class="btn-studioAPP"
                              variant="outline-warning"
                              size="sm"
                              @click="adelantarAudio()"
                              title="Adelantar 10 seg."
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                 class="bi bi-trash2-fill" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                              <path
                                  d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                            </svg>
                          </b-button>

                          <b-button
                              class="btn-studioAPP"
                              variant="outline-warning"
                              size="sm"
                              @click="subirAudio()"
                              title="Subir volumen"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                 class="bi bi-trash2-fill" viewBox="0 0 16 16">
                              <path
                                  d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
                              <path
                                  d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
                              <path
                                  d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z"/>
                            </svg>
                          </b-button>

                          <b-button
                              class="btn-studioAPP"
                              variant="outline-warning"
                              size="sm"
                              @click="bajarAudio()"
                              title="Bajar volumen"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                 class="bi bi-trash2-fill" viewBox="0 0 16 16">
                              <path
                                  d="M9 4a.5.5 0 0 0-.812-.39L5.825 5.5H3.5A.5.5 0 0 0 3 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 9 12V4zM6.312 6.39 8 5.04v5.92L6.312 9.61A.5.5 0 0 0 6 9.5H4v-3h2a.5.5 0 0 0 .312-.11zM12.025 8a4.486 4.486 0 0 1-1.318 3.182L10 10.475A3.489 3.489 0 0 0 11.025 8 3.49 3.49 0 0 0 10 5.525l.707-.707A4.486 4.486 0 0 1 12.025 8z"/>
                            </svg>
                          </b-button>

                          <b-button
                              class="btn-studioAPP"
                              variant="outline-warning"
                              size="sm"
                              @click="modalEliminarAudio=true"
                              title="Eliminar archivo de audio"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                 class="bi bi-trash2-fill" viewBox="0 0 16 16">
                              <path
                                  d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                            </svg>
                          </b-button>
                        </b-button-group>
                      </b-button-toolbar>
                    </div>
                  </div>
                  <!-- fin controles de audio -->
                </b-col>
              </b-row>
            </div>


          </b-row>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarAudio()"
                  title="Guarda archivo"
                  :disabled="disabledBtnGuardarAudio"
              >
                {{ textobtnGuardarAudio }}
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar "
                  @click="modalAudio=false"
                  :disabled="disabledBtnCancelar"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalConfirmarEliminarAudioPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalEliminarAudio"
             title="Confirmar eliminar audio de Pregunta"
             v-model="modalEliminarAudio"
    >
      <b-alert v-model="modalConfirmacionAudio" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionAudio"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea eliminar el archivo de audio seleccionado?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarArchivoAudio()"
                  title="Borrar archivo"
                  :disabled="disabledBtnsEliminarAudio"

              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalEliminarAudio=false"
                  :disabled="disabledBtnsEliminarAudio"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalVerImagenPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="ModalCrearSesion"
             title="Ver Imagen de Pregunta"
             v-model="modalZoomImagen">
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row>
            <b-img rounded :src="url_imagen"

                   fluid
                   v-if="imagenNueva != null && editarImagen===true">
            </b-img>
          </b-row>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalZoomImagen=false"

              >
                Cerrar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalAgregarImagenPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="ModalCrearSesion"
             title="Agregar archivo de Imagen a Pregunta"
             v-model="modalImagen">
      <b-alert v-model="modalConfirmacionImagen" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionImagen"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="primary" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          A continuación seleccione la imagen
        </b-alert>
        <b-card-text>
          <b-row>
            <b-form-file class="my-3"
                         size="sm"
                         v-model="imagenNueva"

                         placeholder="Archivo adjunto..."
                         accept="image/*"
                         drop-placeholder="Arrastrar un archivo para adjuntar."
            ></b-form-file>
          </b-row>
          <b-row>
            <div id="image-container" @click="zoomImage">
              <b-img rounded :src="url_imagen"
                     height="200"
                     width="200"
                     fluid
                     v-if="imagenNueva != null && editarImagen===true"
                     title="Clic para ampliar imagen"
              >
              </b-img>
            </div>
          </b-row>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarImagen()"
                  title="Guarda archivo"
                  :disabled="disabledBtnGuardarImagen"
              >
                {{ textobtnGuardarImagen }}
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar "
                  @click="modalImagen=false"
                  :disabled="disabledBtnCancelar"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalConfirmarEliminarImagenPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalEliminarImagen"
             title="Confirmar eliminar imagen de Pregunta"
             v-model="modalEliminarImagen"
    >
      <b-alert v-model="modalConfirmacionImagen" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionImagen"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea eliminar el archivo de imagen seleccionado?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarArchivoImagen()"
                  title="Borrar archivo"
                  :disabled="disabledBtnsEliminarImagen"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalEliminarImagen=false"
                  :disabled="disabledBtnsEliminarImagen"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalAgregarVideoPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalConfirmacionVideo"
             title="Agregar video Youtube a Pregunta"
             v-model="modalYoutube"
    >
      <!--ventana confirmacion -->
      <b-alert v-model="modalConfirmacionVideo" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <!--cerrar ventana confirmacion -->
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="primary" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          A continuación seleccione el Video Youtube que desea anexar a la pregunta
        </b-alert>
        <b-card-text>
          <b-row>
            <div class="col-sm-12">
              <label> <small> Seleccionar video de Youtube</small></label>
              <b-form-select
                  v-model="idVideoSelected"
                  :options="youtubeItems"
                  @change="optionKeyFunction"
                  return object
              >
              </b-form-select>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm-12">
              <youtube
                  :video-id="idVideoSelected"
                  @ready="ready"
                  @playing="playing"
                  :player-vars="{ rel: 0, showinfo: 0, autoplay: 0 }"
                  player-width="100%"
                  player-height="400">
              </youtube>
            </div>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarVideoYoutube()"
                  title="Guarda Video"
                  :disabled="disabledBtnGuardarVideo"
              >
                {{ textobtnGuardarVideo }}
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar "
                  @click="modalYoutube=false"
                  :disabled="disabledBtnCancelar"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-row id="agregarPreguntaDashboardPrincipal">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body">
            <b-alert
                id="tituloAgregarPregunta"
                show
                variant="primary"
                dismissible>
              Agregar Pregunta | Reactivo opción múltiple varias respuestas.
            </b-alert>
            <!--ventana confirmacion general-->
            <b-alert id="alertMensajeConfirmacionGeneral" v-model="modalConfirmacion" variant="light" show>
              <b-button variant="primary" disabled>
                <b-spinner small type="grow"></b-spinner>
                {{ mensajeConfirmacion }}
              </b-button>
            </b-alert>
            <Notificacion id="notificacionGeneral" ref="notificacion"/>

          </div>
          <div class="col-sm-12 my-2">
            <div>
              <b-card no-body>
                <b-tabs card>
                  <!--INICIO TAB1-->
                  <b-tab id="tabPregunta" title="Pregunta" active>
                    <b-card-text>
<!--                      <b-button-toolbar key-nav aria-label="Toolbar with button groups">
                        <b-button-group class="mx-1">
                          <b-button
                              variant="success"
                              size="sm"
                              @click="abrirModalYoutube()"
                              title="Anexar video youtube"
                              :disabled="disabledBarraMultimedia"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-youtube" viewBox="0 0 16 16">
                              <path
                                  d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
                            </svg>
                          </b-button>
                          <b-button
                              variant="success"
                              size="sm"
                              @click="abrirModalImagen()"
                              title="Anexar archivo de imagen"
                              :disabled="disabledBarraMultimedia"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-image" viewBox="0 0 16 16">
                              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                              <path
                                  d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                            </svg>
                          </b-button>

                          <b-button
                              variant="success"
                              size="sm"
                              @click="abrirModalAudio()"
                              title="Anexar archivo de audio"
                              :disabled="disabledBarraMultimedia"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-file-music" viewBox="0 0 16 16">
                              <path
                                  d="M10.304 3.13a1 1 0 0 1 1.196.98v1.8l-2.5.5v5.09c0 .495-.301.883-.662 1.123C7.974 12.866 7.499 13 7 13c-.5 0-.974-.134-1.338-.377-.36-.24-.662-.628-.662-1.123s.301-.883.662-1.123C6.026 10.134 6.501 10 7 10c.356 0 .7.068 1 .196V4.41a1 1 0 0 1 .804-.98l1.5-.3z"/>
                              <path
                                  d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                            </svg>
                          </b-button>
                        </b-button-group>
                      </b-button-toolbar>-->
                      <b-form id="formPreguntas">
                        <b-row>
                          <b-col cols="12">
                            <div id="editor1ED">
                              <b-form-textarea
                                  id="formPlateamientoReactivo"
                                  ref="formPlateamientoReactivo"
                                  v-model="FormPregunta.descripcion"
                                  placeholder="Capturar la pregunta"
                                  rows="4"
                                  max-rows="6"
                              ></b-form-textarea>
<!--                              <vue-editor v-model="FormPregunta.descripcion"
                                          ref="reactivo"
                                          id="editor1"
                                          :editorToolbar="customToolbar"
                                          placeholder="Ingresa la pregunta"
                                          :style="{padding:'10px'}"
                                          @input="checkLengthPregunta"
                              >
                              </vue-editor>-->
                            </div>
                            <!--TOOLBARS VIDEO IMAGEN AUDIO -->
                            <!--TOOLBAR VIDEO -->
<!--                            <div id="video" v-if="video_save===1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#B2007B"
                                   class="bi bi-paperclip"
                                   viewBox="0 0 18 18">
                                <path
                                    d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
                              </svg>

                              <span style="font-size: small"> {{ tituloVideoYoutube }}</span>
                              <b-button
                                  variant="outline-primary"
                                  size="sm"
                                  @click="eliminarVideoYoutube()"
                                  title="Eliminar video"
                                  :disabled="visibleBtnDelVideo"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                  <path
                                      d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                                </svg>
                              </b-button>
                            </div>
                            &lt;!&ndash;TOOLBAR IMAGEN &ndash;&gt;
                            <div id="imagen" v-if="enabledToolbarImagen===true">
                              <b-button-toolbar key-nav aria-label="Toolbar with button groups"
                                                v-if="enabledToolbarImagen===true">
                                <b-button-group class="mx-1">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#B2007B"
                                       class="bi bi-paperclip"
                                       viewBox="0 0 18 18">
                                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    <path
                                        d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                                  </svg>
                                  <b-button
                                      class="btn-studioAPP"
                                      variant="outline-warning"
                                      size="sm"
                                      @click="zoomImage()"
                                      title="Ver imagen"
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                         class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd"
                                            d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                      <path
                                          d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/>
                                      <path fill-rule="evenodd"
                                            d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"/>
                                    </svg>
                                  </b-button>
                                  <b-button
                                      class="btn-studioAPP"
                                      variant="outline-warning"
                                      size="sm"
                                      @click="modalEliminarImagen=true"
                                      title="Eliminar imagen"
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                         class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                      <path
                                          d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                                    </svg>
                                  </b-button>
                                </b-button-group>
                              </b-button-toolbar>
                            </div>

                            <div id="audio" v-if="enabledToolbarAudio===true">
                              <div style="margin-bottom: 12px">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#B2007B"
                                     class="bi bi-music-note-beamed" viewBox="0 0 18 18">
                                  <path
                                      d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z"/>
                                  <path fill-rule="evenodd" d="M14 11V2h1v9h-1zM6 3v10H5V3h1z"/>
                                  <path d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z"/>
                                </svg>
                              </div>
                              &lt;!&ndash;                <span style="font-size: small"> {{url_audio}}</span>&ndash;&gt;
                              &lt;!&ndash;INICIO DE BARRA DE PROGRESO&ndash;&gt;
                              <div class="wrapper" id="app">
                                &lt;!&ndash;                  <div class="player">&ndash;&gt;
                                <div class="progress" ref="progress">
                                  <div class="progress__top">
                                    <div class="progress__duration">{{ duration }}</div>
                                  </div>
                                  <div class="progress__bar" @click="clickProgress">
                                    <div class="progress__current" :style="{ width : barWidth }"></div>
                                  </div>
                                  <div class="progress__time">{{ currentTime }}</div>
                                </div>
                                &lt;!&ndash;                  </div>&ndash;&gt;
                              </div>
                              &lt;!&ndash;FIN    DE BARRA DE PROGRESO&ndash;&gt;
                              &lt;!&ndash; controles de audio &ndash;&gt;
                              <div id="controles" style="margin-top: 5px">
                                <b-button-toolbar key-nav aria-label="Toolbar with button groups"
                                                  v-if="enabledToolbarAudio===true">
                                  <b-button-group class="mx-1">
                                    <b-button
                                        class="btn-studioAPP"
                                        variant="outline-warning"
                                        size="sm"
                                        @click="play()"
                                        title="Reproducir audio"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#952f81"
                                           class="bi bi-play-btn"
                                           viewBox="0 0 16 16" v-if="audio_play==1">
                                        <path
                                            d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                                        <path
                                            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                                      </svg>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#952f81"
                                           class="bi bi-play-btn"
                                           viewBox="0 0 16 16" v-if="audio_play==0">
                                        <path
                                            d="M6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z"/>
                                        <path
                                            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                                      </svg>
                                    </b-button>
                                    <b-button
                                        class="btn-studioAPP"
                                        variant="outline-warning"
                                        size="sm"
                                        @click="retrocederAudio()"
                                        title="Regresar 10 seg."
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                           class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                              d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                                        <path
                                            d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                                      </svg>
                                    </b-button>
                                    <b-button
                                        class="btn-studioAPP"
                                        variant="outline-warning"
                                        size="sm"
                                        @click="adelantarAudio()"
                                        title="Adelantar 10 seg."
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                           class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                              d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                                        <path
                                            d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                                      </svg>
                                    </b-button>

                                    <b-button
                                        class="btn-studioAPP"
                                        variant="outline-warning"
                                        size="sm"
                                        @click="subirAudio()"
                                        title="Subir volumen"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                           class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
                                        <path
                                            d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
                                        <path
                                            d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z"/>
                                      </svg>
                                    </b-button>

                                    <b-button
                                        class="btn-studioAPP"
                                        variant="outline-warning"
                                        size="sm"
                                        @click="bajarAudio()"
                                        title="Bajar volumen"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                           class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M9 4a.5.5 0 0 0-.812-.39L5.825 5.5H3.5A.5.5 0 0 0 3 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 9 12V4zM6.312 6.39 8 5.04v5.92L6.312 9.61A.5.5 0 0 0 6 9.5H4v-3h2a.5.5 0 0 0 .312-.11zM12.025 8a4.486 4.486 0 0 1-1.318 3.182L10 10.475A3.489 3.489 0 0 0 11.025 8 3.49 3.49 0 0 0 10 5.525l.707-.707A4.486 4.486 0 0 1 12.025 8z"/>
                                      </svg>
                                    </b-button>

                                    <b-button
                                        class="btn-studioAPP"
                                        variant="outline-warning"
                                        size="sm"
                                        @click="modalEliminarAudio=true"
                                        title="Eliminar video"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                           class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                                      </svg>
                                    </b-button>
                                  </b-button-group>
                                </b-button-toolbar>
                              </div>
                              &lt;!&ndash; fin controles de audio &ndash;&gt;
                            </div>-->

                            <!--
                                          <editor-de-ecuaciones></editor-de-ecuaciones>-->


                            <b-button
                                variant="primary"
                                size="sm"
                                class="float-right mx-2 my-1"
                                @click="nuevaPregunta()"
                                title="Cancelar"
                                v-if="FormPregunta.visibleBtnNuevaPregunta===true"
                            >
                              Nueva Pregunta
                            </b-button>

                            <b-button
                                variant="success"
                                size="sm"
                                class="float-right mx-2 my-1"
                                @click="guardarPregunta()"
                                title="Guarda Pregunta"

                            >
                              Guardar
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>
                  </b-tab>
                  <!--FIN TAB1-->
                  <!--INICIO TAB2-->
                  <b-tab id="tabRespuestas" title="Respuestas" :disabled="FormRespuestas.disabledTabRespuestas">
                    <b-card-text>
<!--                      <b-button-toolbar key-nav aria-label="Toolbar with button groups">
                        <b-button-group class="mx-1">
&lt;!&ndash;                          <b-button
                              variant="success"
                              size="sm"
                              @click="abrirModalYoutube()"
                              title="Anexar video youtube"
                              :disabled="disabledRespuesta"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-youtube" viewBox="0 0 16 16">
                              <path
                                  d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
                            </svg>
                          </b-button>&ndash;&gt;
                          <b-button
                              variant="success"
                              size="sm"
                              @click="abrirModalImagenRespuesta()"
                              title="Anexar imagen"
                              :disabled="disabledBarraMultimediaRespuesta"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-image" viewBox="0 0 16 16">
                              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                              <path
                                  d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                            </svg>
                          </b-button>

&lt;!&ndash;                          <b-button
                              variant="success"
                              size="sm"
                              @click="guardarPregunta()"
                              title="Anexar archivo de audio"
                              :disabled="disabledRespuesta"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-file-music" viewBox="0 0 16 16">
                              <path
                                  d="M10.304 3.13a1 1 0 0 1 1.196.98v1.8l-2.5.5v5.09c0 .495-.301.883-.662 1.123C7.974 12.866 7.499 13 7 13c-.5 0-.974-.134-1.338-.377-.36-.24-.662-.628-.662-1.123s.301-.883.662-1.123C6.026 10.134 6.501 10 7 10c.356 0 .7.068 1 .196V4.41a1 1 0 0 1 .804-.98l1.5-.3z"/>
                              <path
                                  d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                            </svg>
                          </b-button>&ndash;&gt;
                        </b-button-group>
                      </b-button-toolbar>-->
                      <b-row id="formRespuestas">
                        <b-col id="textAreaRespuesta" cols="8">
                          <div id="editor2ED" class="mb-2">
<!--                            <vue-editor v-model="FormRespuestas.respuesta"
                                        ref="respuesta"
                                        id="editor2"
                                        :editorToolbar="customToolbar"
                                        placeholder="Captura la respuesta"
                                        :disabled="disabledRespuesta"
                                        class="mb-5"
                                        @input="checkLengthRespuesta"
                            >
                            </vue-editor>-->
                            <b-form-textarea
                                id="formRespuesta"
                                ref="formRespuesta"
                                v-model="FormRespuestas.respuesta"
                                placeholder="Capturar la respuesta"
                                rows="1"
                                max-rows="2"
                            ></b-form-textarea>
                          </div>
                        </b-col>
                        <b-col id="botonesRespuesta" cols="4">
                          <div  class="w-100">

                            <b-button
                                variant="success"
                                size="sm"
                                class="float-left mr-2 mt-5"
                                @click="guardarRespuesta()"
                            >
                              Guardar
                            </b-button>
                            <b-button
                                variant="primary"
                                size="sm"
                                class="float-left  mt-5"
                                @click="nuevaRespuesta()"
                                title="Nueva Respuesta"
                                v-if="FormRespuestas.visibleBtnNuevaRespuesta===true"
                            >
                              Nueva Respuesta
                            </b-button>

                          </div>
                        </b-col>
                      </b-row>
                      <b-row id="dataTableRespuestas">
                        <b-col cols="12">
                          <b-table class="table data-table"
                                   :items="FormRespuestas.data"
                                   :fields="FormRespuestas.fields"
                                   :current-page="FormRespuestas.currentPage"
                                   :per-page="FormRespuestas.perPage"
                                   :filter="FormRespuestas.filter"
                                   :filter-included-fields="FormRespuestas.filterOn"
                                   :striped="true"
                                   hover
                                   show-empty
                                   :small="true"
                          >
                            <template #empty="scope">
                              <h5 class="text-center">
                                {{ scope.emptyText = 'Por el momento no existe información que mostrar.' }}
                              </h5>
                            </template>
                            <template #emptyfiltered="scope">
                              <h5 class="text-center">
                                {{
                                  scope.emptyFilteredText = 'No existen información que coincida con los parámetros de búsqueda.'
                                }}
                              </h5>
                            </template>
                            <template #cell(respuesta)="row">
                              <div v-html="row.value"></div>
                            </template>
                            <template #cell(correcta)="row">
                              <div v-if="row.item.correcta==0">
                                <b-button
                                    variant="primary"
                                    size="sm"
                                    @click="activarRespuestaCorrecta(row.item.uuid_respuesta)"
                                    title="Activar"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                       class="bi bi-youtube" viewBox="0 0 16 16">
                                    <path
                                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                  </svg>
                                </b-button>
                              </div>

                              <div v-if="row.item.correcta==1">
                                <b-button
                                    variant="success"
                                    size="sm"
                                    @click="desactivarRespuestaCorrecta(row.item.uuid_respuesta)"
                                    title="Desactivar"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                       class="bi bi-youtube" viewBox="0 0 16 16">
                                    <path
                                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                    <path
                                        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                  </svg>
                                </b-button>
                              </div>
                            </template>
                            <template #cell(actions)="row">
                              <div class="text-center">
                                <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                          @click="setRespuestaEdit(row.item)" class="mr-1" title="Editar respuesta">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                       class="bi bi-pencil-square" viewBox="0 0 16 16">
                                    <path
                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd"
                                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                  </svg>
                                </b-button>
                                <b-button variant="danger" v-b-modal.modal-prevent-closing size="sm"
                                          @click="abrirModalRespuesta(row.item)" class="mr-1"
                                          title="Eliminar respuesta">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                       class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                                  </svg>
                                </b-button>
                              </div>
                            </template>

                            <template #cell(url_imagen)="row">
                              <div v-if="row.item.url_imagen!=null">
                                <b-button
                                    variant="warning"
                                    size="sm"
                                    @click="abrirEditarImagenRespuestaRow(row.item)"
                                    title="Ver imagen"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                       class="bi bi-youtube" viewBox="0 0 16 16">
                                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>                                  </svg>
                                </b-button>
                              </div>
                            </template>
                          </b-table>
                          <div class="d-flex justify-content-between row p-2">
                            <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                              <div>
                                <b-pagination
                                    v-model="FormRespuestas.currentPage"
                                    :total-rows="FormRespuestas.totalRows"
                                    :per-page="FormRespuestas.perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                >
                                </b-pagination>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                  <!--FIN TAB2-->
                </b-tabs>
              </b-card>
              <!--FIN DEL CUERPO TABS-->
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

  </b-container>
</template>
/* eslint-disable vue/no-unused-vars */
<script>
import toast from "@/mixins/ToastMixin.js";
import PreguntaService from "@/services/PreguntaService";
import YoutubeService from "@/services/Youtube/YoutubeService";
import moment from "moment"
import 'moment/locale/es';
import {getIdFromURL} from 'vue-youtube-embed';
import Notificacion from '@/components/Notificacion.vue'
//import {VueEditor} from "vue2-editor";

export default {
  components: {
    Notificacion,
    //VueEditor
  },
  mixins: [toast],
  data() {
    return {
      FormRespuestas: {
        respuesta: null,
        data: [],
        fields: [
          {label: 'Correcta', key: 'correcta', class: 'text-center'},
          {label: 'Respuesta', key: 'respuesta', class: 'text-center'},
          //{label: 'Imagen', key: 'url_imagen', class: 'text-center'},
          {label: 'Acciones', key: 'actions', class: 'text-center'}
        ],
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: [],
        totalRows: 0,
        uuid_respuesta: 0,
        disabledTabRespuestas:true,
        disabledBarraMultimediaRespuesta: true,
        visibleBtnNuevaRespuesta:false,
        textobtnGuardarRespuesta: "Guardar Respuesta",
      },
      FormPregunta:{
        descripcion: null,
        uuid_pregunta: 0,
        disabledBarraMultimedia:true,
        visibleBtnNuevaPregunta:false,
      },
      /*variables Generales*/
      disabledBtnCancelar: false,
      /*VARIABLES DE BARRA DE PROGRESO*/
      barWidth: null,
      duration: null,
      currentTime: null,
      isTimerPlaying: false,

      /*VARIABLES VIDEO*/
      textobtnGuardarVideo: "Guardar video",
      modalConfirmacionVideo: false,
      disabledBtnGuardarVideo: true,
      modalYoutube: false,
      video_save: 0,
      mensajeConfirmacion: null,
      modalConfirmacion: false,

      disabledBarraMultimedia: true,


      /* activar desactivar botones, textbox,textarea */
      visibleBtnDelVideo: true,


      tituloVideoYoutube: null,
      userSelectedVideo: 0,
      idVideoSelected: null,
      youtubeItems: [],
      youtubeItems_count: 0,


      //VARIABLES PARA IMAGEN
      disabledBtnsEliminarImagen: false,
      modalEliminarImagen: false,
      textobtnGuardarImagen: "Guardar archivo",
      disabledBtnGuardarImagen: false,
      modalConfirmacionImagen: false,
      primer_guardado_nameFileImagen: 0,
      nombre_archivo_original_imagen: null,
      url_imagen: null,
      imagenNueva: null,
      imagen: null,
      editarImagen: false,
      enabledToolbarImagen: false,
      modalZoomImagen: false,
      modalImagen: false,
      objImagen: null,
      //FIN VARIABLES PARA IMAGEN

      //VARIABLES PARA AUDIO
      disabledBtnsEliminarAudio: false,
      modalAudio: false,
      modalEliminarAudio: false,
      textobtnGuardarAudio: "Guardar archivo",
      disabledBtnGuardarAudio: false,
      primer_guardado_nameFileAudio: 0,
      nombre_archivo_original_audio: null,
      enabledToolbarAudio: false,
      modalConfirmacionAudio: false,
      audio_play: null,
      objAudio: null,
      audioNuevo: null,
      audio: null,
      editarAudio: false,
      url_audio: null,
      //fin vars para audio

      //variables pregunta
      textobtnGuardarPregunta: "Guardar Pregunta",
      disabledBtnGuardarPregunta: true,
      primer_guardado_pregunta: 0,
      contenidoPreguntaOriginal: null,
      uuid_pregunta: 0,
      //fin variables pregunta

      //variables RESPUESTA
      modalConfirmacionEliminarImagenRespuesta:false,
      modalEliminarImagenRespuesta:false,
      objImagenRespuesta: null,
      modalZoomImagenRespuesta: false,
      primer_guardado_nameFileImagenRespuesta: 0,
      nombre_archivo_original_imagenRespuesta: null,
      editarImagenRespuesta: false,
      disabledBarraMultimediaRespuesta: true,
      textobtnGuardarImagenRespuesta: "Guardar archivo",
      disabledBtnGuardarImagenRespuesta: false,
      url_imagenRespuesta: null,
      imagenNuevaRespuesta: null,
      modalImagenRespuesta:false,
      modalConfirmacionImagenRespuesta:false,

      maximoRespuestasOkPermitidas: 1,
      totalRespuestasOk: 0,
      respuesta: null,
      resultSetRespuestas: [],
      disabledBtnGuardarRespuesta: true,
      disabledBtnNuevaRespuesta: true,
      textobtnGuardarRespuesta: "Guardar Respuesta",
      disabledRespuesta: true,
      uuid_respuesta: 0,
      modalEliminarRespuesta: false,
      modalConfirmacionRespuesta: false,
      disabledBtnsEliminarRespuesta: false,
      //fin variables RESPUESTA



      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      filter: null,
      filterOn: [],
      resultSet: [],
      search: '',
      nombre_evento: null,
      numero_cuenta_pago: null,

      totalRows_reactivos: 1,
      customToolbar:
          [
            [{header: [false, 1, 2, 3, 4, 5, 6]}],
            ["bold", "italic", "underline", "strike"],
            [{color: []}],
            [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
            [
              {align: ""},
              {align: "center"},
              {align: "right"},
              {align: "justify"}
            ],
            [{indent: "-1"}, {indent: "+1"}],
            ["clean"]
          ],

    }
  },
  created() {
    this.objAudio = new Audio();
  },

  methods: {
    nuevaPregunta()
    {
      this.FormPregunta.visibleBtnNuevaPregunta = false
      this.FormPregunta.uuid_pregunta= 0
      this.FormPregunta.descripcion = null

      this.FormRespuestas.data = []
      this.FormRespuestas.totalRows = 1
      this.FormRespuestas.visibleBtnNuevaRespuesta = false
      this.FormRespuestas.disabledTabRespuestas = true

      this.objAudio = new Audio()
      this.editarImagen= false
      this.imagenNueva = null
      this.textobtnGuardarPregunta= "Guardar Pregunta"
      this.textobtnGuardarRespuesta= "Guardar Respuesta"
      this.disabledBtnGuardarPregunta= true

      this.enabledToolbarImagen = false
      this.disabledBarraMultimedia = false
      this.editarImagen = false
      this.url_imagen = null

      this.enabledToolbarAudio = false
      this.url_audio = null
      this.audio_play = null

      this.barWidth= null
      this.duration= null
      this.currentTime= null
      this.isTimerPlaying= false
      this.disabledRespuesta = false
    },
    abrirModalEliminarImagenRespuesta(){
      this.modalEliminarImagenRespuesta = true
    },
    async eliminarImagenRespuesta() {
      try {
        this.disabledBtnsEliminarRespuesta = true
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionEliminarImagenRespuesta = true
        const response = await PreguntaService.deleteImagenRespuesta(this.FormRespuestas.uuid_respuesta);
        let {
          success,
          message,
          color
          , respuestas
          , cantidadRespuestas
        } = response.data;
        if (success == true) {
          //this.toast('b-toaster-bottom-center', true, message, color );
          this.editarImagenRespuesta=false
          this.primer_guardado_nameFileImagenRespuesta= 0,
          this.nombre_archivo_original_imagenRespuesta= null,
          this.showNotificacionImagenRespuesta(message, color)
          this.modalEliminarImagenRespuesta = false;
          this.modalConfirmacionEliminarImagenRespuesta = false

          this.resultSetRespuestas = []
          this.resultSetRespuestas = respuestas;
          this.totalRows = cantidadRespuestas;
          //queda pendiente eliminar fisicamente el archivo en
          //cloud storage
          //
        }
      } catch (error) {
        this.modalEliminarImagenRespuesta = false;
        this.modalConfirmacionEliminarImagenRespuesta = false
        this.showNotificacionImagenRespuesta('Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      }
    },
    async eliminarRespuesta() {
      try {
        this.disabledBtnsEliminarRespuesta = true
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionRespuesta = true
        const response = await PreguntaService.deleteRespuesta(this.FormRespuestas.uuid_respuesta);
        let {
          success,
          message,
          color
          , respuestas
          , cantidadRespuestas
        } = response.data;
        if (success == true) {
          //this.toast('b-toaster-bottom-center', true, message, color );
          this.showNotificacion(message, color)
          this.modalEliminarRespuesta = false;
          this.modalConfirmacionRespuesta = false

          this.FormRespuestas.data = []
          this.FormRespuestas.data = respuestas;
          this.FormRespuestas.totalRows = cantidadRespuestas;
          //queda pendiente eliminar fisicamente el archivo en
          //cloud storage
          //
        }
      } catch (error) {
        this.showNotificacionRespuesta('Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      }
    },
    resetVariablesImagenRespuesta(){
          this.modalZoomImagenRespuesta= false
          this.primer_guardado_nameFileImagenRespuesta= 0
          this.nombre_archivo_original_imagenRespuesta= null
          this.editarImagenRespuesta= false
          this.disabledBarraMultimediaRespuesta= true
          this.textobtnGuardarImagenRespuesta= "Guardar archivo"
          this.disabledBtnGuardarImagenRespuesta= false
          this.url_imagenRespuesta= null
          this.imagenNuevaRespuesta= null
          this.modalImagenRespuesta=false
          this.modalConfirmacionImagenRespuesta=false
    },
    nuevaRespuesta() {
      this.FormRespuestas.visibleBtnNuevaRespuesta = false
      this.FormRespuestas.respuesta = null
      this.$refs.formPlateamientoReactivo.focus();
      this.disabledBtnGuardarRespuesta = true
      this.disabledBtnNuevaRespuesta = true
      this.textobtnGuardarRespuesta = "Guardar Respuesta"
      this.FormRespuestas.uuid_respuesta = 0
      this.$refs.formRespuesta.focus()
      //reset variables de imagen respuesta
      this.resetVariablesImagenRespuesta()
    },

    checkLengthPregunta() {
      if (this.FormPregunta.descripcion.length >= 12)
        this.disabledBtnGuardarPregunta = false
      else
        this.disabledBtnGuardarPregunta = true
    },
    checkLengthRespuesta() {
      if (this.FormRespuestas.respuesta.length >= 12)
        this.disabledBtnGuardarRespuesta = false
      else
        this.disabledBtnGuardarRespuesta = true
    },

    play() {
      this.audio_play = !this.audio_play
      if (this.objAudio.paused) {
        this.objAudio.play();
        this.isTimerPlaying = true;
      } else {
        this.objAudio.pause();
        this.isTimerPlaying = false;
      }
    },
    generateTime() {
      let width = (100 / this.objAudio.duration) * this.objAudio.currentTime;
      this.barWidth = width + "%";
      //this.circleLeft = width + "%";
      let durmin = Math.floor(this.objAudio.duration / 60);
      let dursec = Math.floor(this.objAudio.duration - durmin * 60);
      let curmin = Math.floor(this.objAudio.currentTime / 60);
      let cursec = Math.floor(this.objAudio.currentTime - curmin * 60);
      if (durmin < 10) {
        durmin = "0" + durmin;
      }
      if (dursec < 10) {
        dursec = "0" + dursec;
      }
      if (curmin < 10) {
        curmin = "0" + curmin;
      }
      if (cursec < 10) {
        cursec = "0" + cursec;
      }
      this.duration = durmin + ":" + dursec;
      this.currentTime = curmin + ":" + cursec;
    },
    updateBar(x) {
      let progress = this.$refs.progress;
      let maxduration = this.objAudio.duration;
      let position = x - progress.offsetLeft;
      let percentage = (100 * position) / progress.offsetWidth;
      if (percentage > 100) {
        percentage = 100;
      }
      if (percentage < 0) {
        percentage = 0;
      }
      // this.barWidth = percentage + "%";
      // this.circleLeft = percentage + "%";
      this.objAudio.currentTime = (maxduration * percentage) / 100;
      this.objAudio.play();
    },
    zoomImage() {
      this.modalZoomImagen = true
    },
    zoomImageRespuesta() {
      this.modalZoomImagenRespuesta = true
    },
    clickProgress(e) {
      this.isTimerPlaying = true;
      this.objAudio.pause();
      this.updateBar(e.pageX);
    },
    async guardarVideoYoutube() {
      this.disabledBtnGuardarVideo = true
      this.disabledBtnCancelar = true
      let formData = new FormData();
      formData.append("video_id", this.idVideoSelected);
      formData.append("uuid_pregunta", this.FormPregunta.uuid_pregunta);
      /* formData.append("imagen",this.imagenNueva);*/
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacionVideo = true
      this.disabledBtnGuardarPregunta = true
      const response = await PreguntaService.updateVideo(formData);
      let {
        success,
        uuid_pregunta,
        message,
        color
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.textobtnGuardarVideo = "Actualizar video"
        this.disabledBtnGuardarVideo = false
        this.disabledBtnCancelar = false
        this.modalConfirmacionVideo = false
        this.FormPregunta.uuid_pregunta = uuid_pregunta
        this.video_save = 1
        this.showNotificacion(message, color);

      } else {
        this.showNotificacion(message, color);
      }
    },
    filterAudioFile(file) {
      return /\.mp3$/i.test(file.name);
    },
    async desactivarRespuestaCorrecta(uuid) {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      let formData = new FormData();
      formData.append("correcta", 0);
      formData.append("uuid_respuesta", uuid);
      formData.append("uuid_pregunta", this.FormPregunta.uuid_pregunta);
      const response = await PreguntaService.desactivarRespuestaCorrecta(formData);
      let {
        success,
        //uuid_respuesta,
        message
        ,color
        ,respuestas
        ,cantidadRespuestas
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormRespuestas.data = []
        this.FormRespuestas.data = respuestas;
        this.FormRespuestas.totalRows = cantidadRespuestas;
        this.modalConfirmacion = false
        this.FormRespuestas.uuid_respuesta = 0
      }
      this.showNotificacion(message, color)
    },
    async saveOptionMultipleUnicaRespuesta(uuid) {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacionRespuesta = true
      this.disabledBtnsEliminarRespuesta = true
      let formData = new FormData();
      formData.append("correcta", 1);
      formData.append("uuid_respuesta", uuid);
      formData.append("uuid_pregunta", this.FormPregunta.uuid_pregunta);
      const response = await PreguntaService.saveOptionMultipleUnicaRespuesta(formData);
      let {
        success,
        uuid_respuesta,
        message
        , color
        , respuestas
        , cantidadRespuestas
      } = response.data;
      this.modalConfirmacionRespuesta = false
      if (success) {
        this.resultSetRespuestas = []
        this.resultSetRespuestas = respuestas;
        this.totalRows = cantidadRespuestas;
        this.modalEliminarRespuesta = false
        this.FormRespuestas.uuid_respuesta = uuid_respuesta
      }
      this.showNotificacion(message, color)
    },


    async activarRespuestaCorrecta(uuid) {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      let formData = new FormData();
      formData.append("correcta", 1);
      formData.append("uuid_respuesta", uuid);
      formData.append("uuid_pregunta", this.FormPregunta.uuid_pregunta);
      const response = await PreguntaService.activarRespuestaCorrecta(formData);
      let {
        success,
        //uuid_respuesta,
        message
        ,color
        ,respuestas
        ,cantidadRespuestas
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormRespuestas.data = []
        this.FormRespuestas.data = respuestas;
        this.FormRespuestas.totalRows = cantidadRespuestas;
        this.modalConfirmacion = false
        this.FormRespuestas.uuid_respuesta = 0
      }
      this.showNotificacion(message, color)
    },
    abrirModalRespuesta(rowRespuesta) {
      this.FormRespuestas.uuid_respuesta = rowRespuesta.uuid_respuesta
      this.modalEliminarRespuesta = true
      this.disabledBtnsEliminarRespuesta = false
    },
    abrirEditarImagenRespuestaRow(rowRespuesta){
      this.FormRespuestas.uuid_respuesta=rowRespuesta.uuid_respuesta
      console.log("imagen selecccionada")
      console.log(rowRespuesta)
      this.resetVariablesImagenRespuesta()
      this.modalImagenRespuesta=true
      this.editarImagenRespuesta = true
      this.url_imagenRespuesta = rowRespuesta.url_imagen

      this.textobtnGuardarImagenRespuesta = "Actualizar archivo"
      this.btnsDisabledModalImagenRespuesta(false)
      this.disabledBarraMultimediaRespuesta = false
      this.modalConfirmacionImagenRespuesta = false
      this.nombre_archivo_original_imagenRespuesta = rowRespuesta.url_imagen
      this.primer_guardado_nameFileImagenRespuesta = 1
      this.imagenNuevaRespuesta = null
    },
    abrirModalAudio() {
      this.modalAudio = true
    },
    abrirModalImagen() {
      this.modalImagen = true
    },
    abrirModalImagenRespuesta() {
      this.modalImagenRespuesta = true
    },

    abrirModalYoutube() {
      this.modalYoutube = true
    },
    adelantarAudio() {
      this.objAudio.currentTime += 10
    },
    reemplazarAudio() {
      this.audio = '#';
      this.reemplazarAudio = true;
    },
    eliminarVideoYoutube() {

    },
    bajarAudio() {
      if (this.objAudio.volume > 0) {
        this.objAudio.volume -= 0.1
      }
    },
    async eliminarArchivoAudio() {
      try {
        this.disabledBtnsEliminarAudio = true
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionAudio = true
        const response = await PreguntaService.deleteAudio(this.FormPregunta.uuid_pregunta);
        let {
          success,
          message,
          color
        } = response.data;
        if (success == true) {
          //this.toast('b-toaster-bottom-center', true, message, color );
          this.showNotificacion(message, color)
          this.modalEliminarAudio = false;
          this.modalAudio = false
          //TODO reset variables de audio
          this.resetVarsAudio()
          this.modalConfirmacionAudio = false
          //queda pendiente eliminar fisicamente el archivo en
          //cloud storage
          //
        }
      } catch (error) {
        this.showNotificacionAudio('Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      }
    },
    async eliminarArchivoImagen() {
      try {
        this.disabledBtnsEliminarImagen = true
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionImagen = true
        const response = await PreguntaService.deleteImagen(this.FormPregunta.uuid_pregunta);
        let {
          success,
          message,
          color
        } = response.data;
        if (success == true) {
          //this.toast('b-toaster-bottom-center', true, message, color );
          this.showNotificacion(message, color)
          this.modalEliminarImagen = false;
          this.modalImagen = false
          //TODO reset variables de imagen
          this.resetVarsImagen()
          this.modalConfirmacionImagen = false
          //queda pendiente eliminar fisicamente el archivo en
          //cloud storage
          //
        }
      } catch (error) {
        this.showNotificacion('Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      }
    },
    btnsDisabledModalAudio(estatus) {
      this.disabledBtnGuardarAudio = estatus
      this.disabledBtnCancelar = estatus
    },
    btnsDisabledModalImagen(estatus) {
      this.disabledBtnGuardarImagenRespuesta = estatus
      this.disabledBtnCancelar = estatus
    },
    btnsDisabledModalImagenRespuesta(estatus) {
      this.disabledBtnGuardarImagen = estatus
      this.disabledBtnCancelar = estatus
    },
    retrocederAudio() {
      this.objAudio.currentTime -= 10
    },
    resetVarsAudio() {
      this.modalAudio = false
      this.modalEliminarAudio = false
      this.textobtnGuardarAudio = "Guardar archivo",
      this.disabledBtnGuardarAudio = false,
      this.primer_guardado_nameFileAudio = 0
      this.nombre_archivo_original_audio = null
      this.enabledToolbarAudio = false
      this.modalConfirmacionAudio = false
      this.audio_play = null
      //this.objAudio= null
      this.audioNuevo.name = null
      this.audioNuevo = null
      this.audio = null
      this.editarAudio = false
      this.url_audio = null
    },
    resetVarsImagen() {
      this.modalEliminarImagen = false
      this.textobtnGuardarImagen = "Guardar archivo"
      this.disabledBtnGuardarImagen = false
      this.modalConfirmacionImagen = false
      this.primer_guardado_nameFileImagen = 0
      this.nombre_archivo_original_imagen = null
      this.url_imagen = null
      this.imagenNueva = null
      this.imagen = null
      this.editarImagen = false
      this.enabledToolbarImagen = false
      this.modalZoomImagen = false
      this.modalImagen = false
    },
    ready(event) {
      this.player = event.target;
    },
    playing(event) {
      console.log("playing event..." + event);
    },

    getCurrentVideoId(youtube_url) {
      return getIdFromURL(youtube_url)
    },
    optionKeyFunction() {
      this.userSelectedVideo = 1
      const videoYoutubeSelected = this.youtubeItems.find(object => object.value === this.idVideoSelected);
      this.tituloVideoYoutube = videoYoutubeSelected.label
      this.disabledBtnGuardarVideo = false
    },
    async getYoutubeVideos() {
      //segundo select
      try {
        let response = await YoutubeService.getYoutubeVideos();
        let {resultSet} = response.data;
        this.youtubeItems_count = resultSet.count;
        this.youtubeItems = resultSet.entries;
        /*console.log(this.youtubeItems_count)
        console.log(this.youtubeItems)*/
      } catch (error) {
        this.showNotificacion('Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      }
    },

    async guardarAudio() {
      let formData = new FormData();
      formData.append("uuid_pregunta", this.FormPregunta.uuid_pregunta);
      formData.append("archivo_audio", this.audioNuevo);
      formData.append("nombre_archivo_audio", this.audioNuevo.name);
      if (this.audioNuevo.name != this.nombre_archivo_original_audio) {
        this.disabledBarraMultimedia = true
        this.btnsDisabledModalAudio(true)
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionAudio = true
        const response = await PreguntaService.storeFileAudio(formData);
        let {
          success,
          url_audio,
          message
        } = response.data;
        this.modalConfirmacion = false
        if (success) {
          this.enabledToolbarAudio = true
          if (this.primer_guardado_nameFileAudio === 0) {
            this.nombre_archivo_original_audio = this.audioNuevo.name
            this.primer_guardado_nameFileAudio = 1
          }
          let vm = this;
          this.url_audio = url_audio
          this.objAudio.src = this.url_audio
          this.objAudio.ontimeupdate = function () {
            vm.generateTime();
          };
          this.objAudio.onloadedmetadata = function () {
            vm.generateTime();
          };
        }
        ///this.toast('b-toaster-bottom-center', true, message, color);
        this.showNotificacionAudio(message, 'success')
        this.textobtnGuardarAudio = "Actualizar archivo"
        this.btnsDisabledModalAudio(false)
        this.audio_play = 1
        this.disabledBarraMultimedia = false
        this.modalConfirmacionAudio = false
        this.modalConfirmacion = false
      } else {
        //seleccionando el mismo archivo de audio
        this.showNotificacionAudio('¡El archivo seleccionado es el mismo que el almacenado. Favor de verificar!', 'danger')
        ///this.toast('b-toaster-bottom-center', true, '¡El archivo seleccionado es el mismo que el almacenado. Favor de verificar!', 'danger');
      }
    },
    async guardarImagen() {
      let formData = new FormData();
      formData.append("uuid_pregunta", this.FormPregunta.uuid_pregunta);
      formData.append("archivo_imagen", this.imagenNueva);
      formData.append("nombre_archivo_imagen", this.imagenNueva.name);
      this.objImagen = this.imagenNueva
      if (this.imagenNueva.name != this.nombre_archivo_original_imagen) {
        this.disabledBarraMultimedia = true
        this.btnsDisabledModalImagen(true)
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionImagen = true
        const response = await PreguntaService.storeFileImagen(formData);
        let {
          success,
          url_imagen,
          message
          , color
        } = response.data;
        this.modalConfirmacion = false
        if (success) {
          this.enabledToolbarImagen = true
          this.editarImagen = true
          this.url_imagen = url_imagen
          if (this.primer_guardado_nameFileImagen === 0) {
            this.nombre_archivo_original_imagen = this.imagenNueva.name
            this.primer_guardado_nameFileImagen = 1
          }
        }
        //this.toast('b-toaster-bottom-center', true, message, color);
        this.showNotificacionImagen(message, color)
        this.textobtnGuardarImagen = "Actualizar archivo"
        this.btnsDisabledModalImagen(false)
        this.disabledBarraMultimedia = false
        this.modalConfirmacionImagen = false
        this.modalConfirmacion = false
      } else {
        //seleccionando el mismo archivo de audio
        //this.toast('b-toaster-bottom-center', true, '¡El archivo seleccionado es el mismo que el almacenado. Favor de verificar!', 'danger');
        this.showNotificacion('¡El archivo seleccionado es el mismo que el almacenado. Favor de verificar!', 'danger')
      }

    },
    async guardarImagenRespuesta() {
      let formData = new FormData();
      formData.append("uuid_respuesta", this.FormRespuestas.uuid_respuesta);
      formData.append("uuid_pregunta", this.FormPregunta.uuid_pregunta);
      formData.append("archivo_imagen", this.imagenNuevaRespuesta);
      formData.append("nombre_archivo_imagen", this.imagenNuevaRespuesta.name);
      this.objImagenRespuesta = this.imagenNuevaRespuesta
      if (this.imagenNuevaRespuesta.name != this.nombre_archivo_original_imagenRespuesta) {
        this.disabledBarraMultimediaRespuesta = true
        this.btnsDisabledModalImagenRespuesta(true)
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionImagenRespuesta = true
        const response = await PreguntaService.storeFileImagenRespuesta(formData);
        let {
          success,
          url_imagen,
          message
          , color
          , respuestas
          , cantidadRespuestas
        } = response.data;
        this.modalConfirmacionImagenRespuesta = false
        if (success)
        {
          //update datagrid Respuestas
          this.resultSetRespuestas = []
          this.resultSetRespuestas = respuestas;
          this.totalRows = cantidadRespuestas;


          //this.enabledToolbarImagen = true
          this.editarImagenRespuesta = true
          this.url_imagenRespuesta = url_imagen
          this.showNotificacionImagenRespuesta(message, color)
          this.textobtnGuardarImagenRespuesta = "Actualizar archivo"
          this.btnsDisabledModalImagenRespuesta(false)
          this.disabledBarraMultimediaRespuesta = false
          this.modalConfirmacionImagenRespuesta = false
          if (this.primer_guardado_nameFileImagenRespuesta === 0) {
            this.nombre_archivo_original_imagenRespuesta = this.imagenNuevaRespuesta.name
            this.primer_guardado_nameFileImagenRespuesta = 1
          }
        }
        //this.toast('b-toaster-bottom-center', true, message, color);

        //this.modalConfirmacion = false
      } else {
        //seleccionando el mismo archivo de audio
        //this.toast('b-toaster-bottom-center', true, '¡El archivo seleccionado es el mismo que el almacenado. Favor de verificar!', 'danger');
        this.showNotificacion('¡El archivo seleccionado es el mismo que el almacenado. Favor de verificar!', 'danger')
      }

    },
    async guardarPregunta() {
      if (this.FormPregunta.descripcion === '' || this.FormPregunta.descripcion === null || this.FormPregunta.descripcion.length === 0) {
        this.$refs.formPlateamientoReactivo.focus();
        this.showNotificacion('¡Debe capturar la pregunta!', 'danger');
        return false;
      }
      let formData = new FormData();
      formData.append("descripcion", this.FormPregunta.descripcion);
      formData.append("uuid_pregunta", this.FormPregunta.uuid_pregunta);
      formData.append("tiporeactivo_id",2);
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      this.disabledBtnGuardarPregunta = true
      const response = await PreguntaService.storeQuestion(formData);
      let {
        success,
        uuid_pregunta,
        message
        , color
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormPregunta.visibleBtnNuevaPregunta = true
        this.FormRespuestas.disabledTabRespuestas = false
        this.disabledBarraMultimedia = false
        this.modalConfirmacion = false
        this.FormPregunta.uuid_pregunta = uuid_pregunta
        this.disabledBtnGuardarPregunta = false
        this.textobtnGuardarPregunta = "Actualizar Pregunta"
        this.disabledRespuesta = false
      }
      this.showNotificacion(message, color)
    },
    async guardarRespuesta() {
      if (this.FormRespuestas.respuesta === '' || this.FormRespuestas.respuesta === null || this.FormRespuestas.respuesta.length === 0) {
        this.$refs.formRespuesta.focus();
        this.showNotificacion('¡Debe capturar la respuesta!', 'danger');
        return false;
      }
      this.disabledBtnGuardarRespuesta = true
      this.disabledRespuesta = true
      let formData = new FormData();
      formData.append("respuesta", this.FormRespuestas.respuesta);
      formData.append("uuid_pregunta", this.FormPregunta.uuid_pregunta);
      formData.append("uuid_respuesta", this.FormRespuestas.uuid_respuesta);
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      const response = await PreguntaService.storeRespuesta(formData);
      let {
        success,
        message
        , color
        , respuestas
        , cantidadRespuestas
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        console.log("respyestas")
        console.log(respuestas)
        this.FormRespuestas.visibleBtnNuevaRespuesta = true
        this.FormRespuestas.data = []
        this.FormRespuestas.data = respuestas
        this.FormRespuestas.totalRows = cantidadRespuestas
        this.FormRespuestas.visibleBtnNuevaRespuesta = true
        this.FormRespuestas.respuesta = null
        this.FormRespuestas.uuid_respuesta = 0
        //this.$refs.formRespuesta.focus()

        this.disabledBarraMultimediaRespuesta = false
        this.modalConfirmacion = false
        this.disabledBtnGuardarRespuesta = false
        this.disabledRespuesta = false
        this.disabledBtnNuevaRespuesta = false
      }
      this.showNotificacion(message, color)
    },
    showModalRespuestas(idPregunta) {
      this.idPregunta = idPregunta
      this.dataRespuesta.reactivo_id = idPregunta;
      this.getRespuestas(idPregunta);
      this.modalRespuestas = true;
      //reset vars dialog
      this.respuestas_de_la_unidad = []
      this.currentPage_respuestas = 1
      this.perPage_respuestas = 8
      this.filter_respuestas = null
      this.filterOn_respuestas = []
      this.totalRows_respuestas = 1
    },


    setRespuestaEdit(rowRespuesta) {
      this.FormRespuestas.respuesta = rowRespuesta.respuesta
      this.FormRespuestas.uuid_respuesta = rowRespuesta.uuid_respuesta
      this.disabledBtnGuardarRespuesta = false
      this.disabledBtnNuevaRespuesta = false


      this.url_imagenRespuesta = rowRespuesta.url_imagen
      this.nombre_archivo_original_imagenRespuesta = rowRespuesta.url_imagen
      if(this.nombre_archivo_original_imagenRespuesta!=null) {
        this.primer_guardado_nameFileImagenRespuesta = 1
        this.editarImagenRespuesta = true
      }
      else {
        this.primer_guardado_nameFileImagenRespuesta = 0
        this.editarImagenRespuesta= false
      }
      this.imagenNuevaRespuesta = null
    },

    showNotificacion(message, tipo) {
      this.$refs.notificacion.mostrar(message, tipo, 3000)
    },
    showNotificacionAudio(message, tipo) {
      this.$refs.notificacionAudio.mostrar(message, tipo, 3000)
    },
    showNotificacionRespuesta(message, tipo) {
      this.$refs.notificacionRespuesta.mostrar(message, tipo, 3000)
    },
    showNotificacionImagen(message, tipo) {
      this.$refs.notificacionImagen.mostrar(message, tipo, 3000)
    },
    showNotificacionImagenRespuesta(message, tipo) {
      this.$refs.notificacionImagenRespuesta.mostrar(message, tipo, 3000)
    },
    convertir_fecha(fechahora) {
      return moment(String(fechahora)).format('dddd DD/MM/YYYY hh:mm a')
    },

    subirAudio() {
      if (this.objAudio.volume < 1) {
        this.objAudio.volume += 0.1
      }
    },

    async getRespuestasByUuidPregunta($uuid_pregunta) {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacionImagen = true
      this.FormRespuestas.data = []
      const response = await PreguntaService.getRespuestasByUuidPregunta($uuid_pregunta);
      this.modalConfirmacion = false
      this.FormRespuestas.data = response.data.respuestas;
      this.FormRespuestas.totalRows = response.data.cantidadRespuestas;
      if(this.totalRows>=1) {
        this.FormRespuestas.visibleBtnNuevaRespuesta = true
        this.disabledBtnGuardarRespuesta = false
        this.disabledRespuesta = false

      }
      else {
        this.FormRespuestas.visibleBtnNuevaRespuesta = false
        this.disabledBtnGuardarRespuesta = false
        this.disabledRespuesta = false
      }
    },

  },
  mounted()
  {
    //se recibieron valores por transferencia
    if(this.$route.params.descripcion!=null){
      this.FormPregunta.visibleBtnNuevaPregunta = true
      this.disabledBtnGuardarPregunta = false
      this.FormRespuestas.disabledTabRespuestas = false

      this.FormPregunta.descripcion = this.$route.params.descripcion
      this.FormPregunta.uuid_pregunta = this.$route.params.uuid_pregunta
      this.textobtnGuardarPregunta = "Actualizar Pregunta"
      //cargando respuestas de la pregunta enviada
      this.getRespuestasByUuidPregunta(this.$route.params.uuid_pregunta)

      if(this.$route.params.url_imagen!=null){
        this.imagenNueva=true
        this.enabledToolbarImagen = true
        this.disabledBarraMultimedia = false
        this.editarImagen = true
        this.url_imagen = this.$route.params.url_imagen
      }
      if(this.$route.params.url_audio!=null){
        let vm = this;
        this.enabledToolbarAudio = true
        this.disabledBarraMultimedia = false
        this.url_audio = this.$route.params.url_audio
        this.objAudio.src = this.url_audio
        this.objAudio.ontimeupdate = function () {
          vm.generateTime();
        };
        this.objAudio.onloadedmetadata = function () {
          vm.generateTime();
        };
        this.audio_play = 1
      }
    }
    else {
      this.nuevaPregunta()
    }
  },

  computed: {}
}
</script>
<style lang="scss" scoped>
$studioAPPColor: #952f81;
.btn-studioAPP {
  color: $studioAPPColor !important;
  border: 1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active {
  background-color: #dadada !important;
  color: white !important;
}

.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color: #B2007B !important;
}

.alert-fixed {
  position: fixed;
  bottom: 2%;
  left: 30%;
  z-index: 9999;
  border-radius: 5px;
}


.player {
  background: #eef3f7;
  width: 310px;
  min-height: 30px;
  // box-shadow: 0px 55px 75px -10px rgba(76, 70, 124, 0.5);
  // box-shadow: 0px 55px 105px 10px rgba(76, 70, 124, 0.35);
  box-shadow: 0px 15px 35px -5px rgba(50, 88, 130, 0.32);
  border-radius: 15px;
  padding: 30px;
  @media screen and (max-width: 576px), (max-height: 500px) {
    width: 95%;
    padding: 10px;
    margin-top: 75px;
    min-height: initial;
    padding-bottom: 30px;
    max-width: 400px;
  }

  &__top {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 4;
    @media screen and (max-width: 576px), (max-height: 500px) {
      flex-wrap: wrap;
    }
  }

  &-cover {
    width: 300px;
    height: 300px;
    margin-left: -70px;
    flex-shrink: 0;
    position: relative;
    z-index: 2;
    border-radius: 15px;
    // transform: perspective(512px) translate3d(0, 0, 0);
    // transition: all .4s cubic-bezier(.125, .625, .125, .875);
    z-index: 1;

    @media screen and (max-width: 576px), (max-height: 500px) {
      margin-top: -70px;
      margin-bottom: 25px;
      width: 290px;
      height: 230px;
      margin-left: auto;
      margin-right: auto;
    }

    &__item {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      position: absolute;
      left: 0;
      top: 0;

      &:before {
        content: "";
        background: inherit;
        width: 100%;
        height: 100%;
        box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
        display: block;
        z-index: 1;
        position: absolute;
        top: 30px;
        transform: scale(0.9);
        filter: blur(10px);
        opacity: 0.9;
        border-radius: 15px;
      }

      &:after {
        content: "";
        background: inherit;
        width: 100%;
        height: 100%;
        box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
        display: block;
        z-index: 2;
        position: absolute;
        border-radius: 15px;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
      box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
      user-select: none;
      pointer-events: none;
    }
  }

  &-controls {
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 576px), (max-height: 500px) {
      flex-direction: row;
      padding-left: 0;
      width: 100%;
      flex: unset;
    }

    &__item {
      display: inline-flex;
      font-size: 30px;
      padding: 5px;
      margin-bottom: 10px;
      color: #acb8cc;
      cursor: pointer;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: all 0.3s ease-in-out;

      @media screen and (max-width: 576px), (max-height: 500px) {
        font-size: 26px;
        padding: 5px;
        margin-right: 10px;
        color: #acb8cc;
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #fff;
        transform: scale(0.5);
        opacity: 0;
        box-shadow: 0px 5px 10px 0px rgba(76, 70, 124, 0.2);
        transition: all 0.3s ease-in-out;
        transition: all 0.4s cubic-bezier(0.35, 0.57, 0.13, 0.88);
      }

      @media screen and (min-width: 500px) {
        &:hover {
          color: #532ab9;

          &::before {
            opacity: 1;
            transform: scale(1.3);
          }
        }
      }

      @media screen and (max-width: 576px), (max-height: 500px) {
        &:active {
          color: #532ab9;

          &::before {
            opacity: 1;
            transform: scale(1.3);
          }
        }
      }

      .icon {
        position: relative;
        z-index: 2;
      }

      &.-xl {
        margin-bottom: 0;
        font-size: 95px;
        // filter: drop-shadow(0 2px 8px rgba(172, 184, 204, 0.3));
        // filter: drop-shadow(0 9px 6px rgba(172, 184, 204, 0.35));
        filter: drop-shadow(0 11px 6px rgba(172, 184, 204, 0.45));
        color: #fff;
        width: auto;
        height: auto;
        display: inline-flex;
        @media screen and (max-width: 576px), (max-height: 500px) {
          margin-left: auto;
          font-size: 75px;
          margin-right: 0;
        }

        &:before {
          display: none;
        }
      }

      &.-favorite {
        &.active {
          color: red;
        }
      }
    }
  }
}

[v-cloak] {
  display: none;
}

[v-cloak] > * {
  display: none;
}

.progress {
  width: 100%;
  margin-top: -15px;
  user-select: none;

  &__top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__duration {
    margin-bottom: 6px;
    color: #71829e;
    font-weight: 700;
    font-size: 14px;
    opacity: 0.5;
  }

  &__time {
    margin-top: 6px;
    color: #71829e;
    font-weight: 700;
    font-size: 14px;
    opacity: 0.7;
  }
}

.progress__bar {
  height: 6px;
  width: 100%;
  cursor: pointer;
  background-color: #d0d8e6;
  display: inline-block;
  border-radius: 10px;
}

.progress__current {
  height: inherit;
  width: 0%;
  background-color: #a3b3ce;
  border-radius: 10px;
}

.album-info {
  color: #71829e;
  flex: 1;
  padding-right: 60px;
  user-select: none;

  @media screen and (max-width: 576px), (max-height: 500px) {
    padding-right: 30px;
  }

  &__name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
    line-height: 1.3em;
    @media screen and (max-width: 576px), (max-height: 500px) {
      font-size: 18px;
      margin-bottom: 9px;
    }
  }

  &__track {
    font-weight: 400;
    font-size: 20px;
    opacity: 0.7;
    line-height: 1.3em;
    min-height: 52px;
    @media screen and (max-width: 576px), (max-height: 500px) {
      font-size: 18px;
      min-height: 50px;
    }
  }
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  transition: all .3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }
}

//scale out

.scale-out-enter-active {
  transition: all .35s ease-in-out;
}

.scale-out-leave-active {
  transition: all .35s ease-in-out;
}

.scale-out-enter {
  transform: scale(.55);
  pointer-events: none;
  opacity: 0;
}

.scale-out-leave-to {
  transform: scale(1.2);
  pointer-events: none;
  opacity: 0;
}


//scale in

.scale-in-enter-active {
  transition: all .35s ease-in-out;
}

.scale-in-leave-active {
  transition: all .35s ease-in-out;
}

.scale-in-enter {
  transform: scale(1.2);
  pointer-events: none;
  opacity: 0;
}

.scale-in-leave-to {
  transform: scale(.55);
  pointer-events: none;
  opacity: 0;
}

.btn-primary.active, .btn-primary:active {
  background-color: #FFA500; /* Color de fondo cuando es pulsado */
  border-color: #FFA500; /* Color de borde cuando es pulsado */
}

.vue-editor__wrapper {
  height: 100px;
}

#editor1,
#editor2 {
  height: 550px;
}

</style>