<template>

  <b-container fluid>
    <b-modal no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalEliminarPregunta"
             title="Confirmar finalizar examen"
             v-model="modalEliminarPregunta"
    >
      <b-alert v-model="modalConfirmacionEliminarPregunta" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <!--      <Notificacion ref="notificacionEliminarPregunta"/>-->
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea dar por finalizado su examen?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="finalizarExamenByUser()"
                  title="Finalizar examen"
                  :disabled="disabledBtnsEliminarPregunta"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalEliminarPregunta=false"
                  :disabled="disabledBtnsEliminarPregunta"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>


    <b-alert v-model="modalConfirmacionImagen" variant="light" show>
      <b-button variant="primary" disabled>
        <b-spinner small type="grow"></b-spinner>
        {{ mensajeConfirmacion }}
      </b-button>
    </b-alert>
    <Notificacion ref="notificacion"/>
    <!--    <div id="examenFinalizaTiempo" v-if="this.$store.getters.usuario.uuid_examen_permitido!=uuid_examen" style="margin-top: 50px;">
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <b-card>
                  <h5>¡No tienes permisos para este examen.!
                  </h5>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </div>-->
    <b-row v-if="mostrarRecomendacion===true  && this.$store.getters.usuario.uuid_examen_permitido===uuid_examen"
           style="font-family: 'Roboto', sans-serif; font-size: 16px; line-height: 1.5;margin-top: 20px">
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <b-card>
              <div id="indicaciones"
                   style="font-family: 'Roboto', sans-serif; font-size: 16px; line-height: 1.5;margin-top: 20px">
                <p style="text-align: justify;">Antes de pulsar el botón de inicio considera lo siguiente:</p>
                <ul style="text-align: justify;">
                  <li>
                    <b>Cuida tus tiempos.</b>
                    Administrar el tiempo durante el examen marca la diferencia entre obtener el resultado positivo y
                    uno negativo. El examen está diseñado para completarse en un máximo de 3 horas (180 minutos).
                  </li>
                  <li>
                    <b>Evita dejar respuestas en blanco.</b>
                    Puedes saltar las preguntas que no puedas contestar de forma inmediata y regresar a ellas al final,
                    pero asegúrate de no dejarlas en blanco antes de enviar tu evaluación, pues serán consideradas como
                    incorrectas.
                  </li>
                  <li>
                    <b>Controla los nervios.</b>
                    Para ello busca tomar el simulacro en un día que tengas el tiempo suficiente, estés descansado y te
                    ubiques en un espacio cómodo y libre de distracciones.
                  </li>
                  <li>
                    <b>Leer con atención el enunciado es importante.</b>
                    Es importante que leas con calma y de forma analítica cada escenario o pregunta para que selecciones
                    la respuesta correcta.
                  </li>
                  <li>
                    <b>Revisa el examen antes de entregarlo.</b>
                    Este detalle puede marcar la diferencia entre obtener una buena calificación de una excelente o un
                    resultado a mejorar.
                  </li>
                </ul>
              </div>
              <b-row v-if="mostrarRecomendacion===true" class="text-center">
                <b-col cols="12" class="text-center">
                  <b-button
                      variant="success"
                      size="sm"
                      class="float-right mx-2 my-1"
                      @click="iniciarExamen()"
                      title="Iniciar examen"
                  >
                    Iniciar examen
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div id="examenTerminadoUser" v-if="examenFinalizadoUser===true || estatusExamen===3" style="margin-top: 50px;">
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <b-card>
              <h5>¡Gracias!, usted ha finalizado su examen, pronto recibirá sus resultados.
              </h5>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </div>


    <div id="examenFinalizaTiempo"
         v-if="mensajeFinalizadoPorTiempo===true && this.$store.getters.usuario.uuid_examen_permitido===uuid_examen"
         style="margin-top: 50px;">
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <b-card>
              <h5>Su examen ha concluido, por haber alcanzo el límite de tiempo.
              </h5>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </div>


    <div id="appAvanza" v-if="examenActivo===true && this.$store.getters.usuario.uuid_examen_permitido===uuid_examen"
         style="margin-top: 50px;">
      <h5>Lee con atención y contesta correctamente, recuerda que el examen tiene una duración de 3 horas.
      </h5>
      <br>
      <div id="examen" v-for="(question, index) in preguntas" :key="index" class="mb-4">
        <b-card v-if="question.tipo_pregunta===1">
          <div v-html="question.pregunta"
               style="font-family: 'Roboto', sans-serif;font-size: 16px;line-height: 1.5;"></div>
          <b-form-radio-group
              :options="question.respuestas"
              html-field="respuesta"
              value-field="uuid_respuesta"
              v-model="respuestaTipo1[`${question.uuid_pregunta}`]"
              @change="respuestaSeleccionada"
              stacked
          >
          </b-form-radio-group>
        </b-card>
        <b-card v-if="question.tipo_pregunta===2">
          <div v-html="question.pregunta" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
          <b-form-checkbox-group
              :options="question.respuestas"
              html-field="respuesta"
              value-field="uuid_respuesta"
              v-model="respuestaTipo2[`${question.uuid_pregunta}`]"
              @change="respuestaTipo2Seleccionada"
              class="mb-2"
              stacked
          ></b-form-checkbox-group>
        </b-card>
        <b-card v-if="question.tipo_pregunta===3">
          <div v-html="question.pregunta" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
          <b-table class="table data-table"
                   :items="question.columnaA"
                   :fields="reactivos_fields"
                   :current-page="currentPage_reactivos"
                   :per-page="perPage_reactivos"
                   :filter="filter_reactivos"
                   :filter-included-fields="filterOn_reactivos"
                   :striped="true"
                   hover
                   show-empty
                   :small="true"
          >
            <template #cell(descripcion)="row">
              <div v-html="row.value"></div>
            </template>
            <template #cell(concepto)="row">
              <b-form-select
                  v-model="respuestaTipo3[`${row.item.uuid_columnaA}`]"
                  :options="question.columnaB"
                  text-field="descripcion"
                  value-field="uuid_columnaB"
                  class="mb-3"
                  size="sm"
                  @change="respuestaTipo3Seleccionada(row)"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>-- Elige una opción --</b-form-select-option>
                </template>
              </b-form-select>

              <!--              <b-form-select-->
              <!--                  size="sm"-->
              <!--                  :options="question.columnaB"-->
              <!--                  class="mb-3"-->
              <!--                  value-field="uuid_columnaB"-->
              <!--                  text-field="descripcion"-->
              <!--                  @change="saveRowSelected($event,row.item.uuid_columnaA,row.item.uuid_reactivo_fk,uuid_examen)"-->
              <!--                  label="Elegir calificación"-->
              <!--              >-->
              <!--                <template #first>-->
              <!--                  <b-form-select-option disabled value="0"> Elegir opción</b-form-select-option>-->
              <!--                </template>-->
              <!--              </b-form-select>-->
            </template>
          </b-table>
        </b-card>
      </div>
      <button @click="submitExam" class="btn btn-primary" v-if="examenActivo==true">Finalizar Examen</button>
    </div>
    <span class="float" v-if="examenActivo===true">
       <b-spinner small type="grow"></b-spinner><b>Minutos restantes:</b> <p style="font-weight: bold;">{{
        counter
      }}</p>
    </span>
  </b-container>
</template>

<script>
import ExamenService from '@/services/ExamenService';
import Notificacion from '@/components/Notificacion.vue'


export default {
  components: {
    Notificacion
  },
  data() {
    return {
      examenFinalizadoUser: false,
      modalEliminarPregunta: false,
      modalConfirmacionEliminarPregunta: false,
      disabledBtnsEliminarPregunta: false,
      respuestaTipo1: [],
      respuestaTipo2: [],
      respuestaTipo3: [],
      mensajeFinalizadoPorTiempo: false,
      selected: null,
      reactivos_fields: [
        {label: 'Descripcion', key: 'descripcion', class: 'text-left'},
        {label: 'Concepto', key: 'concepto', class: 'text-left'},
      ],
      currentPage_reactivos: 1,
      perPage_reactivos: 20,
      filter_reactivos: null,
      filterOn_reactivos: [],
      modalConfirmacionImagen: false,
      mensajeConfirmacion: null,
      examenActivo: false,
      uuid_examen: (this.$route.params.uuid_examen) ? (this.$route.params.uuid_examen) : 0,
      preguntas: [],
      respuestas: [],
      selectedOptions: [] // Respuestas seleccionadas por el usuario
      , checked: [] // Respuestas seleccionadas por el usuario
      , btnFinalizarexamen: false,
      totalTime: 10800, // Tiempo total en segundos
      timeRemaining: 10800, // Tiempo restante en segundos
      intervalId: null, // ID del intervalo para controlar el contador
      isRunning: false, // Estado de ejecución del contador

      //variables del examen
      examen_finalizado: 0,
      mostrar_resultados: 0,

      estatusExamen: null,
      mostrarRecomendacion: true,
      counter: 0,
      interval: null,
      tiempoExamen: null,
    }
  },
  mounted() {

  },

  computed: {
    formatTime() {
      const minutes = Math.floor(this.timeRemaining / 60);
      const seconds = this.timeRemaining % 60;
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
  },
  methods:
      {
        async finalizarExamenByUser() {
          let formData = new FormData();
          //formData.append("tiempo_actual", this.counter);
          this.disabledBtnsEliminarPregunta = true
          formData.append("uuid_examen_fk", this.uuid_examen);
          const response = await ExamenService.finalizarExamenByUser(formData);
          let {
            success,
            estatusExamen

          } = response.data;
          if (success) {
            //this.showNotificacion("¡Respuesta guardada!", "success")
            this.modalEliminarPregunta = false
            if (estatusExamen == 3) {
              this.mostrarRecomendacion = false
              this.mensajeFinalizadoPorTiempo = false
              this.examenActivo = false
              this.examenFinalizadoUser = true
              //desplegar mensaje lo sentimos
            }
          }

        },
        startCountdown() {
          this.interval = setInterval(() => {
            this.counter--;
            if (this.counter <= 0) {
              clearInterval(this.interval);
              this.mostrarRecomendacion = false
              this.examenActivo = false
              this.mensajeFinalizadoPorTiempo = true
              this.actualizarContador()
            }
          }, 60000);
        },
        async verificarTiempoExamen() {
          const response = await ExamenService.getverificarTiempoExamen(this.uuid_examen);
          this.counter = (response.contador) - 1;
          this.estatusExamen = response.estatusExamen;
        },


        iniciarExamen: async function () {
          this.mensajeConfirmacion = "Espera un momento, estamos preparando tu examen..."
          this.modalConfirmacionImagen = true

          this.mostrarRecomendacion = false
          this.preguntas = []
          const response = await ExamenService.getAllPreguntasRespuestas(this.uuid_examen);
          this.preguntas = response.data;
//console.log(this.preguntas)

          this.preguntas.forEach(pregunta => {
            if (pregunta.tipo_pregunta === 1 || pregunta.tipo_pregunta === 2) {
             // console.log(pregunta.tipo_pregunta)
             // console.log(pregunta.respuestas.isArray)
              pregunta.respuestas.forEach((respuesta) => {
                if (pregunta.tipo_pregunta === 1 && respuesta.checked) {
                  const respuestaNueva = {
                    ...this.respuestaTipo1,
                    [pregunta.uuid_pregunta]: respuesta.uuid_respuesta
                  };
                  this.respuestaTipo1 = respuestaNueva;
                }

                if (pregunta.tipo_pregunta === 2 && respuesta.checked) {
                  let respuestaValores = Array.from(this.respuestaTipo2[pregunta.uuid_pregunta] ?? []);
                  respuestaValores.push(respuesta.uuid_respuesta);
                  const respuestaNueva = {
                    ...this.respuestaTipo2,
                    [pregunta.uuid_pregunta]: respuestaValores
                  };
                  this.respuestaTipo2 = respuestaNueva;
                }


              });
            }
            if (pregunta.tipo_pregunta === 3) {
              pregunta.columnaA.forEach(columnaA => {
                //console.log(columnaA);
                const respuestaNueva = {
                  ...this.respuestaTipo3,
                  [columnaA.uuid_columnaA]: columnaA.respuestaSelected
                };
                this.respuestaTipo3 = respuestaNueva;
              });
              //console.table(this.respuestaTipo3);
            }
          });

          // this.preguntas.forEach(pregunta => {
          //   pregunta.respuestas.forEach(respuesta => {
          //     this.respuestas.push({
          //       uuid_respuesta: respuesta.uuid_respuesta,
          //       isChecked: respuesta.isChecked
          //     });
          //   });
          // });
          //console.table(this.preguntas);
          this.mensajeConfirmacion = "Gracias por la espera, ahora puedes iniciar..."
          this.modalConfirmacionImagen = false

//  todo
          let formData = new FormData();
          formData.append("uuid_examen", this.uuid_examen);
          const responseTiempo = await ExamenService.setIniciarExamenAlumno(formData);
          this.counter = (responseTiempo.data.minutos_restantes) - 1;
          this.estatusExamen = responseTiempo.data.estatusExamen;
          //console.log(this.estatusExamen)
          if (this.estatusExamen === 4) { //finalizado por tiempo
            this.examenActivo = false
            this.mensajeFinalizadoPorTiempo = true
          } else if (this.estatusExamen === 3) {
            this.examenActivo = false
            this.examenFinalizadoUser = true;
          } else if (this.estatusExamen === 1 || this.estatusExamen === 2) {
            this.examenActivo = true
            this.startCountdown();
          }
        },


        async actualizarContador() {
          let formData = new FormData();
          //formData.append("tiempo_actual", this.counter);
          formData.append("uuid_examen_fk", this.uuid_examen);
          const response = await ExamenService.storeTiempoActualizado(formData);
          let {
            success,
            estatusExamen

          } = response.data;
          if (success) {
            //this.showNotificacion("¡Respuesta guardada!", "success")
            if (estatusExamen == 4) {
              //desplegar mensaje lo sentimos
            }
          }

        },
        mostrar() {
          alert(this.counter)
        },
        async saveRowSelected($uuid_columnaB, $uuid_columnaA, $uuid_reactivo_fk, $uuid_examen) {
          let formData = new FormData();
          formData.append("tiempo_actual", this.counter);
          formData.append("uuid_columnaB", $uuid_columnaB);
          formData.append("uuid_columnaA", $uuid_columnaA);
          formData.append("uuid_pregunta", $uuid_reactivo_fk);
          formData.append("uuid_examen", $uuid_examen);
          const response = await ExamenService.storeRespuestaColumna(formData);
          let {
            success
          } = response.data;
          if (success) {
            this.showNotificacion("¡Respuesta guardada!", "success")
          } else
            this.showNotificacion("¡Respuesta no guardada. Favor de revisar tu conexión a internet!", "danger")
        },

        async saveRespuestasRadioSelected(val_uuid_pregunta, uuid_respuesta, tipo_reactivo) {
          let formData = new FormData();
          formData.append("tiempo_actual", this.counter);
          formData.append("uuid_pregunta", val_uuid_pregunta);
          formData.append("uuid_respuesta", uuid_respuesta);
          formData.append("tipo_reactivo", tipo_reactivo);
          formData.append("uuid_examen", this.uuid_examen);
          const response = await ExamenService.storeRespuestaRadio(formData);
          let {
            success
          } = response.data;
          if (success) {
            this.showNotificacion("¡Respuesta guardada!", "success")
          } else
            this.showNotificacion("¡Respuesta no guardada. Favor de revisar tu conexión a internet!", "danger")
        },
        async saveRespuestasCheckSelected(uuid_pregunta, uuid_respuesta, tipo_reactivo, option) {
          let checked = null
          const indiceRespuesta = this.respuestas.findIndex(respuesta => respuesta.uuid_respuesta === uuid_respuesta);
          // Si no existe, agregar el nuevo elemento
          if (indiceRespuesta === -1) {
            this.respuestas.push({uuid_respuesta: option.uuid_respuesta, isChecked: !option.isChecked});
            checked = !option.isChecked
          } else {
            this.respuestas[indiceRespuesta].isChecked = !this.respuestas[indiceRespuesta].isChecked;
            checked = this.respuestas[indiceRespuesta].isChecked
          }
          let formData = new FormData();
          formData.append("uuid_pregunta", uuid_pregunta);
          //formData.append("tiempo_actual", this.counter);
          formData.append("uuid_respuesta", uuid_respuesta);
          formData.append("tipo_reactivo", tipo_reactivo);
          formData.append("uuid_examen", this.uuid_examen);
          formData.append("checked", checked);
/*          console.log("uuid_respuesta")
          console.log(uuid_respuesta)
          console.log("uuid_pregunta")
          console.log(uuid_pregunta)
          console.log("tipo_reactivo")
          console.log(tipo_reactivo)
          console.log("valor del check")
          console.log(checked)*/

          const response = await ExamenService.storeRespuestaCheckbox(formData);
          let {
            success
          } = response.data;
          if (success) {
            this.showNotificacion("¡Respuesta guardada!", "success")
          } else
            this.showNotificacion("¡Respuesta no guardada. Favor de revisar tu conexión a internet!", "danger")

        },
        async getPreguntas(uuid_examen) {
          //this.mensajeConfirmacion = "Favor de esperar un momento, estamos preparando tu examen..."
          //this.modalConfirmacionImagen = true
          this.preguntas = []
          const response = await ExamenService.getAllPreguntasRespuestas(uuid_examen);
          this.preguntas = response.data;

          this.preguntas.forEach(pregunta => {
            pregunta.respuestas.forEach(respuesta => {
              this.respuestas.push({
                uuid_respuesta: respuesta.uuid_respuesta,
                isChecked: respuesta.isChecked
              });
            });
          });
/*          console.log("preguntas")
          console.log(this.preguntas)*/
          this.mensajeConfirmacion = "Gracias por la espera, ahora puedes iniciar..."
          this.modalConfirmacionImagen = false
          this.examenActivo = true
        },
        submitExam() {

          // Procesar las respuestas seleccionadas por el usuario
          /*          this.mensajeConfirmacion = "Gracias tu examen ha sido enviado, pronto recibirás noticias de tu evaluación..."
                    this.modalConfirmacionImagen = true*/
          this.modalEliminarPregunta = true
          // Puedes realizar más acciones aquí, como calcular la puntuación, mostrar resultados, etc.
        },
        respuestaSeleccionada(respuestaUUID) {
          let preguntaUUID = Object.keys(this.respuestaTipo1).find(key => this.respuestaTipo1[key] === respuestaUUID);

          //console.log(respuestaUUID, preguntaUUID);
          this.saveRespuestasRadioSelected(preguntaUUID, respuestaUUID, 1)
        },
        async respuestaTipo2Seleccionada(respuestasUUID) {
/*          let contieneComa = 0
          let esArreglo = 0*/
         // let arregloRespuestas = null
          //let longitudCadena=0
          let preguntaUUID = Object.keys(this.respuestaTipo2).find(key => JSON.stringify(this.respuestaTipo2[key]) === JSON.stringify(respuestasUUID));

          //console.log(preguntaUUID, respuestasUUID);

          let formData = new FormData();
          formData.append("uuid_pregunta", preguntaUUID);
          formData.append("arrayUuid_respuesas", respuestasUUID);

          formData.append("tipo_reactivo", 2);
          formData.append("uuid_examen", this.uuid_examen);
          const response = await ExamenService.storeRespuestaCheckbox(formData);
          let {
            success
          } = response.data;
          if (success) {
            this.showNotificacion("¡Respuesta guardada!", "success")
          } else
            this.showNotificacion("¡Respuesta no guardada. Favor de revisar tu conexión a internet!", "danger")


        },
        async respuestaTipo3Seleccionada(rowSeleccionado) {
          let uuidColumnaBSeleccionada = this.respuestaTipo3[rowSeleccionado.item.uuid_columnaA];
/*
          console.log({
            'uuid_reactivo': rowSeleccionado.item.uuid_reactivo_fk,
            'uuid_columnaA': rowSeleccionado.item.uuid_columnaA,
            'uuid_columnaB': uuidColumnaBSeleccionada
          });*/
          //todo mandar a guardar
          let formData = new FormData();
          formData.append("uuid_columnaB", uuidColumnaBSeleccionada);
          formData.append("uuid_columnaA", rowSeleccionado.item.uuid_columnaA);
          formData.append("uuid_pregunta", rowSeleccionado.item.uuid_reactivo_fk);
          formData.append("uuid_examen", this.uuid_examen);
          const response = await ExamenService.storeRespuestaColumna(formData);
          let {
            success
          } = response.data;
          if (success) {
            this.showNotificacion("¡Respuesta guardada!", "success")
          } else
            this.showNotificacion("¡Respuesta no guardada. Favor de revisar tu conexión a internet!", "danger")
        },
        showNotificacion(message, tipo) {
          this.$refs.notificacion.mostrar(message, tipo, 2000)
        },
        startTimer() {
          this.isRunning = true;
          this.intervalId = setInterval(() => {
            if (this.timeRemaining > 0) {
              this.timeRemaining--;
            } else {
              this.stopTimer();
              this.performAction();
            }
          }, 1000);
        },
        pauseTimer() {
          clearInterval(this.intervalId);
          this.isRunning = false;
        },
        continueTimer() {
          this.isRunning = true;
          this.intervalId = setInterval(() => {
            if (this.timeRemaining > 0) {
              this.timeRemaining--;
            } else {
              this.stopTimer();
              this.performAction();
            }
          }, 1000);
        },
        resetTimer() {
          clearInterval(this.intervalId);
          this.timeRemaining = this.totalTime;
          this.isRunning = false;
        },
        stopTimer() {
          clearInterval(this.intervalId);
          this.isRunning = false;
        },
        performAction() {
          // Realiza la acción deseada cuando se alcanza cierto tiempo
          console.log('¡Tiempo alcanzado! Realizar acción...');
        }
      },
  /*  beforeDestroy() {
      // Clear the interval and remove the counter value from localStorage
      clearInterval(this.interval);
      localStorage.removeItem('counter');
    },*/
}


</script>
<style>
/*@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,500;1,900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,400;0,500;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,400;0,500;1,900&family=Sanchez&display=swap');

.b-form-radio + .b-form-radio {
  margin-top: 1rem;
}

.espacio-contador {
  display: block;
}

.container-contador-home {
  position: relative;
}

.container-contador {
  position: fixed;
  z-index: 9999;
  padding: 10px;
  top: 6.9vh;
  right: 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  padding: 100px;
}

.fixed-div {
  position: fixed;
  top: 75px;
  left: 250px;
  background-color: red;
  padding: 10px;

}

.fixedEdit-div {
  position: fixed;
  top: 75px;
  left: 250px;

  padding: 10px;

}

.float {
  position: fixed;
  width: 160px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  /*background-color: #952f81;*/
  background-color: #952f81;
  color: #FFF;
  border-radius: 5px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}
</style>