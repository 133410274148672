<template>
  <b-container fluid>
    <b-modal id="modalEliminarOpcion" no-close-on-backdrop no-close-on-esc centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title="Eliminar opción"
             v-model="FormModalEliminarOpcion.visibleModalEliminarOpcion"
    >
      <b-alert v-model="FormModalEliminarOpcion.visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ FormModalEliminarOpcion.mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <NotificacionModal ref="notificacionModalInterno"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row class="my-1">
            <b-col cols="12">
              <label for="label-concepto-ordenamiento">¿Desea eliminar la opción: {{FormModalEliminarOpcion.opcion}}?</label>
            </b-col>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarOpcion()"
                  title="Eliminar opción"
                  :disabled="FormModalEliminarOpcion.disabledBtns"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="FormModalEliminarOpcion.visibleModalEliminarOpcion=false"
                  :disabled="FormModalEliminarOpcion.disabledBtns"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalEliminarCategoria" no-close-on-backdrop no-close-on-esc centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title="Eliminar categoría"
             v-model="FormModalEliminarCategoria.visibleModalCrearCategoria"
    >
      <b-alert v-model="FormModalEliminarCategoria.visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ FormModalEliminarCategoria.mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <NotificacionModal ref="notificacionModal"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row class="my-1">
            <b-col cols="12">
              <label for="label-concepto-ordenamiento">¿Desea eliminar la categoría: {{FormModalEliminarCategoria.categoria}}?</label>
            </b-col>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarCategoria()"
                  title="Eliminar categoría"
                  :disabled="FormModalEliminarCategoria.disabledBtns"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="FormModalEliminarCategoria.visibleModalCrearCategoria=false"
                  :disabled="FormModalEliminarCategoria.disabledBtns"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalEditarCategoria" no-close-on-backdrop no-close-on-esc centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title="Actualizar categoría"
             v-model="FormModalEditCategoria.visibleModalCrearCategoria"
    >
      <b-alert v-model="FormModalEditCategoria.visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ FormModalEditCategoria.mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <NotificacionModal ref="notificacionModal"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="label-categoria">Categoría:</label>
            </b-col>
            <b-col cols="8">
              <b-form-input
                  v-model="FormModalEditCategoria.categoria" id="categoria"
                  placeholder="Captura la categoría"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="actualizarCategoria()"
                  title="Actualizar categoría"
                  :disabled="FormModalEditCategoria.disabledBtns"
              >
                Guardar
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="FormModalEditCategoria.visibleModalCrearCategoria=false"
                  :disabled="FormModalEditCategoria.disabledBtns"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>
    <b-modal id="modalCrearCategoria" no-close-on-backdrop no-close-on-esc centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title="Crear categoría"
             v-model="FormModalCrearCategoria.visibleModalCrearCategoria"
    >
      <b-alert v-model="FormModalCrearCategoria.visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ FormModalCrearCategoria.mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <NotificacionModal ref="notificacionModal"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="label-categoria">Categoría:</label>
            </b-col>
            <b-col cols="8">
              <b-form-input
                  v-model="FormModalCrearCategoria.categoria" id="categoria"
                  placeholder="Captura la categoría"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarCategoria()"
                  title="Guardar categoría"
                  :disabled="FormModalCrearCategoria.disabledBtns"
              >
                Guardar
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="FormModalCrearCategoria.visibleModalCrearCategoria=false"
                  :disabled="FormModalCrearCategoria.disabledBtns"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>


        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalCrearOpcion" no-close-on-backdrop no-close-on-esc centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title="Crear opción"
             v-model="FormModalCrearOpcion.visibleModalCrearOpcion"
    >
      <b-alert v-model="FormModalCrearOpcion.visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ FormModalCrearOpcion.mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <NotificacionModal ref="notificacionOpcion"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="label-opcion">Opción:</label>
            </b-col>
            <b-col cols="8">
              <b-form-input
                  v-model="FormModalCrearOpcion.opcion" id="opcion"
                  placeholder="Captura la opción"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarOpcion()"
                  title="Guardar opción"
                  :disabled="FormModalCrearOpcion.disabledBtns"
              >
                Guardar
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="FormModalCrearOpcion.visibleModalCrearOpcion=false"
                  :disabled="FormModalCrearOpcion.disabledBtns"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>

          <b-row class="d-flex align-items-end justify-content-end my-1">
            <div class="col-sm-12 my-2">
              <b-form-input
                  id="filter-input-dashboard"
                  v-model="FormModalCrearCategoria.filter"
                  type="search"
                  placeholder="Buscar"
              >
              </b-form-input>
            </div>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-table class="table data-table"
                       :items="FormModalCrearOpcion.data"
                       :fields="FormModalCrearOpcion.fields"
                       :current-page="FormModalCrearOpcion.currentPage"
                       :per-page="FormModalCrearOpcion.perPage"
                       :filter="FormModalCrearOpcion.filter"
                       :filter-included-fields="FormModalCrearOpcion.filterOn"
                       :striped="true"
                       hover
                       show-empty
                       :small="true"
              >
                <template #empty="scope">
                  <h5 class="text-center">
                    {{ scope.emptyText = 'Por el momento no existen opciones de categorías que mostrar.' }}
                  </h5>
                </template>
                <template #emptyfiltered="scope">
                  <h5 class="text-center">
                    {{
                      scope.emptyFilteredText = 'No existen opciones de categorías que coincidan con los parámetros de búsqueda.'
                    }}
                  </h5>
                </template>
                <template #cell(opcion)="row">
                  <div v-html="row.value"></div>
                </template>
                <template #cell(actions)="row">
                  <div class="d-flex align-content-end">
                    <b-button
                        variant="success"
                        v-b-modal.modal-prevent-closing
                        size="sm"
                        @click="showModalEditarOpcion(row.item)"
                        class="mr-1"
                        title="Editar opción">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-trash2-fill" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                      </svg>
                    </b-button>

                    <b-button
                        variant="danger"
                        v-b-modal.modal-prevent-closing
                        size="sm"
                        @click="showModalEliminarOpcion(row.item)"
                        class="mr-1"
                        title="Eliminar opción">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-trash2-fill" viewBox="0 0 16 16">
                          <path
                              d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                        </svg>
                      </svg>
                    </b-button>
                  </div>
                </template>
              </b-table>
              <div class="d-flex justify-content-between row p-2">
                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                  <div>
                    <b-pagination
                        v-model="FormModalCrearOpcion.currentPage"
                        :total-rows="FormModalCrearOpcion.totalRows"
                        :per-page="FormModalCrearOpcion.perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                    >
                    </b-pagination>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>


    <b-row id="dashboardPrincipal">
      <b-col cols="12">
        <div class="card card-block">
          <div id="AlertGeneral" class="card-body">
            <b-alert
                show
                variant="primary"
                >
              Agregar Pregunta de Categorización
            </b-alert>
            <!--ventana confirmacion general-->
            <b-alert v-model="modalConfirmacion" variant="light" show>
              <b-button variant="primary" disabled>
                <b-spinner small type="grow"></b-spinner>
                {{ mensajeConfirmacion }}
              </b-button>
            </b-alert>
            <Notificacion ref="notificacion"/>

            <!--cerrar ventana confirmacion general-->
          </div>
          <div id="tabGeneral " class="col-sm-12 my-2">
            <div>
              <b-card no-body>
                <b-tabs card>
                  <!--INICIO TAB1-->
                  <b-tab id="tabPlanteamiento" title="Planteamiento" active>
                    <b-card-text>
                      <b-form>

                        <b-row>
                          <b-col cols="12">
                            <div id="editor1ED">
<!--                              <vue-editor v-model="Form.descripcion"
                                          id="editor1"
                                          :editorToolbar="customToolbar"
                                          placeholder="Capturar el planteamiento del reactivo"
                                          :style="{padding:'10px'}"
                              >
                              </vue-editor>-->
                              <b-form-textarea
                                  id="formDescripcion"
                                  ref="formDescripcion"
                                  v-model="Form.descripcion"
                                  placeholder="Capturar el planteamiento"
                                  rows="4"
                                  max-rows="6"
                              ></b-form-textarea>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row id="btnsFormPlanteamiento" class="justify-content-end">
                          <div class="d-flex align-items-right">
                            <b-button
                                variant="success"
                                size="sm"
                                class="float-right mx-2 my-1"
                                @click="guardarPlanteamiento()"
                                title="Agregar correos de importación"
                                :disabled="Form.disabledBtns"
                            >
                              Guardar
                            </b-button>
                            <b-button
                                variant="primary"
                                size="sm"
                                class="float-right mx-2 my-1"
                                title="Nueva Pregunta"
                                @click="resetFormReactivoOrdenamiento"
                                :disabled="Form.disabledBtns"
                                v-if="(this.Form.visibleNuevaPregunta === true)"
                            >
                              Nueva Pregunta
                            </b-button>
                          </div>
                        </b-row>
                      </b-form>
                    </b-card-text>
                  </b-tab>

                  <b-tab id="tabCategorias" title="Categorías" :disabled="FormModalCrearCategoria.disabledTabCategoria">
                    <b-card-text>
                      <b-form>
                        <b-alert
                            show
                            variant="success"
                            dismissible
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16"

                          >
                            <path
                                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                          </svg>
                          Agregar primero las categorías, posteriormente por cada categoría agregar las opciones que le correspondan.
                        </b-alert>
                        <b-row class="justify-content-end">
                          <b-col sm="12">
                            <div class="col-sm-12">
                              <b-button
                                  variant="success"
                                  size="sm"
                                  @click="showModalCrearCategoria()"
                                  title="Crear categoría"
                              >
<!--                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                     class="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                                </svg>-->
                                Agregar categoría
                              </b-button>
                            </div>
                          </b-col>
                        </b-row>

<!--                        <b-row class="d-flex align-items-end justify-content-end my-1">
                          <div class="col-sm-12 my-2">
                            <b-form-input
                                id="filter-input-dashboard"
                                v-model="FormModalCrearCategoria.filter"
                                type="search"
                                placeholder="Buscar"
                            >
                            </b-form-input>
                          </div>
                        </b-row>-->
                        <b-row id="tableCategorias" class="mt-2">
                          <b-col cols="12">
                            <b-table class="table data-table"
                                     :items="FormModalCrearCategoria.data"
                                     :fields="FormModalCrearCategoria.fields"
                                     :current-page="FormModalCrearCategoria.currentPage"
                                     :per-page="FormModalCrearCategoria.perPage"
                                     :filter="FormModalCrearCategoria.filter"
                                     :filter-included-fields="FormModalCrearCategoria.filterOn"
                                     :striped="true"
                                     hover
                                     show-empty
                                     :small="true"
                                     :disabled="this.FormModalCrearCategoria.disableTable"
                            >
                              <template #empty="scope">
                                <h5 class="text-center">
                                  {{ scope.emptyText = 'Por el momento no existen categorías que mostrar.' }}
                                </h5>
                              </template>
                              <template #emptyfiltered="scope">
                                <h5 class="text-center">
                                  {{
                                    scope.emptyFilteredText = 'No existen categorías que coincidan con los parámetros de búsqueda.'
                                  }}
                                </h5>
                              </template>
                              <template #cell(categoria)="row">
                                <div v-html="row.value"></div>
                              </template>
                              <template #cell(actions)="row">
                                <div class="d-flex align-content-end">
                                  <b-button
                                      variant="primary"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="showModalCrearOpcion(row.item)"
                                      class="mr-1"
                                      title="Agregar opciones">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                           class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                        <path d="M8.235 1.559a.5.5 0 0 0-.47 0l-7.5 4a.5.5 0 0 0 0 .882L3.188 8 .264 9.559a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882L12.813 8l2.922-1.559a.5.5 0 0 0 0-.882l-7.5-4zm3.515 7.008L14.438 10 8 13.433 1.562 10 4.25 8.567l3.515 1.874a.5.5 0 0 0 .47 0l3.515-1.874zM8 9.433 1.562 6 8 2.567 14.438 6 8 9.433z"/>                                      </svg>
                                    </svg>
                                  </b-button>

                                  <b-button
                                      variant="success"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="showModalEditarCategoria(row.item)"
                                      class="mr-1"
                                      title="Editar categoría">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                           class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                      </svg>
                                    </svg>
                                  </b-button>

                                  <b-button
                                      variant="danger"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="showModalEliminarCategoria(row.item)"
                                      class="mr-1"
                                      title="Eliminar categoría">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                           class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                                      </svg>
                                    </svg>
                                  </b-button>



                                </div>
                              </template>

                            </b-table>
                            <div class="d-flex justify-content-between row p-2">
                              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div>
                                  <b-pagination
                                      v-model="FormModalCrearCategoria.currentPage"
                                      :total-rows="FormModalCrearCategoria.totalRows"
                                      :per-page="FormModalCrearCategoria.perPage"
                                      align="fill"
                                      size="sm"
                                      class="my-0"
                                  >
                                  </b-pagination>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
              <!--FIN DEL CUERPO TABS-->
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import 'moment/locale/es';
import Notificacion from '@/components/Notificacion.vue'
//import {VueEditor} from "vue2-editor";
import ReactivoOrdenamientoService from "@/services/ReactivoOrdenamiento";
import ReactivoCategorizacion from "@/services/ReactivoCategorizacion";
import NotificacionModal from "@/components/NotificacionModal.vue";

export default {
  components: {
    NotificacionModal,
    Notificacion,
    //VueEditor
  },
  data() {
    return {
      /* dataTable Modal*/
      Form: {
        descripcion: (this.$route.params.descripcion) ? (this.$route.params.descripcion) : null,
        disabledBtns: false,
        uuid_pregunta: (this.$route.params.uuid_pregunta) ? (this.$route.params.uuid_pregunta) : 0,
        tiporeactivo_id: 5,  //reactivo categorizacion
        textobtnGuardar: "Guardar",
        visibleNuevaPregunta:false,
      },
      FormModalCrearCategoria: {
            disabledTabCategoria:true,
            categoria: null,
            visibleModalCrearCategoria: false,
            visibleModalConfirmacion: false,
            mensajeConfirmacion: null,
            disabledBtns: false,
            filter: null,
            data: [],
            fields: [
              {label: 'Categoría', key: 'categoria', class: 'text-left'},
              {label: 'Acciones', key: 'actions', class: 'text-left'}
            ],
            currentPage: 1,
            perPage: 5,
            filterOn: [],
            totalRows: 0,
            disableTable:true
          },
      FormModalCrearOpcion: {
            opcion: null,
            visibleModalCrearOpcion: false,
            visibleModalConfirmacion: false,
            mensajeConfirmacion: null,
            disabledBtns: false,
            filter: null,
            data: [],
            fields: [
              {label: 'Opción', key: 'opcion', class: 'text-left'},
              {label: 'Acciones', key: 'actions', class: 'text-left'}
            ],
            currentPage: 1,
            perPage: 5,
            filterOn: [],
            totalRows: 0,
            uuid_categoria_fk:null,
            uuid_opcion: 0
          },
      FormModalEliminarOpcion: {
            opcion: null,
            visibleModalEliminarOpcion: false,
            visibleModalConfirmacion: false,
            mensajeConfirmacion: null,
            disabledBtns: false,
            filter: null,
            data: [],
            fields: [
              {label: 'Opción', key: 'opcion', class: 'text-left'},
              {label: 'Acciones', key: 'actions', class: 'text-left'}
            ],
            currentPage: 1,
            perPage: 5,
            filterOn: [],
            totalRows: 0,
            uuid_categoria_fk:null,
            uuid_opcion: 0
          },
      FormModalEditCategoria: {
            categoria:null,
            visibleModalCrearCategoria: false,
            visibleModalConfirmacion: false,
            mensajeConfirmacion: null,
            disabledBtns: false,
            uuid_categoria:null,
            uuid_pregunta_fk:null
          },
      FormModalEliminarCategoria: {
            categoria:null,
            visibleModalCrearCategoria: false,
            visibleModalConfirmacion: false,
            mensajeConfirmacion: null,
            disabledBtns: false,
            uuid_categoria:null,
            uuid_pregunta_fk:null
          },
      FormOrdenamiento: {
        disabled: false,
        list: [],
        visibleModalConfirmacion: false,
        mensajeConfirmacion: null
      },
      customToolbar:[
            [{header: [false, 1, 2, 3, 4, 5, 6]}],
            ["bold", "italic", "underline", "strike"],
            [{color: []}],
            [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
            [
              {align: ""},
              {align: "center"},
              {align: "right"},
              {align: "justify"}
            ],
            [{indent: "-1"}, {indent: "+1"}],
            ["clean"]
      ],
      visibleModalGruposCorreo: false,
      currentRowIndex: -1,
      dataTableModal: [],
      totalRowsModal: 1,
      currentPageDataTableModal: 1,
      perPageDataTableModal: 9,
      filterDataTableModal: null,
      filterOnDataTableModal: [],
      tituloModalDataTable: null,
      selectedIds: [],

      totalRows: 1,
      currentPageDataTable: 1,
      perPageDataTable: 5,
      filterDataTable: null,
      filterOnDataTable: [],
      //reactivos
      modalConfirmacion: false,
      mensajeConfirmacion: null,
      dataTable: [],
      activo: false,
      importacionSelected: null,
      idImportacionSelected: null,
      idGrupoSelected: null,
      dataImportaciones: [],
      visibleModalDataTable: false,
      visibleModalCrearGrupo: false,
      disabledBtnsCrearGrupo: false,
      visibleModalConfirmacion: false,
      uuid_preguntaEliminar: null,

      tituloGrupo: null,

      dragging: false,
      data: null,
      enabled: true,
    }
  },
  created() {

  },
  mounted() {
    if (this.$route.params.uuid_pregunta !== undefined) {
      this.getCategoriasUuid_pregunta(this.$route.params.uuid_pregunta)
    }
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  methods: {
    async getCategoriasUuid_pregunta($uuid_pregunta_fk){
      this.Form.visibleNuevaPregunta = true
      this.mensajeConfirmacion = "Favor de esperar un momento, procesando información...";
      this.modalConfirmacion = true
      const response = await ReactivoCategorizacion.getCategoriasbyUuid_pregunta_fk($uuid_pregunta_fk);
      //console.log(response)
      this.modalConfirmacion = false
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalCrearCategoria.data = data

/*        this.FormOrdenamiento.list = []
        this.FormModalCrearCategoria.data.forEach(concepto => {
          this.FormOrdenamiento.list.push(concepto);
        });*/
        this.FormModalCrearCategoria.totalRows = total
        if(this.FormModalCrearCategoria.totalRows>=1){
          this.FormModalCrearCategoria.disabledTabCategoria = false
          this.FormModalCrearCategoria.disableTable = false
        }
        else{
          this.FormModalCrearCategoria.disabledTabCategoria = true
          this.FormModalCrearCategoria.disableTable = true
        }
        this.showNotificacion(message, color);
      }
    },
    resetFormReactivoOrdenamiento(){
      this.FormModalCrearCategoria.disabledTabCategoria = true
      this.FormModalCrearCategoria.categoria=null
      this.FormModalCrearCategoria.visibleModalCrearCategoria=false
      this.FormModalCrearCategoria.visibleModalConfirmacion=false
      this.FormModalCrearCategoria.mensajeConfirmacion=null
      this.FormModalCrearCategoria.disabledBtns=false
      this.FormModalCrearCategoria.filter=null
      this.FormModalCrearCategoria.data=[]
      this.FormModalCrearCategoria.currentPage=1
      this.FormModalCrearCategoria.perPage=5
      this.FormModalCrearCategoria.filterOn=[]
      this.FormModalCrearCategoria.totalRows=0

      this.FormModalEditCategoria.categoria=null
      this.FormModalEditCategoria.visibleModalCrearCategoria= false
      this.FormModalEditCategoria.visibleModalConfirmacion= false
      this.FormModalEditCategoria.mensajeConfirmacion= null
      this.FormModalEditCategoria.disabledBtns= false
      this.FormModalEditCategoria.uuid_concepto=null
      this.FormModalEditCategoria.uuid_pregunta_fk=null

      this.FormOrdenamiento.disabled= false
      this.FormOrdenamiento.list= []
      this.FormOrdenamiento.visibleModalConfirmacion= false
      this.FormOrdenamiento.mensajeConfirmacion= null

      this.FormModalEliminarCategoria.categoria=null
      this.FormModalEliminarCategoria.visibleModalCrearCategoria= false
      this.FormModalEliminarCategoria.visibleModalConfirmacion= false
      this.FormModalEliminarCategoria.mensajeConfirmacion= null
      this.FormModalEliminarCategoria.disabledBtns= false
      this.FormModalEliminarCategoria.uuid_concepto=null
      this.FormModalEliminarCategoria.uuid_pregunta_fk=null

      this.Form.descripcion= null
      this.Form.disabledBtns= false
      this.Form.uuid_pregunta= 0
      this.Form.tiporeactivo_id= 4
      this.Form.textobtnGuardar= "Guardar"
      this.Form.visibleNuevaPregunta = false

      this.dragging= false
      this.data= null
      this.enabled= true
    },
    checkMove(e) {
      console.log("Future index: " + e.draggedContext.futureIndex);
      console.log("element: " + e.draggedContext.element.id);
    },

    async actualizarIndexElemento() {
      let formData = new FormData();
      formData.append("uuid_pregunta_fk", this.Form.uuid_pregunta);
      formData.append("lista_conceptos", JSON.stringify(this.FormOrdenamiento.list));
      this.FormOrdenamiento.disabled = true
      this.FormOrdenamiento.mensajeConfirmacion = "Favor de esperar un momento, actualizando ordenamiento...";
      this.FormOrdenamiento.visibleModalConfirmacion = true
      const response = await ReactivoOrdenamientoService.updateIndexConceptoOrdenamiento(formData);
      this.FormOrdenamiento.visibleModalConfirmacion = false
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalCrearCategoria.data = data
        this.FormOrdenamiento.list = []
        this.FormModalCrearCategoria.data.forEach(concepto => {
          this.FormOrdenamiento.list.push(concepto);
        });
        this.FormModalCrearCategoria.totalRows = total
        this.showNotificacion(message, color);
        this.FormOrdenamiento.disabled = false

      }
    }
    , show() {
      console.log("data")

    },
    showModalEditarCategoria(item){
      this.FormModalEditCategoria.visibleModalCrearCategoria = true
      this.FormModalEditCategoria.categoria = item.categoria
      this.FormModalEditCategoria.uuid_categoria = item.uuid_categoria
      this.FormModalEditCategoria.uuid_pregunta_fk = item.uuid_pregunta_fk
    },

    showModalEditarOpcion(item){
      //this.FormModalCrearOpcion.visibleModalCrearOpcion = true
      this.FormModalCrearOpcion.opcion = item.opcion
      this.FormModalCrearOpcion.uuid_opcion = item.uuid_opcion
      this.FormModalCrearOpcion.uuid_categoria_fk = item.uuid_categoria_fk
    },

    showModalEliminarCategoria(item) {
      this.FormModalEliminarCategoria.visibleModalCrearCategoria = true
      this.FormModalEliminarCategoria.categoria = item.categoria
      this.FormModalEliminarCategoria.uuid_categoria = item.uuid_categoria
      this.FormModalEliminarCategoria.uuid_pregunta_fk = item.uuid_pregunta_fk
    },

    showModalEliminarOpcion(item) {
      this.FormModalEliminarOpcion.visibleModalEliminarOpcion = true
      this.FormModalEliminarOpcion.opcion = item.opcion
      this.FormModalEliminarOpcion.uuid_opcion = item.uuid_opcion
      this.FormModalEliminarOpcion.uuid_categoria_fk = item.uuid_categoria_fk
    },

    resetFormModalCrearCategoria() {
      this.FormModalCrearCategoria.categoria = null
      this.FormModalCrearCategoria.visibleModalConfirmacion = false
      this.FormModalCrearCategoria.filter = null
      this.FormModalCrearCategoria.disabledBtns = false
      this.FormModalCrearCategoria.data = []
      this.FormModalCrearCategoria.currentPage = 1
      this.FormModalCrearCategoria.perPage = 5
      this.FormModalCrearCategoria.filterOn = []
      this.FormModalCrearCategoria.totalRows = 0

    },
    showModalCrearCategoria() {
      this.FormModalCrearCategoria.visibleModalCrearCategoria = true
      this.resetFormModalCrearCategoria()
    },

    resetFormModalCrearOpcion() {
      this.FormModalCrearOpcion.opcion = null
      this.FormModalCrearOpcion.visibleModalConfirmacion = false
      this.FormModalCrearOpcion.filter = null
      this.FormModalCrearOpcion.disabledBtns = false
      this.FormModalCrearOpcion.data = []
      this.FormModalCrearOpcion.currentPage = 1
      this.FormModalCrearOpcion.perPage = 5
      this.FormModalCrearOpcion.filterOn = []
      this.FormModalCrearOpcion.totalRows = 0
      this.FormModalCrearOpcion.uuid_categoria_fk = null
      this.FormModalCrearOpcion.uuid_opcion = 0

    },
    async getOpciones($uuid_categoria_fk){

      this.FormModalCrearOpcion.mensajeConfirmacion = "Favor de esperar un momento, procesando información...";
      this.FormModalCrearOpcion.visibleModalConfirmacion = true
      const response = await ReactivoCategorizacion.getOpciones($uuid_categoria_fk);
      this.FormModalCrearOpcion.visibleModalConfirmacion = false
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalCrearOpcion.data = data
        this.FormModalCrearOpcion.totalRows = total
        this.showNotificacionModalOpcion(message, color);
      }
    },
    async showModalCrearOpcion(item) {
      console.log(item)
      this.resetFormModalCrearOpcion()
      this.FormModalCrearOpcion.visibleModalCrearOpcion = true
      this.FormModalCrearOpcion.uuid_categoria_fk = item.uuid_categoria

      await this.getOpciones(this.FormModalCrearOpcion.uuid_categoria_fk)

    },

    async eliminarOpcion()
    {
      this.FormModalEliminarOpcion.disabledBtns = true
      this.FormModalEliminarOpcion.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormModalEliminarOpcion.visibleModalConfirmacion = true
      let formData = new FormData();

      formData.append("uuid_opcion", this.FormModalEliminarOpcion.uuid_opcion);
      formData.append("uuid_categoria_fk", this.FormModalEliminarOpcion.uuid_categoria_fk);
      const response = await ReactivoCategorizacion.deleteOpcion(formData);
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalEliminarOpcion.opcion = null
        this.FormModalEliminarOpcion.visibleModalConfirmacion = false
        this.FormModalEliminarOpcion.disabledBtns = false

        this.FormModalCrearOpcion.data = data
        this.FormModalCrearOpcion.totalRows = total
        this.FormModalEliminarOpcion.visibleModalEliminarOpcion = false
        this.showNotificacionModalOpcion(message, color);
      }
    },

    async eliminarCategoria()
    {
      this.FormModalEliminarCategoria.disabledBtns = true
      this.FormModalEliminarCategoria.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormModalEliminarCategoria.visibleModalConfirmacion = true
      let formData = new FormData();

      formData.append("uuid_categoria", this.FormModalEliminarCategoria.uuid_categoria);
      formData.append("uuid_pregunta_fk", this.FormModalEliminarCategoria.uuid_pregunta_fk);
      const response = await ReactivoCategorizacion.deleteCategoria(formData);
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalEliminarCategoria.categoria = null
        this.FormModalEliminarCategoria.visibleModalConfirmacion = false
        this.FormModalEliminarCategoria.disabledBtns = false

        this.FormModalCrearCategoria.data = data
        this.FormOrdenamiento.list = []
        this.FormModalCrearCategoria.data.forEach(concepto => {
          this.FormOrdenamiento.list.push(concepto);
        });
        this.FormModalCrearCategoria.totalRows = total
        this.FormModalEliminarCategoria.visibleModalCrearCategoria = false
        this.showNotificacion(message, color);
      }
    },
    async actualizarCategoria()
    {
      if (this.FormModalEditCategoria.categoria === null || this.FormModalEditCategoria.categoria === 0) {
        this.showNotificacionModal('¡Debe capturar la categoría!', 'danger');
        return false;
      }
      this.FormModalEditCategoria.disabledBtns = true
      this.FormModalEditCategoria.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormModalEditCategoria.visibleModalConfirmacion = true
      let formData = new FormData();
      formData.append("categoria", this.FormModalEditCategoria.categoria);
      formData.append("uuid_categoria", this.FormModalEditCategoria.uuid_categoria);
      formData.append("uuid_pregunta_fk", this.FormModalEditCategoria.uuid_pregunta_fk);
      const response = await ReactivoCategorizacion.updateCategoria(formData);
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalEditCategoria.categoria = null
        this.FormModalEditCategoria.visibleModalConfirmacion = false
        this.FormModalEditCategoria.disabledBtns = false

        this.FormModalCrearCategoria.data = data
/*        this.FormOrdenamiento.list = []
        this.FormModalCrearCategoria.data.forEach(concepto => {
          this.FormOrdenamiento.list.push(concepto);
        });*/
        this.FormModalCrearCategoria.totalRows = total
        this.FormModalEditCategoria.visibleModalCrearCategoria = false
        this.showNotificacion(message, color);
      }
    },
    async guardarCategoria() {
      if (this.FormModalCrearCategoria.categoria === null || this.FormModalCrearCategoria.categoria === 0) {
        this.showNotificacion('¡Debe capturar la categoría!', 'danger');
        return false;
      }
      this.FormModalCrearCategoria.disabledBtns = true
      this.FormModalCrearCategoria.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormModalCrearCategoria.visibleModalConfirmacion = true
      let formData = new FormData();
      formData.append("categoria", this.FormModalCrearCategoria.categoria);
      formData.append("uuid_pregunta_fk", this.Form.uuid_pregunta);
      const response = await ReactivoCategorizacion.storeCategoria(formData);
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalCrearCategoria.disableTable= false
        this.FormModalCrearCategoria.categoria = null
        this.FormModalCrearCategoria.visibleModalConfirmacion = false
        this.FormModalCrearCategoria.disabledBtns = false
        this.FormModalCrearCategoria.data = data
        this.FormOrdenamiento.list = []
        this.FormModalCrearCategoria.data.forEach(concepto => {
          this.FormOrdenamiento.list.push(concepto);
        });
        this.FormModalCrearCategoria.totalRows = total
        this.showNotificacionModal(message, color);
      }
    },

    async guardarOpcion() {
      if (this.FormModalCrearOpcion.opcion === null || this.FormModalCrearOpcion.opcion === 0) {
        this.showNotificacion('¡Debe capturar la opción!', 'danger');
        return false;
      }
      this.FormModalCrearOpcion.disabledBtns = true
      this.FormModalCrearOpcion.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.FormModalCrearOpcion.visibleModalConfirmacion = true
      let formData = new FormData();
      formData.append("opcion", this.FormModalCrearOpcion.opcion);
      formData.append("uuid_categoria_fk", this.FormModalCrearOpcion.uuid_categoria_fk);
      formData.append("uuid_opcion", this.FormModalCrearOpcion.uuid_opcion);

      const  response = await ReactivoCategorizacion.storeOpcion(formData);

      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.FormModalCrearOpcion.opcion = null
        this.FormModalCrearOpcion.uuid_opcion = 0
        this.FormModalCrearOpcion.visibleModalConfirmacion = false
        this.FormModalCrearOpcion.disabledBtns = false
        this.FormModalCrearOpcion.data = data
        this.FormModalCrearOpcion.totalRows = total
        this.showNotificacionModalOpcion(message,color);
      }
    },

    async guardarPlanteamiento() {
      if (this.Form.descripcion === null || this.Form.descripcion.length === 0) {
        this.$refs.formDescripcion.focus();
        this.showNotificacion('¡Debe capturar el nombre del grupo!', 'danger');
        return false;
      }
      this.Form.disabledBtns = true
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true

      let formData = new FormData();
      formData.append("descripcion", this.Form.descripcion);
      formData.append("uuid_pregunta", this.Form.uuid_pregunta);
      formData.append("tiporeactivo_id", this.Form.tiporeactivo_id);

      const response = await ReactivoCategorizacion.storePlanteamiento(formData);
      let {
        success,
        uuid_pregunta,
        message,
        color
      } = response.data;
      if (success) {
        this.FormModalCrearCategoria.disabledTabCategoria = false
        this.Form.visibleNuevaPregunta = true
        this.modalConfirmacion = false
        this.Form.uuid_pregunta = uuid_pregunta
        this.disabledBtnGuardarPregunta = false
        this.Form.textobtnGuardar = "Actualizar"
        this.Form.disabledBtns = false
        this.showNotificacion(message, color);
      }
    },

    showNotificacion(message, tipo) {
      this.$refs.notificacion.mostrar(message, tipo, 3000)
    },
    showNotificacionModal(message, tipo) {
      this.$refs.notificacionModal.mostrar(message, tipo, 3000)
    },
    showNotificacionModalInterno(message, tipo) {
      this.$refs.notificacionModalInterno.mostrar(message, tipo, 3000)
    },
    showNotificacionModalOpcion(message, tipo) {
      this.$refs.notificacionOpcion.mostrar(message, tipo, 3000)
    },
  }


}
</script>
<style lang="scss" scoped>
$studioAPPColor: #952f81;
.btn-studioAPP {
  color: $studioAPPColor !important;
  border: 1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active {
  background-color: #dadada !important;
  color: white !important;
}

.btn-primary.active, .btn-primary:active {
  background-color: #FFA500; /* Color de fondo cuando es pulsado */
  border-color: #FFA500; /* Color de borde cuando es pulsado */
}

.vue-editor__wrapper {
  height: 100px;
}

#editor1,
#editor2 {
  height: 550px;
}
</style>