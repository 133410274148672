<template>
  <b-card>
  <div>
    testasasasasas
    <b-form-radio-group

        stacked>
      <b-form-radio
          v-for="(option, optionIndex) in question.respuestas"
          :key="optionIndex"
          :value="!option.isChecked"
          @change="select(question.uuid_pregunta,option.uuid_respuesta)"
          class="mb-2">
        <div v-html="option.respuesta"></div>
      </b-form-radio>
    </b-form-radio-group>
  </div>
  </b-card>
</template>

<script>
/*
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'http://localhost:8000'
*/
export default {
  name: 'CountDown',
  data() {
    return {
      question: {
        respuestas: [
          { respuesta: 'Opción 1', isChecked: true },
          { respuesta: 'Opción 2', isChecked: false },
          { respuesta: 'Opción 3', isChecked: false }
        ],
        uuid_pregunta: '1'
      },
      databaseNameStream: process.env.VUE_APP_FIREBASE_DB_EXAMS_NAME,
      examen_finalizado_aux: 0,
      seconds: 0,
      countdown: null,
      expires_in: null,
      interval: null,
      checkstatus: null,
      paused_action: 0,
      resume_action: 0,
      exam_finished: 0,
      current_timestamp: ''
    }
  },
  mounted() {

  },

  methods: {
    select(pregunta,respuesta){
      console.log(pregunta)
      console.log(respuesta)
    }

  }
}
</script>
