export default (value) => {
    if (!value) return "-";
    const date = new Date(value);
    return date.toLocaleDateString(["es"], {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }); //if you want, you can change locale date string
  };
  