<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="text-center">
        <h3> {{FormExamen.titulo}}</h3>
      </b-col>
    </b-row>
    <div id="respuestas" v-if="FormDetallesExamen.visible_respuesta===1">
      <b-row class="mb-4">

        <h4 style="color: #B2007B">Calificación: 70</h4>
      </b-row>
      <b-row class="mb-2">

        <h5>Respuestas correctas:</h5>
      </b-row>
      <ul>
        <li v-for="(pregunta, index) in formRespuestas" :key="index" class="mb-2">
          <h6>{{ pregunta.descripcion }}</h6>
          <ul>
          <li v-for="(respuesta, index2) in pregunta.respuestasCorrectas" :key="index2">
            <p>{{ respuesta.descripcion}}</p>
          </li>
          </ul>
        </li>
      </ul>
    </div>
    <div id="general" v-if="FormDetallesExamen.visible===1">
    <div id="examen" v-for="(question, index) in FormDetallesExamen.data" :key="index" class="mb-4">
      <b-card v-if="question.preguntas.tiporeactivo_id===1">
        <div v-html="question.preguntas.descripcion"
             style="font-family: 'Roboto', sans-serif;font-size: 16px;line-height: 1.5;"></div>
        <b-form-radio-group
            :options="question.preguntas.opciones_respuesta"
            html-field="respuesta"
            value-field="uuid_respuesta"
            v-model="FormDetallesExamen.respuestaTipo1[`${question.preguntas.uuid_pregunta}`]"
            @change="respuestaSeleccionada"
            stacked
        >
        </b-form-radio-group>
      </b-card>

      <b-card v-if="question.preguntas.tiporeactivo_id===2">
        <div v-html="question.preguntas.descripcion" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
        <b-form-checkbox-group
            :options="question.preguntas.opciones_respuesta"
            html-field="respuesta"
            value-field="uuid_respuesta"
            v-model="FormDetallesExamen.respuestaTipo2[`${question.preguntas.opciones_respuesta.uuid_pregunta_fk}`]"
            @change="respuestaTipo2Seleccionada"
            class="mb-2"
            stacked
        ></b-form-checkbox-group>
      </b-card>

      <b-card v-if="question.preguntas.tiporeactivo_id===3">
        <div v-html="question.preguntas.descripcion" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
        <b-table class="table data-table"
                 :items="question.preguntas.reactivos_columna_a"
                 :fields="FormDetallesExamen.fields"
                 :current-page="FormDetallesExamen.currentPage"
                 :per-page="FormDetallesExamen.perPage"
                 :filter="FormDetallesExamen.filter"
                 :filter-included-fields="FormDetallesExamen.filterOn"
                 :striped="true"
                 hover
                 show-empty
                 :small="true"
        >
          <template #cell(descripcion)="row">
            <div v-html="row.item.descripcion"></div>
          </template>
          <template #cell(concepto)="row">
            <b-form-select
                v-model="FormDetallesExamen.respuestaTipo3[`${row.item.uuid_columnaA}`]"
                :options="question.preguntas.reactivos_columna_b"
                text-field="descripcion"
                value-field="uuid_columnaB"
                class="mb-3"
                size="sm"
                @change="respuestaTipo3Seleccionada(row)"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>-- Elige una opción --</b-form-select-option>
              </template>
            </b-form-select>
          </template>
        </b-table>
      </b-card>

      <b-card v-if="question.preguntas.tiporeactivo_id===4">
        <div v-html="question.preguntas.descripcion" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
        <b-row class="justify-content-end">
          <b-col sm="12">
            <draggable
                @input="actualizarIndexElemento"
                v-model="question.preguntas.conceptos_ordenamiento"
                class="list-group"
                ghost-class="ghost"
                @start="dragging = true"
                @end="dragging = false"
            >
              <!--                              <draggable
                                              @input="actualizarIndexElemento"
                                              v-model="FormOrdenamiento.list"
                                              :disabled="FormOrdenamiento.disabled"
                                              :move="checkMove"
                                              class="list-group"
                                              ghost-class="ghost"
                                              @start="dragging = true"
                                              @end="dragging = false"
                                          >-->
              <div
                  class="list-group-item"
                  v-for="element in question.preguntas.conceptos_ordenamiento"
                  :key="element.uuid_concepto"
              >
                {{ element.concepto }}
              </div>
            </draggable>
          </b-col>
        </b-row>
      </b-card>

      <b-card v-if="question.preguntas.tiporeactivo_id===5">
        <div v-html="question.preguntas.descripcion" style="font-family: 'Roboto', sans-serif;line-height: 1.5;">lll</div>
<!--        <b-row class="justify-content-end">
          <b-col sm="12">
            <draggable
                @input="actualizarIndexElemento"
                v-model="question.preguntas.categorias"
                class="list-group"
                ghost-class="ghost"
                @start="dragging = true"
                @end="dragging = false"
            >
              &lt;!&ndash;                              <draggable
                                              @input="actualizarIndexElemento"
                                              v-model="FormOrdenamiento.list"
                                              :disabled="FormOrdenamiento.disabled"
                                              :move="checkMove"
                                              class="list-group"
                                              ghost-class="ghost"
                                              @start="dragging = true"
                                              @end="dragging = false"
                                          >&ndash;&gt;
              <div
                  class="list-group-item"
                  v-for="element in question.preguntas.conceptos_ordenamiento"
                  :key="element.uuid_categoria"
              >
                {{ element.concepto }}
              </div>
            </draggable>
          </b-col>
        </b-row>-->
        <div class="row">
          <div class="col-3">
            <h7>Documentos de Google</h7>
            <draggable class="list-group" :list="list1" group="people" @change="log">
              <div
                  class="list-group-item"
                  v-for="(element) in list1"
                  :key="element.name"
              >
                {{ element.name }}
              </div>
            </draggable>
          </div>

          <div class="col-3">
            <h7>Formularios de Google</h7>
            <draggable class="list-group" :list="list2" group="people" @change="log">
              <div
                  class="list-group-item"
                  v-for="(element) in list2"
                  :key="element.name"
              >
                {{ element.name }}
              </div>
            </draggable>
          </div>

          <div class="col-3">
            <h7>Dibujos de Google</h7>
            <draggable class="list-group" :list="list3" group="people" @change="log">
              <div
                  class="list-group-item"
                  v-for="(element) in list3"
                  :key="element.name"
              >
                {{ element.name }}
              </div>
            </draggable>
          </div>
        </div>
      </b-card>


      <b-card v-if="question.preguntas.tiporeactivo_id===6">
        <div v-html="question.preguntas.descripcion"
             style="font-family: 'Roboto', sans-serif;font-size: 16px;line-height: 1.5;"></div>
        <b-form-radio-group
            :options="question.preguntas.opciones_respuesta"
            html-field="respuesta"
            value-field="uuid_respuesta"
            v-model="FormDetallesExamen.respuestaTipo1[`${question.preguntas.opciones_respuesta.uuid_pregunta_fk}`]"
            @change="respuestaSeleccionada"
            stacked
        >
        </b-form-radio-group>
      </b-card>

    </div>
      <button @click="submitExam" class="btn btn-primary" v-if="FormDetallesExamen.boton_visible===1">Finalizar Examen</button>
    </div>

  </b-container>
</template>
<script>

import ExamenService from "@/services/ExamenService";
import draggable from "vuedraggable";
export default {
  components: {
    draggable
    //Notificacion
  },
  data() {
    return {
      uuid_examen: (this.$route.params.uuid_examen) ? (this.$route.params.uuid_examen) : 0,
      list1: [
        { name: "Diseño gráfico de ideas", id: 1 },
        /*
                { name: "Dictado por voz", id: 3 },
                { name: "Diapositiva", id: 4 }
        */
      ],
      list2: [
        { name: "Organizar las notas tomadas en clase", id: 2 },
        { name: "Material de referencia adicional", id: 3 },
        /*        { name: "Rangos", id: 6 },
                { name: "Transiciones", id: 7 },
                { name: "Estilos de párrafo", id: 8 },*/
      ],
      list3: [
        { name: "Medir la comprensión", id: 4 },
        { name: "Calificar el contenido que se presenta", id: 5 },
        /*        { name: "Mostrar tira de película", id: 11 },
                { name: "Panel de actividad", id: 12 }*/
      ],
      FormDetallesExamen: {
        data: [],
        totalRows: 0,
        respuestaTipo1:[],
        respuestaTipo2: [],
        respuestaTipo3: [],
        fields: [
          {label: 'Descripcion', key: 'descripcion', class: 'text-left'},
          {label: 'Concepto', key: 'concepto', class: 'text-left'},
        ],
        currentPage: 1,
        perPage: 20,
        filter: null,
        filterOn: [],
        dataOpcionesCategoria:[],
        visible:1,
        visible_respuesta:0,
        boton_visible:0
      },
      FormExamen: {
        titulo: null,
        instrucciones: '',
        valorExamen: 0,
        tiempo_minutos_responder: 0,
        uuid_examen: 0,
        id: 0,
      },
      FormPreguntas: {
        data: [], //resultSetPreguntas: [],
        totalRows: 0,      //totalRowsPreguntas: 1,
        fields: [
          {label: 'Pregunta', key: 'descripcion', class: 'text-left',sortable: true},
          {label: 'Tipo', key: 'tiporeactivo_id', class: 'text-center',sortable: true},
          {label: 'Acciones', key: 'actions', class: 'text-center'}
        ],
        currentPage: 1,
        perPage: 10,
        filter: null,
        filterOn: [],
      },
      formRespuestas:[
        {
          uuid_pregunta:"8beef78e-f0c1-4562-902a-f2eba5a50027",
          descripcion:"¿Cual es el nuevo nombre de la suite de aplicaciones educacionales de Google?",
          respuestasCorrectas:[
              {
                  descripcion:"Google Workspace for Education"
              },
              ]
        },
        {
          uuid_pregunta:"069efd01-4090-40b9-9fbd-1c31d7f23ea3",
          descripcion:"¿Cuales de las siguientes son aplicaciones de Google Workspace?",
          respuestasCorrectas:[
            {
              descripcion:"Documentos"
            },
          ]
        },
        {
          uuid_pregunta:"24adc0d9-ba44-4ab8-8ba6-b7c582bb4721",
          descripcion:"Relaciona las descripciones con el tipo de aplicación, según corresponda:",
          respuestasCorrectas:[
            {
              descripcion:"Procesador de textos-Documentos"
            },
            {
              descripcion:"Funciona para procesar datos alfanuméricos mediante el uso de formulas.-Hojas de cálculo"
            }
          ]
        },
        {
          uuid_pregunta:"24adc0d9-ba44-4ab8-8ba6-b7c582bb4721",
          descripcion:"Ingresa los pasos para ingresar a la bandeja de entrada de tu cuenta de Google Worskpace en Gmail.",
          respuestasCorrectas:[
            {
              descripcion:"Abrir la pagina de gmail.com"
            },
            {
              descripcion:"Ingresar tu cuenta de correo de Google Workspace."
            },
          ]
        },
        {
          uuid_pregunta:"34tdc0d9-ba44-4ab8-8ba6-b7c582bb4825",
          descripcion:"A continuación se muestra una lista de distintas acciones que puedes realizar con las aplicaciones de Google Workspace for Education, ordenalas de acuerdo a cada aplicación:",
          respuestasCorrectas:[
            {
              descripcion:"Documentos de Google (Organizar las notas tomadas en clase)"
            },
            {
              descripcion:"Formularios de Google (Calificar el contenido que se presenta)"
            },
          ]
        },
      ],

      textobtnGuardarExamen: "Guardar Examen",
      disabledBtnGral: true,
      disabledTabPreguntas: true,
      disabledTabVistaPrevia: true,


      primer_guardado_pregunta: 0,
      contenidoPreguntaOriginal: null,

      //fin variables pregunta
      /*variables Generales*/
      disabledBtnCancelar: false,
      /*VARIABLES DE BARRA DE PROGRESO*/
      barWidth: null,
      duration: null,
      currentTime: null,
      isTimerPlaying: false,

      /*VARIABLES VIDEO*/
      textobtnGuardarVideo: "Guardar video",
      modalConfirmacionVideo: false,
      disabledBtnGuardarVideo: true,
      modalYoutube: false,
      video_save: 0,
      mensajeConfirmacion: null,
      modalConfirmacion: false,

      disabledBarraMultimedia: true,


      /* activar desactivar botones, textbox,textarea */
      visibleBtnDelVideo: true,


      tituloVideoYoutube: null,
      userSelectedVideo: 0,
      idVideoSelected: null,
      youtubeItems: [],
      youtubeItems_count: 0,


      //VARIABLES PARA IMAGEN
      disabledBtnsEliminarImagen: false,
      modalEliminarImagen: false,
      textobtnGuardarImagen: "Guardar archivo",
      disabledBtnGuardarImagen: false,
      modalConfirmacionImagen: false,
      primer_guardado_nameFileImagen: 0,
      nombre_archivo_original_imagen: null,
      url_imagen: null,
      imagenNueva: null,
      imagen: null,
      editarImagen: false,
      enabledToolbarImagen: false,
      modalZoomImagen: false,
      modalImagen: false,
      objImagen: null,
      //FIN VARIABLES PARA IMAGEN

      //VARIABLES PARA AUDIO
      disabledBtnsEliminarAudio: false,
      modalAudio: false,
      modalEliminarAudio: false,
      textobtnGuardarAudio: "Guardar archivo",
      disabledBtnGuardarAudio: false,
      primer_guardado_nameFileAudio: 0,
      nombre_archivo_original_audio: null,
      enabledToolbarAudio: false,
      modalConfirmacionAudio: false,
      audio_play: null,
      objAudio: null,
      audioNuevo: null,
      audio: null,
      editarAudio: false,
      url_audio: null,
      //fin vars para audio


      //variables RESPUESTA
      modalConfirmacionEliminarImagenRespuesta: false,
      modalEliminarImagenRespuesta: false,
      objImagenRespuesta: null,
      modalZoomImagenRespuesta: false,
      primer_guardado_nameFileImagenRespuesta: 0,
      nombre_archivo_original_imagenRespuesta: null,
      editarImagenRespuesta: false,
      disabledBarraMultimediaRespuesta: true,
      textobtnGuardarImagenRespuesta: "Guardar archivo",
      disabledBtnGuardarImagenRespuesta: false,
      url_imagenRespuesta: null,
      imagenNuevaRespuesta: null,
      modalImagenRespuesta: false,
      modalConfirmacionImagenRespuesta: false,

      maximoRespuestasOkPermitidas: 1,
      totalRespuestasOk: 0,
      respuesta: null,
      resultSetRespuestas: [],
      disabledBtnGuardarRespuesta: true,
      disabledBtnNuevaRespuesta: true,
      textobtnGuardarRespuesta: "Guardar Respuesta",
      disabledRespuesta: true,
      uuid_respuesta: 0,
      modalEliminarRespuesta: false,
      modalConfirmacionRespuesta: false,
      disabledBtnsEliminarRespuesta: false,
      //fin variables RESPUESTA

      filter: null,
      filterOn: [],
      resultSet: [],
      search: '',
      nombre_evento: null,
      numero_cuenta_pago: null,

      totalRows2: 1,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter2: null,
      filterOn2: [],
      resultSet2: [],
      search2: '',

    }
  },
  mounted() {
    this.FormExamen.uuid_examen = this.$route.params.uuid_examen
    this.getExamenAvanza()
  },

  computed: {

  },
  methods:
      {
        log: function(evt) {
          window.console.log(evt);
        },
        submitExam(){
          this.FormDetallesExamen.visible=0
          this.FormDetallesExamen.visible_respuesta=1
          console.log(this.formRespuestas)
        },
        actualizarIndexElemento(){

        },
        respuestaSeleccionada(respuestaUUID){
          console.log(respuestaUUID)
        },
        respuestaTipo2Seleccionada(respuestaUUID){
          console.log(respuestaUUID)
        },
        respuestaTipo3Seleccionada(respuestaUUID){
          console.log(respuestaUUID)
        },
        respuestaTipo6Seleccionada(respuestaUUID){
          //falso o verdadero
          console.log(respuestaUUID)
        },
        async getExamenAvanza() {
          this.FormDetallesExamen.data = []
          //console.log(this.FormExamen.uuid_examen)
          const response = await ExamenService.getExamenAvanza(this.FormExamen.uuid_examen);
          let {
            success,
            data,
              examen,
            totalRows
          } = response.data;
          if(success) {
            this.FormDetallesExamen.data = data
            this.FormDetallesExamen.totalRows = totalRows
            this.FormExamen.titulo = examen
            this.FormDetallesExamen.boton_visible=1
           console.log(this.FormDetallesExamen.data)
            //console.log(this.FormDetallesExamen.totalRows)
            /*        let totalObjetos = this.FormDetallesExamen.data.preguntas.length
                    console.log("total")
                    console.log(totalObjetos)*/
            /*        this.FormDetallesExamen.data.preguntas.forEach(categorias => {
                      this.FormDetallesExamen.dataOpcionesCategoria.add(categorias)
                    });
                    console.log("this.FormDetallesExamen.dataOpcionesCategoria")
                    console.log(this.FormDetallesExamen.data.preguntas.categorias)*/
          }
        },
      }
}


</script>
<style>
/*@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,500;1,900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,400;0,500;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,400;0,500;1,900&family=Sanchez&display=swap');

.b-form-radio + .b-form-radio {
  margin-top: 1rem;
}

.espacio-contador {
  display: block;
}

.container-contador-home {
  position: relative;
}

.container-contador {
  position: fixed;
  z-index: 9999;
  padding: 10px;
  top: 6.9vh;
  right: 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  padding: 100px;
}

.fixed-div {
  position: fixed;
  top: 75px;
  left: 250px;
  background-color: red;
  padding: 10px;

}
.fixedEdit-div {
  position: fixed;
  top: 75px;
  left: 250px;

  padding: 10px;

}
</style>