<template fluid>
<b-container>
  <b-row>
    <b-col cols="12">
      <b-table class="table data-table"
               :items="FormColumnaA.data"
               :fields="FormColumnaA.fields"
               :current-page="FormColumnaA.currentPage"
               :per-page="FormColumnaA.perPage"
               :filter="FormColumnaA.filter"
               :filter-included-fields="FormColumnaA.filterOn"
               :striped="true"
               hover
               show-empty
               :small="true"
      >
        <template #empty="scope">
          <h5 class="text-center">
            {{ scope.emptyText = 'Por el momento no existen preguntas que mostrar.' }}
          </h5>
        </template>
        <template #emptyfiltered="scope">
          <h5 class="text-center">
            {{
              scope.emptyFilteredText = 'No existen preguntas que coincidan con los parámetros de búsqueda.'
            }}
          </h5>
        </template>

        <template #cell(concepto)="row">
          <b-form-select
              v-model="FormColumnaA.select[`${row.item.id}`]"
              :options="FormColumnaA.dataSelect"
              text-field="opcion"
              :selected="FormColumnaA.dataSelect[`${row.item.uuid_columnaB_fk}`]"
              value-field="uuid_columnaB"
              class="mb-3"
              size="sm"
              @change="respuestaSeleccionada(row,FormColumnaA.select[`${row.item.id}`])"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>-- Elige una opción --</b-form-select-option>
            </template>
            <template #default>
              <b-form-select-option :value="row.item.uuid_columnaB_fk" ></b-form-select-option>
            </template>
          </b-form-select>

        </template>


      </b-table>
      <div class="d-flex justify-content-between row p-2">
        <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
          <div>
            <b-pagination
                v-model="FormColumnaA.currentPage"
                :total-rows="FormColumnaA.totalRows"
                :per-page="FormColumnaA.perPage"
                align="fill"
                size="sm"
                class="my-0"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
let id = 1;
export default {
  name: "simple",
  display: "Simple",
  order: 0,

  data() {
    return {
      enabled: true,

      FormColumnaA: {
        select:[],
        visibleModalEliminarPregunta:false,
        uuid_reactivo_fk: null,
        mensaje_confirmacion: null,
        uuid_columnaA: 0,
        descripcion: null,
        uuid_columnaB_respuesta: null,
        visibleModalConfirmacion: false,
        data: [
          { name: "Escribe el correo que enviarás", uuid_columnaA: "100",id:1,uuid_columnaB_fk:"17890" },
          { name: "Da clic en el botón de Redactar", uuid_columnaA: "200",id:2,uuid_columnaB_fk:"17890" },
          { name: "Abrir gmail", uuid_columnaA: "300",id:3,uuid_columnaB_fk:"37890" },
          { name: "Da clic en el botón enviar", uuid_columnaA: "400",id:4,uuid_columnaB_fk:"37890" },
          { name: "Llena los datos de destinatario y asunto", uuid_columnaA: "500",id:5,uuid_columnaB_fk:"57890" },
        ],
        dataSelect:[
          {uuid_columnaB:"7890", opcion:"concepto 1"},
          {uuid_columnaB:"17890", opcion:"concepto 2"},
          {uuid_columnaB:"27890", opcion:"concepto 3"},
          {uuid_columnaB:"37890", opcion:"concepto 4"},
          {uuid_columnaB:"47890", opcion:"concepto 5"},
          {uuid_columnaB:"57890", opcion:"concepto 6"},
        ],
        disabledBtns: false,
        fields: [
          {label: 'Pregunta', key: 'name', class: 'text-left', width:100},
          {label: 'Respuesta', key: 'concepto', class: 'text-center'},
          {label: 'Acciones', key: 'actions', class: 'text-center'}
        ],
        currentPage: 1,
        perPage: 5,
        filterOn: [],
        totalRows: 0,
        filter: null,
        textoBtn: null
      },
      dragging: false
    };
  },
  mounted(){



    this.FormColumnaA.data.forEach(columnaA => {
      this.FormColumnaA.select[`${columnaA.id}`] = columnaA.uuid_columnaB_fk;
    });
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  methods: {
    respuestaSeleccionada(item,id){
      console.log(this.FormColumnaA.dataSelect)
      console.log(item.item.uuid_columnaA);
      console.log(id);
      console.log(this.FormColumnaA.select)
    },
    add: function() {
      this.list.push({ name: "Juan " + id, id: id++ });
    },
    replace: function() {
      this.list = [{ name: "Edgard", id: id++ }];
    },
    checkMove(e) {
      console.log("Future index: " + e.draggedContext.futureIndex);
      console.log("element: " + e.draggedContext.element.id);
    },
    onListChange(){
      console.log("lista" + JSON.stringify(this.list, null, 2));
    },
    show(){
      console.log("data")
      console.log(this.list)
    }
  }
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>