<template>
  <b-container fluid>
    <b-row>
      <b-col cols="8">
      <h5>El profesor Ramírez utiliza diariamente Presentaciones de Google. Desea incluir otras herramientas como Documentos,
        Formularios y Dibujos para tener una clase más interactiva. Relaciona las opciones con la aplicación correcta.</h5><br><br>
      </b-col>
    </b-row>
  <div class="row">
    <div class="col-3">
      <h7>Documentos de Google</h7>
      <draggable class="list-group" :list="list1" group="people" @change="log">
        <div
            class="list-group-item"
            v-for="(element) in list1"
            :key="element.name"
        >
          {{ element.name }}
        </div>
      </draggable>
    </div>

    <div class="col-3">
      <h7>Formularios de Google</h7>
      <draggable class="list-group" :list="list2" group="people" @change="log">
        <div
            class="list-group-item"
            v-for="(element) in list2"
            :key="element.name"
        >
          {{ element.name }}
        </div>
      </draggable>
    </div>

    <div class="col-3">
      <h7>Dibujos de Google</h7>
      <draggable class="list-group" :list="list3" group="people" @change="log">
        <div
            class="list-group-item"
            v-for="(element) in list3"
            :key="element.name"
        >
          {{ element.name }}
        </div>
      </draggable>
    </div>

    <rawDisplayer class="col-3" :value="list1" title="List 1" />

    <rawDisplayer class="col-3" :value="list2" title="List 2" />
  </div>
  </b-container>
</template>
<script>
import draggable from "vuedraggable";

export default {
  name: "two-lists",
  display: "Two Lists",
  order: 1,
  components: {
    draggable
  },
  data() {
    return {
      list1: [
        { name: "Medir la comprensión", id: 1 },
        { name: "Calificar el contenido que se presenta", id: 2 },
/*
        { name: "Dictado por voz", id: 3 },
        { name: "Diapositiva", id: 4 }
*/
      ],
      list2: [
        { name: "Diseño gráfico de ideas", id: 3 },
/*        { name: "Rangos", id: 6 },
        { name: "Transiciones", id: 7 },
        { name: "Estilos de párrafo", id: 8 },*/
      ],
      list3: [
        { name: "Organizar las notas tomadas en clase", id: 4 },
        { name: "Material de referencia adicional", id: 5 },
/*        { name: "Mostrar tira de película", id: 11 },
        { name: "Panel de actividad", id: 12 }*/
      ]
    };
  },
  methods: {
    add: function() {
      this.list.push({ name: "Juan" });
    },
    replace: function() {
      this.list = [{ name: "Edgard" }];
    },
    clone: function(el) {
      return {
        name: el.name + " cloned"
      };
    },
    log: function(evt) {
      window.console.log(evt);
    }
  }
};
</script>