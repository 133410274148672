<template>
  <b-container fluid>
    <!-- inicio modal agregar asignación-->
    <b-modal no-close-on-backdrop no-close-on-esc  centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title= "Crear asignación"
             v-model="visibleModalCrearAsignacion"
    >
      <b-alert v-model="form_asignacion.visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ form_asignacion.mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionModalMensaje"/>
      <NotificacionModal ref="notificacionModal"/>
      <b-card
          tag="article"
          class="mb-2">

        <b-card-text>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="nombre-asignacion">Título asignación:</label>
            </b-col>
            <b-col cols="8">
              <b-form-input
                  v-model="form_asignacion.nombre_asignacion" id="nombre_asignacion"
                  placeholder="Captura el nombre de la asignación"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="input-titulo">Seleccionar Examen:</label>
            </b-col>
            <b-col cols="8">
              <b-form-select
                  v-model="form_asignacion.examenSelected"
                  :options="dataExamenes"
                  text-field="titulo"
                  value-field="uuid_examen"
                  class="mb-3"
                  size="sm"
                  return-object
                  @change="getExamenSelected(form_asignacion.examenSelected)"
              >
              </b-form-select>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col cols="4">
              <label for="input-titulo">Seleccionar Grupo:</label>
            </b-col>
            <b-col cols="8">
              <b-form-select
                  v-model="form_asignacion.grupoSelected"
                  :options="dataGrupos"
                  text-field="nombre_grupo"
                  value-field="uuid_grupo"
                  class="mb-3"
                  size="sm"
                  return-object
                  @change="getGrupoSelected(form_asignacion.grupoSelected)"
              >
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="input-titulo">Fecha de inicio de examen:</label>
            </b-col>
            <b-col col lg="8">
              <b-form-datepicker locale="es"
                                 placeholder="Fecha inicio"
                                 v-model="form_asignacion.fecha_inicio_examen"
                                 size="sm"
                                 label-help=''
                                 label-today-button='Hoy'
                                 today-button
                                 label-no-date-selected='No se ha elegido fecha'>
              </b-form-datepicker>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col cols="4">
              <label for="input-titulo">Hora de inicio de examen:</label>
            </b-col>
            <b-col cols="8">
              <b-row>
                <b-col>
                  <b-form-timepicker
                      size='sm'
                      now-button
                      label-now-button='Ahora'
                      label-hours='Hr'
                      label-minutes='Min'
                      hide-header
                      label-close-button='Cerrar'
                      placeholder="Hora inicio examen"
                      v-model="form_asignacion.hora_inicio_examen" locale="es">
                  </b-form-timepicker>
                </b-col>
              </b-row>
            </b-col>
          </b-row>


          <b-row class="my-1">
            <b-col cols="4">
              <label for="input-titulo">Fecha finalización examen:</label>
            </b-col>
            <b-col col lg="8">
              <b-form-datepicker locale="es"
                                 placeholder="Fecha fin"
                                 v-model="form_asignacion.fecha_fin_examen"
                                 size="sm"
                                 label-help=''
                                 label-today-button='Hoy'
                                 today-button
                                 label-no-date-selected='No se ha elegido fecha'>
              </b-form-datepicker>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col cols="4">
              <label for="input-titulo">Hora finalización de examen:</label>
            </b-col>
            <b-col cols="8">
              <b-row>
                <b-col>
                  <b-form-timepicker
                      size='sm'
                      now-button
                      label-now-button='Ahora'
                      label-hours='Hr'
                      label-minutes='Min'
                      hide-header
                      label-close-button='Cerrar'
                      placeholder="Hora fin examen"
                      v-model="form_asignacion.hora_fin_examen" locale="es">
                  </b-form-timepicker>
                </b-col>
              </b-row>
            </b-col>
          </b-row>



          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarAsignacion()"
                  title="Guardar asignación"
                  :disabled="form_asignacion.disabledBtns"
              >
                Guardar
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="visibleModalCrearAsignacion=false"
                  :disabled="form_asignacion.disabledBtns"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>
    <!--fin modal agregar asignación-->



    <!--modal confirmar eliminar imagen de respuesta-->
    <b-modal no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalEliminarPregunta"
             title="Confirmar eliminar pregunta"
             v-model="modalEliminarPregunta"
    >
      <b-alert v-model="modalConfirmacionEliminarPregunta" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ form_asignacion.mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionEliminarPregunta"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea eliminar la pregunta?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarPregunta()"
                  title="Eliminar pregunta"
                  :disabled="disabledBtnsEliminarPregunta"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalEliminarPregunta=false"
                  :disabled="disabledBtnsEliminarPregunta"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>
    <!-- fin modal eliminar imagen de respuesta   -->
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body">
            <b-alert
                show
                variant="primary"
                dismissible>
              Asignaciones
            </b-alert>
            <!--ventana confirmacion general-->
            <b-alert v-model="modalConfirmacion" variant="light" show>
              <b-button variant="primary" disabled>
                <b-spinner small type="grow"></b-spinner>
                {{ form_asignacion.mensajeConfirmacion }}
              </b-button>
            </b-alert>
            <Notificacion ref="notificacion"/>
            <!--cerrar ventana confirmacion general-->
          </div>
          <div class="col-sm-12 my-2">
            <div>
              <b-card no-body>
                <b-tabs card>
                  <!--INICIO TAB1-->
                  <b-tab title="Dashboard" active>
                    <b-card-text>
                      <b-form>
                        <b-row class="justify-content-end">
                          <b-col sm="12" >
                            <div class="col-sm-12">
                              <b-button
                                  variant="success"
                                  size="sm"
                                  @click="showModalCrearAsignacion()"
                                  title="Crear asignación"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                     class="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                                </svg>
                              </b-button>
                            </div>
                          </b-col>
                        </b-row>

                        <b-row class="d-flex align-items-end justify-content-end my-1">
                          <div class="col-sm-12 my-2">
                            <b-form-input
                                id="filter-input"
                                v-model="filter_reactivos"
                                type="search"
                                placeholder="Buscar"
                            >
                            </b-form-input>
                          </div>
                        </b-row>
                        <b-row>
                          <b-col cols="12">

                            <b-table class="table data-table"
                                     :items="dataAsignaciones"
                                     :fields="fields_dataTable"
                                     :current-page="currentPage_reactivos"
                                     :per-page="perPage_reactivos"
                                     :filter="filter_reactivos"
                                     :filter-included-fields="filterOn_reactivos"
                                     :striped="true"
                                     hover
                                     show-empty
                                     :small="true"
                            >
                              <template #empty="scope">
                                <h5 class="text-center">
                                  {{ scope.emptyText='Por el momento no hay preguntas que mostrar.' }}
                                </h5>
                              </template>
                              <template #emptyfiltered="scope">
                                <h5 class="text-center">
                                  {{ scope.emptyFilteredText='No hay preguntas que coincidan con los parámetros de búsqueda.' }}
                                </h5>
                              </template>
                              <template #cell(descripcion)="row">
                                <div v-html="row.value"></div>
                              </template>
                              <template #cell(tiporeactivo_id)="row">
                                <div v-if="row.item.tiporeactivo_id===1">ROM única respuesta</div>
                                <div v-if="row.item.tiporeactivo_id===2">ROM varias respuestas</div>
                                <div v-if="row.item.tiporeactivo_id===3">Relacionar columnas</div>
                              </template>
                              <template #cell(actions)="row">
                                <div class="d-flex justify-content-around align-items-center">
                                  <b-button
                                      variant="success"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="redirectPreguntaEdit(row.item)"
                                      class="mr-1"
                                      title="Editar pregunta">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <path
                                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                      <path fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                    </svg>
                                  </b-button>
                                  <b-button
                                      variant="danger"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="abrirModalEliminarPregunta(row.item.uuid_pregunta)"
                                      class="mr-1"
                                      title="Eliminar pregunta">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                           class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                                      </svg>                                    </svg>
                                  </b-button>
                                  <!--                                  <b-button
                                                                        variant="warning"
                                                                        size="sm"
                                                                        @click="abrirEditarImagenRespuestaRow(row.item)"
                                                                        title="Ver imagen"
                                                                    >
                                                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                                           class="bi bi-youtube" viewBox="0 0 16 16">
                                                                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                                        <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>                                  </svg>
                                                                    </b-button>-->
                                </div>
                              </template>

                            </b-table>
                            <div class="d-flex justify-content-between row p-2">
                              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div>
                                  <b-pagination
                                      v-model="currentPage_reactivos"
                                      :total-rows="totalRows"
                                      :per-page="perPage_reactivos"
                                      align="fill"
                                      size="sm"
                                      class="my-0"
                                  >
                                  </b-pagination>
                                </div>
                              </div>
                            </div>

                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>
                  </b-tab>
                  <!--FIN TAB1-->

                </b-tabs>
              </b-card>
              <!--FIN DEL CUERPO TABS-->
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import toast from "@/mixins/ToastMixin.js";
import PreguntaService from "@/services/PreguntaService";
import Asignaciones from "@/services/Asignaciones";
import 'moment/locale/es';
import Notificacion from '@/components/Notificacion.vue'
import NotificacionModal from '@/components/NotificacionModal.vue'


export default {
  components: {
    Notificacion,
    NotificacionModal
  },
  mixins: [toast],
  data() {
    return {
      fields_dataTable: [
        {label: '#', key: 'id', class: 'text-left'},
        {label: 'Asignación', key: 'nombre_asignacion', class: 'text-left'},
        {label: 'Examen', key: 'nombre_examen', class: 'text-left'},
        {label: 'Grupo', key: 'nombre_grupo', class: 'text-left'},
       // {label: 'Tipo', key: 'tiporeactivo_id', class: 'text-center'},
        {label: 'Acciones', key: 'actions', class: 'text-center'}
      ],
      totalRows: 1,
      currentPage_reactivos: 1,
      perPage_reactivos: 5,
      filter_reactivos: null,
      filterOn_reactivos: [],
      //reactivos
      modalConfirmacion: false,

      dataAsignaciones: [],
      disabledBtnInscribirse: false,
      activo: false,
      modalEditarSesion: false,
      modalAbrirEncuesta: false,
      nombre_sesion_actual: null,
      sesion_id_actual: null,
      modalDeleteSesion: false,

      modalEliminarPregunta:false,
      modalConfirmacionEliminarPregunta:false,
      disabledBtnsEliminarPregunta:false,
      uuid_preguntaEliminar:null,

      //asignacion
      visibleModalCrearAsignacion:false,


      dataExamenes:[],
      dataGrupos:[],


      context:null,
      value:'',
      evento_form:{
        id:0,
        editar:false,
        fecha_inicio: '',
        fecha_finalizacion : '',
        hora_inicio: null,
        hora_fin: null,

        youtube:{ label:'Elegir', id: 0 },
        fecha_vigencia_inicio : '',
        fecha_vigencia_finalizacion : ''
      },
      form_asignacion:
          {
            id       :0,
            nombre_asignacion:null,
            examenSelected:null,
            grupoSelected:null,
            fecha_inicio_examen:null,
            fecha_fin_examen:null,
            hora_inicio_examen:null,
            hora_fin_examen:null,
            disabledBtns:false,
            mensajeConfirmacion: null,
            visibleModalConfirmacion:false,
          },
    }
  },
  created() {
    this.objAudio = new Audio();
  },
  mounted() {
    this.getAsignaciones();
  },
  methods: {
    onContext(ctx) {
      this.context = ctx
    },
    async guardarAsignacion(){
/*      console.log(this.form_asignacion.nombre_asignacion)
      console.log(this.form_asignacion.examenSelected)
      console.log(this.form_asignacion.grupoSelected)
      console.log(this.form_asignacion.fecha_inicio_examen)
      console.log(this.form_asignacion.hora_inicio_examen)
      console.log(this.form_asignacion.fecha_fin_examen)
      console.log(this.form_asignacion.hora_fin_examen)*/
      if (this.form_asignacion.nombre_asignacion==null)
      {
        this.showNotificacionModal("Es necesario capturar el título de la asignación.", 'danger');
        return false;
      }
      else if(this.form_asignacion.examenSelected == null)
      {
        this.showNotificacionModal("Es necesario seleccionar el Examen.", 'danger');
        return false;
      }
      else if(this.form_asignacion.grupoSelected == null)
      {
        this.showNotificacionModal("Es necesario seleccionar el Grupo.", 'danger');
        return false;
      }
      else if(this.form_asignacion.fecha_inicio_examen == null)
      {
        this.showNotificacionModal("Es necesario especificar la fecha de inicio del examen", 'danger');
        return false;
      }
      else if(this.form_asignacion.hora_inicio_examen == null)
      {
        this.showNotificacionModal("Es necesario especificar la hora de inicio del examen", 'danger');
        return false;
      }

      else if(this.form_asignacion.fecha_fin_examen == null)
      {
        this.showNotificacionModal("Es necesario especificar la fecha finalización del examen", 'danger');
        return false;
      }
      else if(this.form_asignacion.hora_fin_examen == null)
      {
        this.showNotificacionModal("Es necesario especificar la hora finalización del examen", 'danger');
        return false;
      }
      else if(this.form_asignacion.fecha_inicio_examen>this.form_asignacion.fecha_fin_examen)
      {
        this.showNotificacionModal("La fecha de inicio no puede ser mayor que la fecha de finalización del examen.", 'danger');
        return false;
      }
      this.form_asignacion.disabledBtns = true
      this.form_asignacion.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.form_asignacion.visibleModalConfirmacion = true
      let formData = new FormData();
      formData.append("nombre_asignacion", this.form_asignacion.nombre_asignacion);
      formData.append("examenSelected", this.form_asignacion.examenSelected);
      formData.append("grupoSelected", this.form_asignacion.grupoSelected);
      formData.append("fecha_inicio_examen", this.form_asignacion.fecha_inicio_examen);
      formData.append("hora_inicio_examen", this.form_asignacion.hora_inicio_examen);
      formData.append("fecha_fin_examen", this.form_asignacion.fecha_fin_examen);
      formData.append("hora_fin_examen", this.form_asignacion.hora_fin_examen);


    },
    getExamenSelected(examenSelected){
      console.log("examen selected")
      console.log(examenSelected)
    },
    getGrupoSelected(grupoSelected){
      console.log("grupoSelected")
      console.log(grupoSelected)
    },
    async showModalCrearAsignacion(){
      this.form_asignacion.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      this.dataExamenes = []
      const response = await Asignaciones.getExamenes();
      this.dataExamenes = response.data.data;
      const grupos = await Asignaciones.getGrupos();
      this.dataGrupos = grupos.data.data;
      this.modalConfirmacion = false
      this.visibleModalCrearAsignacion = true
    },
    showNotificacion(message, tipo) {
      this.$refs.notificacion.mostrar(message, tipo, 3000)
    },
    showNotificacionModal(message, tipo) {
      this.$refs.notificacionModal.mostrar(message, tipo, 3000)
    },
    abrirModalEliminarPregunta(uuid_pregunta){
      this.modalEliminarPregunta=true
      this.uuid_preguntaEliminar = uuid_pregunta
    },
    async eliminarPregunta(){
      try {
        this.disabledBtnsEliminarPregunta = true
        this.form_asignacion.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionEliminarPregunta = true
        const response = await PreguntaService.deletePregunta(this.uuid_preguntaEliminar);
        let {
          success,
          message,
          color
          ,preguntas
          ,cantidadPreguntas
        } = response.data;
        if (success == true) {
          //this.toast('b-toaster-bottom-center', true, message, color );
          this.showNotificacion(message, color)
          this.modalEliminarPregunta = false;
          this.modalConfirmacionEliminarPregunta = false

          this.dataAsignaciones = []
          this.dataAsignaciones = preguntas;
          this.totalRows = cantidadPreguntas;
          //queda pendiente eliminar fisicamente el archivo en
          //cloud storage
          //
        }
      } catch (error) {
        this.showNotificacion('Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      }
    },
    async getAsignaciones() {
      this.form_asignacion.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      this.dataAsignaciones = []
      const response = await Asignaciones.getAsignaciones();

      //console.log(response)
      this.modalConfirmacion = false
      this.dataAsignaciones = response.data.data;
      this.totalRows = response.data.totalRegistros;
    }
    ,redirectPreguntaEdit(rowPregunta){
      let uuid_pregunta = rowPregunta.uuid_pregunta
      let descripcion = rowPregunta.descripcion
      let url_imagen = rowPregunta.url_imagen
      let url_audio = rowPregunta.url_audio
      if(rowPregunta.tiporeactivo_id===1) {
        this.$router.push({
          name: 'opcionMultipleUnaRespuesta',
          params: {uuid_pregunta, descripcion, url_imagen, url_audio}
        });
      }
      else if(rowPregunta.tiporeactivo_id===2) {
        this.$router.push({
          name: 'reactivoVariasRespuestas',
          params: {uuid_pregunta, descripcion, url_imagen, url_audio}
        });
      }
      else if(rowPregunta.tiporeactivo_id===3) {
        this.$router.push({
          name: 'reactivoRelacionarColumnas',
          params: {uuid_pregunta, descripcion, url_imagen, url_audio}
        });
      }
    }
  }


}
</script>
<style lang="scss" scoped>
$studioAPPColor: #952f81;
.btn-studioAPP {
  color: $studioAPPColor !important;
  border: 1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active {
  background-color: #dadada !important;
  color: white !important;
}
.btn-primary.active, .btn-primary:active {
  background-color: #FFA500; /* Color de fondo cuando es pulsado */
  border-color: #FFA500; /* Color de borde cuando es pulsado */
}
.vue-editor__wrapper {
  height: 100px;
}
#editor1,
#editor2 {
  height: 550px;
}
</style>