import api from '@/services/api';
export default
{
    getGrupos()
    {
        let url          = `/api/grupos/get-all`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getImportaciones()
    {
        let url          = `/api/importaciones/get-all`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    getCorreosGrupo(idGrupoSelected)
    {
        let url          = `/api/grupos/get-correos-grupo/${idGrupoSelected}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    storeGrupo(formData){
        let url          = `/api/grupos/store-grupo`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },

    storeGrupoImportacion(formData){
        let url          = `/api/grupos/store-grupo-importacion`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },

}