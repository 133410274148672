import api from '@/services/api';
export default {
    getCategoriasbyUuid_pregunta_fk(uuid_pregunta_fk)
    {
        let url          = `/api/preguntas/reactivo-categorizacion/categorias/${uuid_pregunta_fk}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getOpciones(uuid_categoria_fk)
    {
        let url          = `/api/preguntas/reactivo-categorizacion/opciones/${uuid_categoria_fk}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    storePlanteamiento(formData){
        let url          = `/api/preguntas/reactivo-categorizacion/store-planteamiento`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    storeCategoria(formData){
        let url          = `/api/preguntas/reactivo-categorizacion/store-categoria`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    storeOpcion(formData){
        let url          = `/api/preguntas/reactivo-categorizacion/store-opcion`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    updateOpcion(formData){
        let url          = `/api/preguntas/reactivo-categorizacion/update-opcion`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    updateCategoria(formData){
        let url          = `/api/preguntas/reactivo-categorizacion/update-categoria`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    deleteCategoria(formData){
        let url          = `/api/preguntas/reactivo-categorizacion/delete-categoria`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    deleteOpcion(formData){
        let url          = `/api/preguntas/reactivo-categorizacion/delete-opcion`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
}