<template>
  <b-modal no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
           hide-footer persistent
           ref="modalCapturarPregunta"
           title="Agregar Respuesta |Columna B"
           v-model="ventanaModalRespuesta"
  >
    <b-alert v-model="ventanaModalConfirmacion" variant="light" show>
      <b-button variant="primary" disabled>
        <b-spinner small type="grow"></b-spinner>
        {{ mensajeConfirmacion }}
      </b-button>
    </b-alert>
    <Notificacion ref="notificacionRespuesta"/>
    <b-card
        tag="article"
        class="mb-2"
    >
      <b-row>
        <b-col cols="12">
          <div id="editor2ED">
            <vue-editor v-model="respuestaColumnaB"
                        ref="pregunta_columnaA"
                        id="editorPreguntaColumnaA"
                        :editorToolbar="customToolbar"
                        placeholder="Captura la opción de respuesta"
                        class="mb-5"
            >
            </vue-editor>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="w-100">
            <b-button
                variant="success"
                size="sm"
                class="float-left  mt-5"
                @click="guardar()"
                :title=textoBotonVentanaModal
                :disabled="disabledBotonVentanaModal"
            >
              {{ textoBotonVentanaModal }}
            </b-button>
            <b-button
                variant="primary"
                size="sm"
                class="float-left  ml-2 mt-5"
                @click="cerrarVentanaModal"
                title="Cancelar"
            >
              Cancelar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<style scoped>

</style>
<script>
import Notificacion from "@/components/Notificacion.vue";
import {VueEditor} from "vue2-editor";

export default {
  props:[
    'customToolbar',
      'ventanaModalRespuestaParameter',
      'ventanaModalConfirmacion',
      'mensajeConfirmacion',
      'disabledBotonVentanaModal',
      'textoBotonVentanaModal'
  ],
  components: {
    Notificacion,
    VueEditor
  },
  data() {
    return {
      ventanaModalRespuesta: this.ventanaModalRespuestaParameter,
      respuestaColumnaB:null
    }
  },
  methods:{
    guardar(){
      //this.$emit('form-submitted', { name: this.name, email: this.email });
      this.$emit( "respuestaColumnaB",this.respuestaColumnaB);
    },
    cerrarVentanaModal(){
      this.ventanaModalRespuesta=false
      this.$emit('update:ventanaModalRespuestaParameter',this.ventanaModalRespuesta);
    }
  }

};
</script>