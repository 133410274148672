<template>
  <b-container>
    <div id="examen" v-for="(question, index) in FormDetallesExamen.data" :key="index" class="mb-4">
      <b-card v-if="question.preguntas.tiporeactivo_id===5">
        <div v-html="question.preguntas.descripcion" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
        <b-row class="justify-content-start">
          <div id="categoria" v-for="(data, indexData) in categorias" :key="indexData">
          <b-col sm="12" v-if="data.idPregunta===question.preguntas.id">
            <div id="columnasDraggables">
              <div class="columns">
                <b-row>
                  <div v-for="(column, columnIndex) in data.items" :key="columnIndex" class="column">
                    <b-col cols="3" md="auto">
                      <h6>{{ column.name }}</h6>

                        <draggable class="list-group" :list="column.items" group="animals" @change="log">
                          <div
                              class="list-group-item"
                              v-for="(respuesta) in column.items"
                              :key="respuesta.uuid_opcion"
                          >
                            {{ respuesta.opcion }}
                          </div>
                        </draggable>
                    </b-col>
                  </div>
                </b-row>
              </div>
            </div>
          </b-col>
          </div>
        </b-row>
      </b-card>
      <b-card v-if="question.preguntas.tiporeactivo_id===1">
        <div v-html="question.preguntas.descripcion"
             style="font-family: 'Roboto', sans-serif;font-size: 16px;line-height: 1.5;"></div>
        <b-form-radio-group
            :options="question.preguntas.opciones_respuesta"
            html-field="respuesta"
            value-field="uuid_respuesta"
            v-model="FormDetallesExamen.respuestaTipo1[`${question.preguntas.opciones_respuesta.uuid_pregunta_fk}`]"
            @change="respuestaSeleccionada"
            stacked
        >
        </b-form-radio-group>
      </b-card>

      <b-card v-if="question.preguntas.tiporeactivo_id===2">
        <div v-html="question.preguntas.descripcion" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
        <b-form-checkbox-group
            :options="question.preguntas.opciones_respuesta"
            html-field="respuesta"
            value-field="uuid_respuesta"
            v-model="FormDetallesExamen.respuestaTipo2[`${question.preguntas.opciones_respuesta.uuid_pregunta_fk}`]"
            @change="respuestaTipo2Seleccionada"
            class="mb-2"
            stacked
        ></b-form-checkbox-group>
      </b-card>

      <b-card v-if="question.preguntas.tiporeactivo_id===3">
        <div v-html="question.preguntas.descripcion" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
        <b-table class="table data-table"
                 :items="question.preguntas.reactivos_columna_a"
                 :fields="FormDetallesExamen.fields"
                 :current-page="FormDetallesExamen.currentPage"
                 :per-page="FormDetallesExamen.perPage"
                 :filter="FormDetallesExamen.filter"
                 :filter-included-fields="FormDetallesExamen.filterOn"
                 :striped="true"
                 hover
                 show-empty
                 :small="true"
        >
          <template #cell(descripcion)="row">
            <div v-html="row.item.descripcion"></div>
          </template>
          <template #cell(concepto)="row">
            <b-form-select
                v-model="FormDetallesExamen.respuestaTipo3[`${row.item.uuid_columnaA}`]"
                :options="question.preguntas.reactivos_columna_b"
                text-field="descripcion"
                value-field="uuid_columnaB"
                class="mb-3"
                size="sm"
                @change="respuestaTipo3Seleccionada(row)"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>-- Elige una opción --</b-form-select-option>
              </template>
            </b-form-select>
          </template>
        </b-table>
      </b-card>

      <b-card v-if="question.preguntas.tiporeactivo_id===4">
        <div v-html="question.preguntas.descripcion" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
        <b-row class="justify-content-end">
          <b-col sm="12">
            <draggable
                @input="actualizarIndexElemento"
                v-model="question.preguntas.conceptos_ordenamiento"
                class="list-group"
                ghost-class="ghost"
                @start="dragging = true"
                @end="dragging = false"
            >
              <!--                              <draggable
                                              @input="actualizarIndexElemento"
                                              v-model="FormOrdenamiento.list"
                                              :disabled="FormOrdenamiento.disabled"
                                              :move="checkMove"
                                              class="list-group"
                                              ghost-class="ghost"
                                              @start="dragging = true"
                                              @end="dragging = false"
                                          >-->
              <div
                  class="list-group-item"
                  v-for="element in question.preguntas.conceptos_ordenamiento"
                  :key="element.uuid_concepto"
              >
                {{ element.concepto }}
              </div>
            </draggable>
          </b-col>
        </b-row>
      </b-card>


      <b-card v-if="question.preguntas.tiporeactivo_id===6">
        <div v-html="question.preguntas.descripcion"
             style="font-family: 'Roboto', sans-serif;font-size: 16px;line-height: 1.5;"></div>
        <b-form-radio-group
            :options="question.preguntas.opciones_respuesta"
            html-field="respuesta"
            value-field="uuid_respuesta"
            v-model="FormDetallesExamen.respuestaTipo1[`${question.preguntas.opciones_respuesta.uuid_pregunta_fk}`]"
            @change="respuestaSeleccionada"
            stacked
        >
        </b-form-radio-group>
      </b-card>
    </div>
    <!--  <div class="row">
        <div class="col-3">
          <h3>Draggable 1</h3>
          <draggable class="list-group" :list="list1" group="people" @change="log1">
            <div
                class="list-group-item"
                v-for="(element, index) in list1"
                :key="element.name"
            >
              {{ element.name }} {{ index }}
            </div>
          </draggable>
        </div>

        <div class="col-3">
          <h3>Draggable 2</h3>
          <draggable class="list-group" :list="list2" group="people" @change="log2">
            <div
                class="list-group-item"
                v-for="(element, index) in list2"
                :key="element.name"
            >
              {{ element.name }} {{ index }}
            </div>
          </draggable>
        </div>

        <rawDisplayer class="col-3" :value="list1" title="List 1" />

        <rawDisplayer class="col-3" :value="list2" title="List 2" />
      </div>-->
  </b-container>
</template>
<script>
import draggable from 'vuedraggable';
//import rawDisplayer from './raw-displayer.vue';
import PreguntaService from "@/services/PreguntaService";
import ExamenService from "@/services/ExamenService";

export default {
  name: "two-lists",
  display: "Two Lists",
  order: 1,
  components: {
    draggable,
    //rawDisplayer
  },
  data() {
    return {
      categoriasArray:[],
      opcionesArray: [], // Aquí almacenaremos los elementos de opciones
      categorias: [],    // Aquí almacenaremos las categorías y sus opciones
      respuestas: [],
      nombres_categorias: [],
      FormDetallesExamen: {
        data: [],
        totalRows: 0,
        respuestaTipo1: [],
        respuestaTipo2: [],
        respuestaTipo3: [],
        fields: [
          {label: 'Descripcion', key: 'descripcion', class: 'text-left'},
          {label: 'Concepto', key: 'concepto', class: 'text-left'},
        ],
        currentPage: 1,
        perPage: 20,
        filter: null,
        filterOn: [],
        dataOpcionesCategoria: [],
        opcionesReactivoCategorizacion: []
      },
      list1: [
        {name: "John", id: 1, idColumna: 1},
        {name: "Joao", id: 2, idColumna: 1},
        {name: "Jean", id: 3, idColumna: 1},
        {name: "Gerard", id: 4, idColumna: 1}
      ],
      list2: [
        {name: "Juan", id: 5, idColumna: 2},
        {name: "Edgard", id: 6, idColumna: 2},
        {name: "Johnson", id: 7, idColumna: 2}
      ],
      FormExamen: {
        titulo: null,
        instrucciones: '',
        valorExamen: 0,
        tiempo_minutos_responder: 0,
        uuid_examen: "4e589385-548a-454d-aa3e-b18b2fb7a591", //4e589385-548a-454d-aa3e-b18b2fb7a591	test examen 6 tipos de reactivos
        id: 0,
      },
      FormPreguntas: {
        data: [], //resultSetPreguntas: [],
        totalRows: 0,      //totalRowsPreguntas: 1,
        fields: [
          {label: 'Pregunta', key: 'descripcion', class: 'text-left', sortable: true},
          {label: 'Tipo', key: 'tiporeactivo_id', class: 'text-center', sortable: true},
          {label: 'Acciones', key: 'actions', class: 'text-center'}
        ],
        currentPage: 1,
        perPage: 10,
        filter: null,
        filterOn: [],
      },
    };
  },
  computed: {
    categoriasFiltradas() {
      return this.categoriasArray.filter(categoria => categoria !== null);
    }

  },
  mounted() {
    this.getExamenAvanza()

  },
  methods: {
    toggleSelection(columnId, opcionId) {
      if (!this.respuestas[columnId]) {
        this.$set(this.respuestas, columnId, []);
      }

      const respuestaIndex = this.respuestas[columnId].findIndex(
          (respuesta) => respuesta.uuid_opcion === opcionId
      );

      if (respuestaIndex !== -1) {
        this.respuestas[columnId].splice(respuestaIndex, 1);
      } else {
        this.respuestas[columnId].push({
          uuid_opcion: opcionId,
          selected: true,
        });
      }
      console.log("respuestas aqui--->>")
      console.log(this.respuestas)
    },
    log: function (evt) {
      window.console.log(evt);
      /*
            console.log(this.respuestas)*/
    },
    async getPreguntas() {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      this.FormPreguntas.data = []
      const response = await PreguntaService.getPreguntasDetallesExamen(this.FormExamen.uuid_examen);
      this.modalConfirmacion = false
      this.FormPreguntas.data = response.data.preguntas;
      this.FormPreguntas.totalRows = response.data.cantidadPreguntas;
      this.disabledTabPreguntas = false
    },
    async getExamenAvanza() {
      this.FormDetallesExamen.data = []
      const response = await ExamenService.getExamenAvanza(this.FormExamen.uuid_examen);
      let {
        success,
        data,
        totalRows
      } = response.data;
      if (success) {
        this.FormDetallesExamen.data = data
        this.FormDetallesExamen.totalRows = totalRows
        this.FormDetallesExamen.data.forEach((item) => {
         if (item.preguntas.tiporeactivo_id === 5) {
           let idPregunta = item.preguntas.id
            this.nombres_categorias = []
            this.categoriasArray = []
            this.opcionesArray = []

            item.preguntas.categorias.forEach((item2) => {
              this.nombres_categorias.push({
                id: idPregunta,
                name: item2.categoria,
                uuid_categoria: item2.uuid_categoria,
                items: []
              });

              item2.opciones.forEach((item3) => {
                this.opcionesArray.push(item3);
              });
            });

            this.categoriasArray.push({
              id: idPregunta,
              name: "Conceptos a ubicar",
              uuid_categoria: 0,
              items: this.opcionesArray
            });
            this.nombres_categorias.forEach((obj) => {
              this.categoriasArray.push({
                name: obj.name,
                uuid_categoria: obj.uuid_categoria,
                items: obj.items
              });
            });
           this.categorias.push({
             idPregunta: item.preguntas.id,
             items:this.categoriasArray
           });

          }
        });
        console.log("CATEGORIAS")
        console.log(this.categorias)
      }
    },
    actualizarIndexElemento() {

    },
    respuestaSeleccionada(respuestaUUID) {
      console.log(respuestaUUID)
    },
    respuestaTipo2Seleccionada(respuestaUUID) {
      console.log(respuestaUUID)
    },
    respuestaTipo3Seleccionada(respuestaUUID) {
      console.log(respuestaUUID)
    },
    respuestaTipo6Seleccionada(respuestaUUID) {
      //falso o verdadero
      console.log(respuestaUUID)
    },
    log1: function (evt) {
      this.updateIdColumn(evt, 1);
    },
    log2: function (evt) {
      this.updateIdColumn(evt, 2);
    },
    updateIdColumn: function (evt, columnIndex) {
      /*      evt.draggedContext.element.idColumna = columnIndex;
            console.log(this.list1);
            console.log(this.list2);*/
      console.log(evt)
      console.log(columnIndex)
    }
  }
};
</script>
