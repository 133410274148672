<template>
  <b-container fluid>
    <b-alert  show>
      <b-spinner small type="grow"></b-spinner>
      <h4>¡Acceso no autorizado!. No tiene asignado un examen.</h4>
    </b-alert>
  </b-container>
</template>
<script>

export default {
  components: {
    //Notificacion
  },
  data() {
    return {

    }
  },
  mounted() {

  },

  computed: {

  },
  methods:
      {
      }
}


</script>
<style>
/*@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,500;1,900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,400;0,500;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,400;0,500;1,900&family=Sanchez&display=swap');

.b-form-radio + .b-form-radio {
  margin-top: 1rem;
}

.espacio-contador {
  display: block;
}

.container-contador-home {
  position: relative;
}

.container-contador {
  position: fixed;
  z-index: 9999;
  padding: 10px;
  top: 6.9vh;
  right: 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  padding: 100px;
}

.fixed-div {
  position: fixed;
  top: 75px;
  left: 250px;
  background-color: red;
  padding: 10px;

}
.fixedEdit-div {
  position: fixed;
  top: 75px;
  left: 250px;

  padding: 10px;

}
</style>