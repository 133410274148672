<template>
  <b-container fluid>

    <b-modal id="modalVerImagenRespuesta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="ModalCrearSesion"
             title="Ver Imagen de Respuesta"
             v-model="modalZoomImagenRespuesta">
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row>
            <b-img rounded :src="url_imagenRespuesta"

                   fluid
                   v-if="imagenNuevaRespuesta != null && editarImagenRespuesta===true">
            </b-img>
          </b-row>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalZoomImagenRespuesta=false"

              >
                Cerrar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalAgregarImagenRespuesta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalImagenRespuesta"
             title="Agregar archivo de Imagen a Respuesta"
             v-model="modalImagenRespuesta">
      <b-alert v-model="modalConfirmacionImagenRespuesta" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionImagenRespuesta"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="primary" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          A continuación seleccione la imagen
        </b-alert>
        <b-card-text>
          <b-row>
            <b-form-file class="my-3"
                         size="sm"
                         v-model="imagenNuevaRespuesta"
                         placeholder="Archivo adjunto..."
                         accept="image/*"
                         drop-placeholder="Arrastrar un archivo para adjuntar."
            ></b-form-file>


          </b-row>
          <b-row>
            <div id="image-containerRespuesta" @click="zoomImageRespuesta">
              <b-img rounded :src="url_imagenRespuesta"
                     height="200"
                     width="200"
                     fluid
                     v-if="editarImagenRespuesta===true"
                     title="Clic para ampliar imagen"
              >
              </b-img>
            </div>
          </b-row>
          <b-row>
            <b-button variant="danger" v-b-modal.modal-prevent-closing size="sm"
                      @click="abrirModalEliminarImagenRespuesta()" class="mt-2"
                      title="Eliminar imagen respuesta"
                      v-if="editarImagenRespuesta===true"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-trash2-fill" viewBox="0 0 16 16">
                <path
                    d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
              </svg>
            </b-button>
          </b-row>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarImagenRespuesta()"
                  title="Guarda archivo"
                  :disabled="disabledBtnGuardarImagenRespuesta"
              >
                {{ textobtnGuardarImagenRespuesta }}
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar "
                  @click="modalImagenRespuesta=false"
                  :disabled="disabledBtnCancelar"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalEliminarImagenRespuesta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalEliminarRespuesta"
             title="Confirmar eliminar imagen de respuesta"
             v-model="modalEliminarImagenRespuesta"
    >
      <b-alert v-model="modalConfirmacionEliminarImagenRespuesta" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionEliminarImagenRespuesta"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea eliminar la imagen?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarImagenRespuesta()"
                  title="Eliminar imagen"
                  :disabled="disabledBtnsEliminarRespuesta"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalEliminarImagenRespuesta=false"
                  :disabled="disabledBtnsEliminarRespuesta"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalConfirmarEliminarRespuesta" no-close-on-backdrop no-close-on-esc hide-header-close centered
             size="lg"
             hide-footer persistent
             ref="modalEliminarRespuesta"
             title="Confirmar eliminar respuesta"
             v-model="modalEliminarRespuesta"
    >
      <b-alert v-model="modalConfirmacionRespuesta" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionRespuesta"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea eliminar la respuesta seleccionada?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarRespuesta()"
                  title="Borrar respuesta"
                  :disabled="disabledBtnsEliminarRespuesta"

              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalEliminarRespuesta=false"
                  :disabled="disabledBtnsEliminarRespuesta"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalAgregarAudioPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="ModalAgregarAudio"
             title="Agregar archivo de Audio a Pregunta"
             v-model="modalAudio"
    >
      <b-alert v-model="modalConfirmacionAudio" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionAudio"/>

      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="primary" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          A continuación seleccione el archivo de audio
        </b-alert>
        <b-card-text>
          <b-row>
            <div v-if="editarAudio && audio != '#' "
                 style="display: flex; justify-content: space-evenly; align-items:center" class="my-2">
              <b-button @click="reemplazarAudio()" variant="outline-primary" class="btn-studioAPP" sm>Reemplazar audio
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg"
                     viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                  <path fill-rule="evenodd"
                        d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                </svg>
              </b-button>
            </div>
            <div v-else>
              <b-row>
                <b-form-file class="my-3"
                             size="sm"
                             v-model="audioNuevo"
                             :state="Boolean(audioNuevo)"
                             placeholder="Archivo adjunto..."
                             accept="audio/mp3"
                             drop-placeholder="Arrastrar un archivo para adjuntar."
                             :filter="filterAudioFile"
                ></b-form-file>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div id="audioModalAgregar" v-if="enabledToolbarAudio===true">
                    <!--INICIO DE BARRA DE PROGRESO-->
                    <div class="wrapper" id="appModalAudio">
                      <!--                  <div class="player">-->

                      <div class="progress" ref="progress">
                        <div class="progress__top">
                          <div class="progress__duration">{{ duration }}</div>
                        </div>
                        <div class="progress__bar" @click="clickProgress">
                          <div class="progress__current" :style="{ width : barWidth }"></div>
                        </div>
                        <div class="progress__time">{{ currentTime }}</div>
                      </div>
                      <!--                  </div>-->
                    </div>

                    <!--FIN    DE BARRA DE PROGRESO-->
                    <!-- controles de audio -->
                    <div id="controlesModalAudio" style="margin-top: 5px">
                      <b-button-toolbar key-nav aria-label="Toolbar with button groups"
                                        v-if="enabledToolbarAudio===true">
                        <b-button-group class="mx-1">
                          <b-button
                              class="btn-studioAPP"
                              variant="outline-warning"
                              size="sm"
                              @click="play()"
                              title="Reproducir audio"

                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#952f81"
                                 class="bi bi-play-btn"
                                 viewBox="0 0 16 16" v-if="audio_play==1">
                              <path
                                  d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                              <path
                                  d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#952f81"
                                 class="bi bi-play-btn"
                                 viewBox="0 0 16 16" v-if="audio_play==0">
                              <path
                                  d="M6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z"/>
                              <path
                                  d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                            </svg>
                          </b-button>

                          <b-button
                              class="btn-studioAPP"
                              variant="outline-warning"
                              size="sm"
                              @click="retrocederAudio()"
                              title="Regresar 10 seg."
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                 class="bi bi-trash2-fill" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                    d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                              <path
                                  d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                            </svg>
                          </b-button>
                          <b-button
                              class="btn-studioAPP"
                              variant="outline-warning"
                              size="sm"
                              @click="adelantarAudio()"
                              title="Adelantar 10 seg."
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                 class="bi bi-trash2-fill" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                              <path
                                  d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                            </svg>
                          </b-button>

                          <b-button
                              class="btn-studioAPP"
                              variant="outline-warning"
                              size="sm"
                              @click="subirAudio()"
                              title="Subir volumen"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                 class="bi bi-trash2-fill" viewBox="0 0 16 16">
                              <path
                                  d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
                              <path
                                  d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
                              <path
                                  d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z"/>
                            </svg>
                          </b-button>

                          <b-button
                              class="btn-studioAPP"
                              variant="outline-warning"
                              size="sm"
                              @click="bajarAudio()"
                              title="Bajar volumen"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                 class="bi bi-trash2-fill" viewBox="0 0 16 16">
                              <path
                                  d="M9 4a.5.5 0 0 0-.812-.39L5.825 5.5H3.5A.5.5 0 0 0 3 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 9 12V4zM6.312 6.39 8 5.04v5.92L6.312 9.61A.5.5 0 0 0 6 9.5H4v-3h2a.5.5 0 0 0 .312-.11zM12.025 8a4.486 4.486 0 0 1-1.318 3.182L10 10.475A3.489 3.489 0 0 0 11.025 8 3.49 3.49 0 0 0 10 5.525l.707-.707A4.486 4.486 0 0 1 12.025 8z"/>
                            </svg>
                          </b-button>

                          <b-button
                              class="btn-studioAPP"
                              variant="outline-warning"
                              size="sm"
                              @click="modalEliminarAudio=true"
                              title="Eliminar archivo de audio"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B2007B"
                                 class="bi bi-trash2-fill" viewBox="0 0 16 16">
                              <path
                                  d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                            </svg>
                          </b-button>
                        </b-button-group>
                      </b-button-toolbar>
                    </div>
                  </div>
                  <!-- fin controles de audio -->
                </b-col>
              </b-row>
            </div>


          </b-row>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarAudio()"
                  title="Guarda archivo"
                  :disabled="disabledBtnGuardarAudio"
              >
                {{ textobtnGuardarAudio }}
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar "
                  @click="modalAudio=false"
                  :disabled="disabledBtnCancelar"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalConfirmarEliminarAudioPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered
             size="lg"
             hide-footer persistent
             ref="modalEliminarAudio"
             title="Confirmar eliminar audio de Pregunta"
             v-model="modalEliminarAudio"
    >
      <b-alert v-model="modalConfirmacionAudio" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionAudio"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea eliminar el archivo de audio seleccionado?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarArchivoAudio()"
                  title="Borrar archivo"
                  :disabled="disabledBtnsEliminarAudio"

              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalEliminarAudio=false"
                  :disabled="disabledBtnsEliminarAudio"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalVerImagenPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="ModalCrearSesion"
             title="Ver Imagen de Pregunta"
             v-model="modalZoomImagen">
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>
          <b-row>
            <b-img rounded :src="url_imagen"

                   fluid
                   v-if="imagenNueva != null && editarImagen===true">
            </b-img>
          </b-row>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalZoomImagen=false"
              >
                Cerrar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalAgregarImagenPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="ModalCrearSesion"
             title="Agregar archivo de Imagen a Pregunta"
             v-model="modalImagen">
      <b-alert v-model="modalConfirmacionImagen" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionImagen"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="primary" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          A continuación seleccione la imagen
        </b-alert>
        <b-card-text>
          <b-row>
            <b-form-file class="my-3"
                         size="sm"
                         v-model="imagenNueva"

                         placeholder="Archivo adjunto..."
                         accept="image/*"
                         drop-placeholder="Arrastrar un archivo para adjuntar."
            ></b-form-file>
          </b-row>
          <b-row>
            <div id="image-container" @click="zoomImage">
              <b-img rounded :src="url_imagen"
                     height="200"
                     width="200"
                     fluid
                     v-if="imagenNueva != null && editarImagen===true"
                     title="Clic para ampliar imagen"
              >
              </b-img>
            </div>
          </b-row>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarImagen()"
                  title="Guarda archivo"
                  :disabled="disabledBtnGuardarImagen"
              >
                {{ textobtnGuardarImagen }}
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar "
                  @click="modalImagen=false"
                  :disabled="disabledBtnCancelar"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalConfirmarEliminarImagenPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered
             size="lg"
             hide-footer persistent
             ref="modalEliminarImagen"
             title="Confirmar eliminar imagen de Pregunta"
             v-model="modalEliminarImagen"
    >
      <b-alert v-model="modalConfirmacionImagen" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionImagen"/>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea eliminar el archivo de imagen seleccionado?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="eliminarArchivoImagen()"
                  title="Borrar archivo"
                  :disabled="disabledBtnsEliminarImagen"
              >
                Sí
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalEliminarImagen=false"
                  :disabled="disabledBtnsEliminarImagen"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-modal id="modalAgregarVideoPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalConfirmacionVideo"
             title="Agregar video Youtube a Pregunta"
             v-model="modalYoutube"
    >
      <!--ventana confirmacion -->
      <b-alert v-model="modalConfirmacionVideo" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <!--cerrar ventana confirmacion -->
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="primary" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          A continuación seleccione el Video Youtube que desea anexar a la pregunta
        </b-alert>
        <b-card-text>
          <b-row>
            <div class="col-sm-12">
              <label> <small> Seleccionar video de Youtube</small></label>
              <b-form-select
                  v-model="idVideoSelected"
                  :options="youtubeItems"
                  @change="optionKeyFunction"
                  return object
              >
              </b-form-select>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm-12">
              <youtube
                  :video-id="idVideoSelected"
                  @ready="ready"
                  @playing="playing"
                  :player-vars="{ rel: 0, showinfo: 0, autoplay: 0 }"
                  player-width="100%"
                  player-height="400">
              </youtube>
            </div>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarVideoYoutube()"
                  title="Guarda Video"
                  :disabled="disabledBtnGuardarVideo"
              >
                {{ textobtnGuardarVideo }}
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar "
                  @click="modalYoutube=false"
                  :disabled="disabledBtnCancelar"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

    <b-row id="dashBoardGeneral">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body">
            <b-alert
                show
                variant="primary"
            >
              Crear Examen
            </b-alert>
            <!--ventana confirmacion general-->
            <b-alert id="alertModalConfirmacionGeneral" v-model="modalConfirmacion" variant="light" show>
              <b-button variant="primary" disabled>
                <b-spinner small type="grow"></b-spinner>
                {{ mensajeConfirmacion }}
              </b-button>
            </b-alert>
            <Notificacion id="notificacionGeneral" ref="notificacion"/>
            <!--cerrar ventana confirmacion general-->
          </div>
          <div class="col-sm-12">
            <div>
              <b-card no-body>
                <b-tabs>
                  <!--INICIO TAB1-->
                  <b-tab id="tabExamen" title="Examen" active>
                    <b-card-text>
                      <b-form>
                        <b-row class="my-1">
                          <b-col cols="2">
                            <label for="input-titulo">Titulo:</label>
                          </b-col>
                          <b-col cols="10">
                            <b-form-input
                                v-model="FormExamen.titulo"
                                id="FormExamenTitulo"
                                ref="FormExamenTitulo"
                                placeholder="Captura el título del examen"
                            ></b-form-input>
                          </b-col>
                        </b-row>


                        <b-row class="my-1">
                          <b-col cols="2">
                            <label for="input-instrucciones">Instrucciones:</label>
                          </b-col>
                          <b-col cols="10">
                            <div id="editor1Instrucciones">
                              <!--                              <vue-editor v-model="instrucciones"
                                                                        ref="instrucciones"
                                                                        id="editor1"
                                                                        :editorToolbar="customToolbar"
                                                                        placeholder="Ingresa las instrucciones para el examen (opcional)"
                                                                        :disabled="disabledBtnGral"
                                                            >
                                                            </vue-editor>-->
                              <b-form-textarea
                                  id="ForExamenInstrucciones"
                                  ref="ForExamenInstrucciones"
                                  v-model="FormExamen.instrucciones"
                                  placeholder="Ingresa las instrucciones para el examen (opcional)"
                                  rows="1"
                                  max-rows="1"
                              ></b-form-textarea>
                            </div>
                          </b-col>
                        </b-row>


                        <b-row class="my-1">
                          <b-col cols="2">
                            <label for="input-valor-examen">
                              Calificación examen: <span :style="{ fontWeight: 'bold' }">{{
                                FormExamen.valorExamen
                              }}</span>
                            </label>
                          </b-col>
                          <b-col cols="10">
                            <!--                            <b-form-input v-model="valorExamen" id="input-valor-examen" placeholder="Captura la calificacion del examen"></b-form-input>-->
                            <b-form-input id="FormExamenvalorExamen"
                                          v-model="FormExamen.valorExamen" type="range" min="0" max="100"

                            ></b-form-input>
                          </b-col>
                        </b-row>


                        <b-row class="my-1">
                          <b-col cols="2">
                            <label for="input-tiempo_minutos_responder">
                              Duración examen:
                              <span :style="{ fontWeight: 'bold'}">
                                {{ FormExamen.tiempo_minutos_responder }} min.
                              </span>
                            </label>
                          </b-col>
                          <b-col cols="10">
                            <!--                            <b-form-input v-model="valorExamen" id="input-valor-examen" placeholder="Captura la calificacion del examen"></b-form-input>-->
                            <b-form-input id="tiempo_minutos_responder"
                                          v-model="FormExamen.tiempo_minutos_responder" type="range" min="0" max="360"

                            ></b-form-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col cols="12">
                            <b-button
                                variant="primary"
                                size="sm"
                                class="float-right mx-2 my-1"
                                @click="guardarExamen()"
                                title="Guardar Examen"

                            >
                              {{ textobtnGuardarExamen }}
                            </b-button>
                            <b-button
                                variant="success"
                                size="sm"
                                class="float-right mx-2 my-1"
                                @click="nuevoExamen()"
                                title="Nuevo Examen"
                            >
                              Nuevo Examen
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>
                  </b-tab>
                  <!--FIN TAB1-->
                  <!--INICIO TAB2-->
                  <b-tab id="tabPreguntas" title="Preguntas" :disabled="disabledTabPreguntas">
                    <b-row>
                      <b-col cols="12">
                        <b-form-input
                            id="filter-input"
                            v-model="FormPreguntas.filter"
                            type="search"
                            size="sm"
                            placeholder="Buscar"
                        >
                        </b-form-input>
                        <b-table
                            :items="FormPreguntas.data"
                            :fields="FormPreguntas.fields"
                            :current-page="FormPreguntas.currentPage"
                            :per-page="FormPreguntas.perPage"
                            :filter="FormPreguntas.filter"
                            :filter-included-fields="FormPreguntas.filterOn"
                            hover
                            show-empty
                            small
                            responsive="sm"
                        >
                          <template #empty="scope">
                            <h5 class="text-center">
                              {{ scope.emptyText = 'Por el momento no hay preguntas que mostrar.' }}
                            </h5>
                          </template>
                          <template #emptyfiltered="scope">
                            <h5 class="text-center">
                              {{
                                scope.emptyFilteredText = 'No hay preguntas que coincidan con los parámetros de búsqueda.'
                              }}
                            </h5>
                          </template>
                          <template #cell(descripcion)="row">
                            <div v-html="row.value"></div>
                          </template>
                          <template #cell(tiporeactivo_id)="row">
                            <div v-if="row.item.tiporeactivo_id===1">ROM única respuesta</div>
                            <div v-if="row.item.tiporeactivo_id===2">ROM varias respuestas</div>
                            <div v-if="row.item.tiporeactivo_id===3">Relacionar columnas</div>
                            <div v-if="row.item.tiporeactivo_id===4">Ordenamiento</div>
                            <div v-if="row.item.tiporeactivo_id===5">Categorización</div>
                            <div v-if="row.item.tiporeactivo_id===6">Falso o Verdadero</div>
                          </template>
                          <template #cell(actions)="row">
                            <div class="d-flex justify-content-around align-items-center">
                              <b-button
                                  variant="primary"
                                  v-b-modal.modal-prevent-closing
                                  size="sm"
                                  @click="agregarPreguntasExamen(FormExamen.uuid_examen,row.item)"
                                  class="mr-1"
                                  title="Agregar pregunta a examen">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     class="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="success"
                                       class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                          d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                    <path
                                        d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                                  </svg>
                                </svg>
                              </b-button>
                            </div>
                          </template>
                        </b-table>
                        <div class="d-flex justify-content-between">
                          <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">

                            <b-pagination
                                v-model="FormPreguntas.currentPage"
                                :total-rows="FormPreguntas.totalRows"
                                :per-page="FormPreguntas.perPage"
                                align="fill"
                                size="sm"
                            >
                            </b-pagination>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <!--FIN TAB2-->

                  <!--INICIO TAB3-->
                  <b-tab id="tabVistaPrevia" title="Vista Previa" >
                    <div id="examen" v-for="(question, index) in FormDetallesExamen.data" :key="index" class="mb-4">
                      <b-card v-if="question.preguntas.tiporeactivo_id===1">
                        <div v-html="question.preguntas.descripcion"
                             style="font-family: 'Roboto', sans-serif;font-size: 16px;line-height: 1.5;"></div>
                        <b-form-radio-group
                            :options="question.preguntas.opciones_respuesta"
                            html-field="respuesta"
                            value-field="uuid_respuesta"
                            v-model="FormDetallesExamen.respuestaTipo1[`${question.preguntas.opciones_respuesta.uuid_pregunta_fk}`]"
                            @change="respuestaSeleccionada"
                            stacked
                        >
                        </b-form-radio-group>
                      </b-card>

                      <b-card v-if="question.preguntas.tiporeactivo_id===2">
                        <div v-html="question.preguntas.descripcion" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
                        <b-form-checkbox-group
                            :options="question.preguntas.opciones_respuesta"
                            html-field="respuesta"
                            value-field="uuid_respuesta"
                            v-model="FormDetallesExamen.respuestaTipo2[`${question.preguntas.opciones_respuesta.uuid_pregunta_fk}`]"
                            @change="respuestaTipo2Seleccionada"
                            class="mb-2"
                            stacked
                        ></b-form-checkbox-group>
                      </b-card>

                      <b-card v-if="question.preguntas.tiporeactivo_id===3">
                        <div v-html="question.preguntas.descripcion" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
                        <b-table class="table data-table"
                                 :items="question.preguntas.reactivos_columna_a"
                                 :fields="FormDetallesExamen.fields"
                                 :current-page="FormDetallesExamen.currentPage"
                                 :per-page="FormDetallesExamen.perPage"
                                 :filter="FormDetallesExamen.filter"
                                 :filter-included-fields="FormDetallesExamen.filterOn"
                                 :striped="true"
                                 hover
                                 show-empty
                                 :small="true"
                        >
                          <template #cell(descripcion)="row">
                            <div v-html="row.item.descripcion"></div>
                          </template>
                          <template #cell(concepto)="row">
                            <b-form-select
                                v-model="FormDetallesExamen.respuestaTipo3[`${row.item.uuid_columnaA}`]"
                                :options="question.preguntas.reactivos_columna_b"
                                text-field="descripcion"
                                value-field="uuid_columnaB"
                                class="mb-3"
                                size="sm"
                                @change="respuestaTipo3Seleccionada(row)"
                            >
                              <template #first>
                                <b-form-select-option :value="null" disabled>-- Elige una opción --</b-form-select-option>
                              </template>
                            </b-form-select>
                          </template>
                        </b-table>
                      </b-card>

                      <b-card v-if="question.preguntas.tiporeactivo_id===4">
                        <div v-html="question.preguntas.descripcion" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
                        <b-row class="justify-content-end">
                          <b-col sm="12">
                            <draggable
                                @input="actualizarIndexElemento"
                                v-model="question.preguntas.conceptos_ordenamiento"
                                class="list-group"
                                ghost-class="ghost"
                                @start="dragging = true"
                                @end="dragging = false"
                            >
                              <!--                              <draggable
                                                              @input="actualizarIndexElemento"
                                                              v-model="FormOrdenamiento.list"
                                                              :disabled="FormOrdenamiento.disabled"
                                                              :move="checkMove"
                                                              class="list-group"
                                                              ghost-class="ghost"
                                                              @start="dragging = true"
                                                              @end="dragging = false"
                                                          >-->
                              <div
                                  class="list-group-item"
                                  v-for="element in question.preguntas.conceptos_ordenamiento"
                                  :key="element.uuid_concepto"
                              >
                                {{ element.concepto }}
                              </div>
                            </draggable>
                          </b-col>
                        </b-row>
                      </b-card>

                      <b-card v-if="question.preguntas.tiporeactivo_id===5">
                        <div v-html="question.preguntas.descripcion" style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
                        <b-row class="justify-content-start">
                          <div id="categoria" v-for="(data, indexData) in categorias" :key="indexData">
                            <b-col sm="12" v-if="data.idPregunta===question.preguntas.id">
                              <div id="columnasDraggables">
                                <div class="columns">
                                  <b-row>
                                    <div v-for="(column, columnIndex) in data.items" :key="columnIndex" class="column">
                                      <b-col cols="3" md="auto">
                                        <h6>{{ column.name }}</h6>

                                        <draggable class="list-group" :list="column.items" group="animals">
                                          <div
                                              class="list-group-item"
                                              v-for="(respuesta) in column.items"
                                              :key="respuesta.uuid_opcion"
                                          >
                                            {{ respuesta.opcion }}
                                          </div>
                                        </draggable>
                                      </b-col>
                                    </div>
                                  </b-row>
                                </div>
                              </div>
                            </b-col>
                          </div>
                        </b-row>
                      </b-card>


                      <b-card v-if="question.preguntas.tiporeactivo_id===6">
                        <div v-html="question.preguntas.descripcion"
                             style="font-family: 'Roboto', sans-serif;font-size: 16px;line-height: 1.5;"></div>
                        <b-form-radio-group
                            :options="question.preguntas.opciones_respuesta"
                            html-field="respuesta"
                            value-field="uuid_respuesta"
                            v-model="FormDetallesExamen.respuestaTipo1[`${question.preguntas.opciones_respuesta.uuid_pregunta_fk}`]"
                            @change="respuestaSeleccionada"
                            stacked
                        >
                        </b-form-radio-group>
                      </b-card>
                    </div>
                  </b-tab>
                  <!--FIN TAB3-->
                </b-tabs>
              </b-card>
              <!--FIN DEL CUERPO TABS-->
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

  </b-container>
</template>
/* eslint-disable vue/no-unused-vars */
<script>
import toast from "@/mixins/ToastMixin.js";
import PreguntaService from "@/services/PreguntaService";
import YoutubeService from "@/services/Youtube/YoutubeService";
import moment from "moment"
import 'moment/locale/es';
import {getIdFromURL} from 'vue-youtube-embed';
import Notificacion from '@/components/Notificacion.vue'
//import {VueEditor} from "vue2-editor";
import ExamenService from "@/services/ExamenService";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
    Notificacion
    //VueEditor
  },
  mixins: [toast],
  data() {
    return {
      categoriasArray:[],
      opcionesArray: [], // Aquí almacenaremos los elementos de opciones
      categorias: [],    // Aquí almacenaremos las categorías y sus opciones
      respuestas: [],
      nombres_categorias: [],
      FormDetallesExamen: {
        data: [],
        totalRows: 0,
        respuestaTipo1:[],
        respuestaTipo2: [],
        respuestaTipo3: [],
        fields: [
          {label: 'Descripcion', key: 'descripcion', class: 'text-left'},
          {label: 'Concepto', key: 'concepto', class: 'text-left'},
        ],
        currentPage: 1,
        perPage: 20,
        filter: null,
        filterOn: [],
        dataOpcionesCategoria:[]
      },
      FormExamen: {
        titulo: null,
        instrucciones: '',
        valorExamen: 0,
        tiempo_minutos_responder: 0,
        uuid_examen: 0,
        id: 0,
      },
      FormPreguntas: {
        data: [], //resultSetPreguntas: [],
        totalRows: 0,      //totalRowsPreguntas: 1,
        fields: [
          {label: 'Pregunta', key: 'descripcion', class: 'text-left',sortable: true},
          {label: 'Tipo', key: 'tiporeactivo_id', class: 'text-center',sortable: true},
          {label: 'Acciones', key: 'actions', class: 'text-center'}
        ],
        currentPage: 1,
        perPage: 10,
        filter: null,
        filterOn: [],
      },


      textobtnGuardarExamen: "Guardar Examen",
      disabledBtnGral: true,
      disabledTabPreguntas: true,
      disabledTabVistaPrevia: true,


      primer_guardado_pregunta: 0,
      contenidoPreguntaOriginal: null,

      //fin variables pregunta
      /*variables Generales*/
      disabledBtnCancelar: false,
      /*VARIABLES DE BARRA DE PROGRESO*/
      barWidth: null,
      duration: null,
      currentTime: null,
      isTimerPlaying: false,

      /*VARIABLES VIDEO*/
      textobtnGuardarVideo: "Guardar video",
      modalConfirmacionVideo: false,
      disabledBtnGuardarVideo: true,
      modalYoutube: false,
      video_save: 0,
      mensajeConfirmacion: null,
      modalConfirmacion: false,

      disabledBarraMultimedia: true,


      /* activar desactivar botones, textbox,textarea */
      visibleBtnDelVideo: true,


      tituloVideoYoutube: null,
      userSelectedVideo: 0,
      idVideoSelected: null,
      youtubeItems: [],
      youtubeItems_count: 0,


      //VARIABLES PARA IMAGEN
      disabledBtnsEliminarImagen: false,
      modalEliminarImagen: false,
      textobtnGuardarImagen: "Guardar archivo",
      disabledBtnGuardarImagen: false,
      modalConfirmacionImagen: false,
      primer_guardado_nameFileImagen: 0,
      nombre_archivo_original_imagen: null,
      url_imagen: null,
      imagenNueva: null,
      imagen: null,
      editarImagen: false,
      enabledToolbarImagen: false,
      modalZoomImagen: false,
      modalImagen: false,
      objImagen: null,
      //FIN VARIABLES PARA IMAGEN

      //VARIABLES PARA AUDIO
      disabledBtnsEliminarAudio: false,
      modalAudio: false,
      modalEliminarAudio: false,
      textobtnGuardarAudio: "Guardar archivo",
      disabledBtnGuardarAudio: false,
      primer_guardado_nameFileAudio: 0,
      nombre_archivo_original_audio: null,
      enabledToolbarAudio: false,
      modalConfirmacionAudio: false,
      audio_play: null,
      objAudio: null,
      audioNuevo: null,
      audio: null,
      editarAudio: false,
      url_audio: null,
      //fin vars para audio


      //variables RESPUESTA
      modalConfirmacionEliminarImagenRespuesta: false,
      modalEliminarImagenRespuesta: false,
      objImagenRespuesta: null,
      modalZoomImagenRespuesta: false,
      primer_guardado_nameFileImagenRespuesta: 0,
      nombre_archivo_original_imagenRespuesta: null,
      editarImagenRespuesta: false,
      disabledBarraMultimediaRespuesta: true,
      textobtnGuardarImagenRespuesta: "Guardar archivo",
      disabledBtnGuardarImagenRespuesta: false,
      url_imagenRespuesta: null,
      imagenNuevaRespuesta: null,
      modalImagenRespuesta: false,
      modalConfirmacionImagenRespuesta: false,

      maximoRespuestasOkPermitidas: 1,
      totalRespuestasOk: 0,
      respuesta: null,
      resultSetRespuestas: [],
      disabledBtnGuardarRespuesta: true,
      disabledBtnNuevaRespuesta: true,
      textobtnGuardarRespuesta: "Guardar Respuesta",
      disabledRespuesta: true,
      uuid_respuesta: 0,
      modalEliminarRespuesta: false,
      modalConfirmacionRespuesta: false,
      disabledBtnsEliminarRespuesta: false,
      //fin variables RESPUESTA

      filter: null,
      filterOn: [],
      resultSet: [],
      search: '',
      nombre_evento: null,
      numero_cuenta_pago: null,

      totalRows2: 1,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter2: null,
      filterOn2: [],
      resultSet2: [],
      search2: '',

      customToolbar:
          [
            [{header: [false, 1, 2, 3, 4, 5, 6]}],
            ["bold", "italic", "underline", "strike"],
            [{color: []}],
            [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
            [
              {align: ""},
              {align: "center"},
              {align: "right"},
              {align: "justify"}
            ],
            [{indent: "-1"}, {indent: "+1"}],

            ["clean"]
          ],

    }
  },
  created() {
    this.objAudio = new Audio();
  },

  methods: {
    actualizarIndexElemento(){

    },
    respuestaSeleccionada(respuestaUUID){
      console.log(respuestaUUID)
    },
    respuestaTipo2Seleccionada(respuestaUUID){
      console.log(respuestaUUID)
    },
    respuestaTipo3Seleccionada(respuestaUUID){
      console.log(respuestaUUID)
    },
    respuestaTipo6Seleccionada(respuestaUUID){
      //falso o verdadero
      console.log(respuestaUUID)
    },

    async agregarPreguntasExamen(uuid_examen, pregunta) {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      let formData = new FormData();
      formData.append("uuid_examen", uuid_examen);
      formData.append("uuid_pregunta", pregunta.uuid_pregunta);
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      this.disabledBtnGuardarPregunta = true
      const responsePreguntas = await ExamenService.storePreguntaExamen(formData);
      let {
        success,
        color,
        message,
        data,
        totalRows
      } = responsePreguntas.data;
      if (success) {
        await this.getExamenAvanza()
        this.modalConfirmacion = false
        this.FormPreguntas.data = data
        this.FormPreguntas.totalRows = totalRows
        this.showNotificacion(message, color)
      }

    },

    async getExamenAvanza() {
      this.FormDetallesExamen.data = []
      const response = await ExamenService.getExamenAvanza(this.FormExamen.uuid_examen);
      let {
        success,
        data,
        totalRows
      } = response.data;
      if(success) {
        this.FormDetallesExamen.data = data
        this.FormDetallesExamen.totalRows = totalRows
        this.FormDetallesExamen.data.forEach((item) => {
          if (item.preguntas.tiporeactivo_id === 5) {
            let idPregunta = item.preguntas.id
            this.nombres_categorias = []
            this.categoriasArray = []
            this.opcionesArray = []

            item.preguntas.categorias.forEach((item2) => {
              this.nombres_categorias.push({
                id: idPregunta,
                name: item2.categoria,
                uuid_categoria: item2.uuid_categoria,
                items: []
              });

              item2.opciones.forEach((item3) => {
                this.opcionesArray.push(item3);
              });
            });

            this.categoriasArray.push({
              id: idPregunta,
              name: "Conceptos a ubicar",
              uuid_categoria: 0,
              items: this.opcionesArray
            });
            this.nombres_categorias.forEach((obj) => {
              this.categoriasArray.push({
                name: obj.name,
                uuid_categoria: obj.uuid_categoria,
                items: obj.items
              });
            });
            this.categorias.push({
              idPregunta: item.preguntas.id,
              items:this.categoriasArray
            });

          }
        });
        console.log("CATEGORIAS")
        console.log(this.categorias)





      }
    },

    async getPreguntas() {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      this.FormPreguntas.data = []
      const response = await PreguntaService.getPreguntasDetallesExamen(this.FormExamen.uuid_examen);
      this.modalConfirmacion = false
      this.FormPreguntas.data = response.data.preguntas;
      this.FormPreguntas.totalRows = response.data.cantidadPreguntas;
      this.disabledTabPreguntas = false
    },
    nuevoExamen() {
      this.FormExamen.titulo = null
      this.instrucciones = null
      this.valorExamen = 0
      this.tiempo_minutos_responder = 0
      this.textobtnGuardarExamen = "Guardar Examen"
      this.disabledBtnGral = true
      this.disabledTabPreguntas = true
      this.disabledTabVistaPrevia = true

    },
    abrirModalEliminarImagenRespuesta() {
      this.modalEliminarImagenRespuesta = true
    },
    async eliminarImagenRespuesta() {
      try {
        this.disabledBtnsEliminarRespuesta = true
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionEliminarImagenRespuesta = true
        const response = await PreguntaService.deleteImagenRespuesta(this.uuid_respuesta);
        let {
          success,
          message,
          color
          , respuestas
          , cantidadRespuestas
        } = response.data;
        if (success == true) {
          //this.toast('b-toaster-bottom-center', true, message, color );
          this.editarImagenRespuesta = false
          this.primer_guardado_nameFileImagenRespuesta = 0,
              this.nombre_archivo_original_imagenRespuesta = null,
              this.showNotificacionImagenRespuesta(message, color)
          this.modalEliminarImagenRespuesta = false;
          this.modalConfirmacionEliminarImagenRespuesta = false

          this.resultSetRespuestas = []
          this.resultSetRespuestas = respuestas;
          this.FormPreguntas.totalRows = cantidadRespuestas;
          //queda pendiente eliminar fisicamente el archivo en
          //cloud storage
          //
        }
      } catch (error) {
        this.modalEliminarImagenRespuesta = false;
        this.modalConfirmacionEliminarImagenRespuesta = false
        this.showNotificacionImagenRespuesta('Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      }
    },
    async eliminarRespuesta() {
      try {
        this.disabledBtnsEliminarRespuesta = true
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionRespuesta = true
        const response = await PreguntaService.deleteRespuesta(this.uuid_respuesta);
        let {
          success,
          message,
          color
          , respuestas
          , cantidadRespuestas
        } = response.data;
        if (success == true) {
          //this.toast('b-toaster-bottom-center', true, message, color );
          this.showNotificacion(message, color)
          this.modalEliminarRespuesta = false;
          this.modalConfirmacionRespuesta = false

          this.resultSetRespuestas = []
          this.resultSetRespuestas = respuestas;
          this.FormPreguntas.totalRows = cantidadRespuestas;
          //queda pendiente eliminar fisicamente el archivo en
          //cloud storage
          //
        }
      } catch (error) {
        this.showNotificacionRespuesta('Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      }
    },
    resetVariablesImagenRespuesta() {
      this.modalZoomImagenRespuesta = false
      this.primer_guardado_nameFileImagenRespuesta = 0
      this.nombre_archivo_original_imagenRespuesta = null
      this.editarImagenRespuesta = false
      this.disabledBarraMultimediaRespuesta = true
      this.textobtnGuardarImagenRespuesta = "Guardar archivo"
      this.disabledBtnGuardarImagenRespuesta = false
      this.url_imagenRespuesta = null
      this.imagenNuevaRespuesta = null
      this.modalImagenRespuesta = false
      this.modalConfirmacionImagenRespuesta = false
    },
    nuevaRespuesta() {
      this.respuesta = null
      this.$refs.respuesta.$el.focus();
      this.disabledBtnGuardarRespuesta = true
      this.disabledBtnNuevaRespuesta = true
      this.textobtnGuardarRespuesta = "Guardar Respuesta"
      this.uuid_respuesta = 0
      //reset variables de imagen respuesta
      this.resetVariablesImagenRespuesta()
    },

    checkLengthTitulo() {
      if (this.FormExamen.titulo.length >= 5)
        this.disabledBtnGral = false
      else
        this.disabledBtnGral = true
    },

    play() {
      this.audio_play = !this.audio_play
      if (this.objAudio.paused) {
        this.objAudio.play();
        this.isTimerPlaying = true;
      } else {
        this.objAudio.pause();
        this.isTimerPlaying = false;
      }
    },
    generateTime() {
      let width = (100 / this.objAudio.duration) * this.objAudio.currentTime;
      this.barWidth = width + "%";
      //this.circleLeft = width + "%";
      let durmin = Math.floor(this.objAudio.duration / 60);
      let dursec = Math.floor(this.objAudio.duration - durmin * 60);
      let curmin = Math.floor(this.objAudio.currentTime / 60);
      let cursec = Math.floor(this.objAudio.currentTime - curmin * 60);
      if (durmin < 10) {
        durmin = "0" + durmin;
      }
      if (dursec < 10) {
        dursec = "0" + dursec;
      }
      if (curmin < 10) {
        curmin = "0" + curmin;
      }
      if (cursec < 10) {
        cursec = "0" + cursec;
      }
      this.duration = durmin + ":" + dursec;
      this.currentTime = curmin + ":" + cursec;
    },
    updateBar(x) {
      let progress = this.$refs.progress;
      let maxduration = this.objAudio.duration;
      let position = x - progress.offsetLeft;
      let percentage = (100 * position) / progress.offsetWidth;
      if (percentage > 100) {
        percentage = 100;
      }
      if (percentage < 0) {
        percentage = 0;
      }
      // this.barWidth = percentage + "%";
      // this.circleLeft = percentage + "%";
      this.objAudio.currentTime = (maxduration * percentage) / 100;
      this.objAudio.play();
    },
    zoomImage() {
      this.modalZoomImagen = true
    },
    zoomImageRespuesta() {
      this.modalZoomImagenRespuesta = true
    },
    clickProgress(e) {
      this.isTimerPlaying = true;
      this.objAudio.pause();
      this.updateBar(e.pageX);
    },
    async guardarVideoYoutube() {
      this.disabledBtnGuardarVideo = true
      this.disabledBtnCancelar = true
      let formData = new FormData();
      formData.append("video_id", this.idVideoSelected);
      formData.append("uuid_examen", this.FormExamen.uuid_examen);
      /* formData.append("imagen",this.imagenNueva);*/
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacionVideo = true
      this.disabledBtnGral = true
      const response = await PreguntaService.updateVideo(formData);
      let {
        success,
        uuid_examen,
        message,
        color
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.textobtnGuardarVideo = "Actualizar video"
        this.disabledBtnGuardarVideo = false
        this.disabledBtnCancelar = false
        this.modalConfirmacionVideo = false
        this.FormExamen.uuid_examen = uuid_examen
        this.video_save = 1
        this.showNotificacion(message, color);

      } else {
        this.showNotificacion(message, color);
      }
    },
    filterAudioFile(file) {
      return /\.mp3$/i.test(file.name);
    },
    async desactivarRespuestaCorrecta(uuid) {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      let formData = new FormData();
      formData.append("correcta", 0);
      formData.append("uuid_respuesta", uuid);
      formData.append("uuid_examen", this.FormExamen.uuid_examen);
      const response = await PreguntaService.desactivarRespuestaCorrecta(formData);
      let {
        success,
        uuid_respuesta,
        message
        , color
        , respuestas
        , cantidadRespuestas
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.resultSetRespuestas = []
        this.resultSetRespuestas = respuestas;
        this.FormPreguntas.totalRows = cantidadRespuestas;
        this.modalConfirmacion = false
        this.uuid_respuesta = uuid_respuesta
      }
      this.showNotificacion(message, color)
    },
    async saveOptionMultipleUnicaRespuesta(uuid) {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacionRespuesta = true
      this.disabledBtnsEliminarRespuesta = true
      let formData = new FormData();
      formData.append("correcta", 1);
      formData.append("uuid_respuesta", uuid);
      formData.append("uuid_examen", this.FormExamen.uuid_examen);
      const response = await PreguntaService.saveOptionMultipleUnicaRespuesta(formData);
      let {
        success,
        uuid_respuesta,
        message
        , color
        , respuestas
        , cantidadRespuestas
      } = response.data;
      this.modalConfirmacionRespuesta = false
      if (success) {
        this.resultSetRespuestas = []
        this.resultSetRespuestas = respuestas;
        this.FormPreguntas.totalRows = cantidadRespuestas;
        this.modalEliminarRespuesta = false
        this.uuid_respuesta = uuid_respuesta
      }
      this.showNotificacion(message, color)
    },


    async activarRespuestaCorrecta(uuid) {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      let formData = new FormData();
      formData.append("correcta", 1);
      formData.append("uuid_respuesta", uuid);
      formData.append("uuid_examen", this.FormExamen.uuid_examen);
      const response = await PreguntaService.activarRespuestaCorrecta(formData);
      let {
        success,
        uuid_respuesta,
        message
        , color
        , respuestas
        , cantidadRespuestas
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.resultSetRespuestas = []
        this.resultSetRespuestas = respuestas;
        this.FormPreguntas.totalRows = cantidadRespuestas;
        //this.disabledBarraMultimedia = false
        this.modalConfirmacion = false
        this.uuid_respuesta = uuid_respuesta
      }
      this.showNotificacion(message, color)
    },
    abrirModalRespuesta(rowRespuesta) {
      this.uuid_respuesta = rowRespuesta.uuid_respuesta
      this.modalEliminarRespuesta = true
      this.disabledBtnsEliminarRespuesta = false
    },
    abrirEditarImagenRespuestaRow(rowRespuesta) {
      this.uuid_respuesta = rowRespuesta.uuid_respuesta
      console.log("imagen selecccionada")
      console.log(rowRespuesta)
      this.resetVariablesImagenRespuesta()
      this.modalImagenRespuesta = true
      this.editarImagenRespuesta = true
      this.url_imagenRespuesta = rowRespuesta.url_imagen

      this.textobtnGuardarImagenRespuesta = "Actualizar archivo"
      this.btnsDisabledModalImagenRespuesta(false)
      this.disabledBarraMultimediaRespuesta = false
      this.modalConfirmacionImagenRespuesta = false
      this.nombre_archivo_original_imagenRespuesta = rowRespuesta.url_imagen
      this.primer_guardado_nameFileImagenRespuesta = 1
      this.imagenNuevaRespuesta = null
    },
    abrirModalAudio() {
      this.modalAudio = true
    },
    abrirModalImagen() {
      this.modalImagen = true
    },
    abrirModalImagenRespuesta() {
      this.modalImagenRespuesta = true
    },
    abrirModalYoutube() {
      this.modalYoutube = true
    },
    adelantarAudio() {
      this.objAudio.currentTime += 10
    },
    reemplazarAudio() {
      this.audio = '#';
      this.reemplazarAudio = true;
    },
    eliminarVideoYoutube() {

    },
    bajarAudio() {
      if (this.objAudio.volume > 0) {
        this.objAudio.volume -= 0.1
      }
    },
    async eliminarArchivoAudio() {
      try {
        this.disabledBtnsEliminarAudio = true
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionAudio = true
        const response = await PreguntaService.deleteAudio(this.FormExamen.uuid_examen);
        let {
          success,
          message,
          color
        } = response.data;
        if (success == true) {
          //this.toast('b-toaster-bottom-center', true, message, color );
          this.showNotificacion(message, color)
          this.modalEliminarAudio = false;
          this.modalAudio = false
          //TODO reset variables de audio
          this.resetVarsAudio()
          this.modalConfirmacionAudio = false
          //queda pendiente eliminar fisicamente el archivo en
          //cloud storage
          //
        }
      } catch (error) {
        this.showNotificacionAudio('Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      }
    },
    async eliminarArchivoImagen() {
      try {
        this.disabledBtnsEliminarImagen = true
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionImagen = true
        const response = await PreguntaService.deleteImagen(this.FormExamen.uuid_examen);
        let {
          success,
          message,
          color
        } = response.data;
        if (success == true) {
          //this.toast('b-toaster-bottom-center', true, message, color );
          this.showNotificacion(message, color)
          this.modalEliminarImagen = false;
          this.modalImagen = false
          //TODO reset variables de imagen
          this.resetVarsImagen()
          this.modalConfirmacionImagen = false
          //queda pendiente eliminar fisicamente el archivo en
          //cloud storage
          //
        }
      } catch (error) {
        this.showNotificacion('Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      }
    },
    btnsDisabledModalAudio(estatus) {
      this.disabledBtnGuardarAudio = estatus
      this.disabledBtnCancelar = estatus
    },
    btnsDisabledModalImagen(estatus) {
      this.disabledBtnGuardarImagen = estatus
      this.disabledBtnCancelar = estatus
    },
    btnsDisabledModalImagenRespuesta(estatus) {
      this.disabledBtnGuardarImagen = estatus
      this.disabledBtnCancelar = estatus
    },
    retrocederAudio() {
      this.objAudio.currentTime -= 10
    },
    resetVarsAudio() {
      this.modalAudio = false
      this.modalEliminarAudio = false
      this.textobtnGuardarAudio = "Guardar archivo",
          this.disabledBtnGuardarAudio = false,
          this.primer_guardado_nameFileAudio = 0
      this.nombre_archivo_original_audio = null
      this.enabledToolbarAudio = false
      this.modalConfirmacionAudio = false
      this.audio_play = null
      //this.objAudio= null
      this.audioNuevo.name = null
      this.audioNuevo = null
      this.audio = null
      this.editarAudio = false
      this.url_audio = null
    },
    resetVarsImagen() {
      this.modalEliminarImagen = false
      this.textobtnGuardarImagen = "Guardar archivo"
      this.disabledBtnGuardarImagen = false
      this.modalConfirmacionImagen = false
      this.primer_guardado_nameFileImagen = 0
      this.nombre_archivo_original_imagen = null
      this.url_imagen = null
      this.imagenNueva = null
      this.imagen = null
      this.editarImagen = false
      this.enabledToolbarImagen = false
      this.modalZoomImagen = false
      this.modalImagen = false
    },
    ready(event) {
      this.player = event.target;
    },
    playing(event) {
      console.log("playing event..." + event);
    },

    getCurrentVideoId(youtube_url) {
      return getIdFromURL(youtube_url)
    },
    optionKeyFunction() {
      this.userSelectedVideo = 1
      const videoYoutubeSelected = this.youtubeItems.find(object => object.value === this.idVideoSelected);
      this.tituloVideoYoutube = videoYoutubeSelected.label
      this.disabledBtnGuardarVideo = false
    },
    async getYoutubeVideos() {
      //segundo select
      try {
        let response = await YoutubeService.getYoutubeVideos();
        let {resultSet} = response.data;
        this.youtubeItems_count = resultSet.count;
        this.youtubeItems = resultSet.entries;
        /*console.log(this.youtubeItems_count)
        console.log(this.youtubeItems)*/
      } catch (error) {
        this.showNotificacion('Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      }
    },

    async guardarAudio() {
      let formData = new FormData();
      formData.append("uuid_examen", this.FormExamen.uuid_examen);
      formData.append("archivo_audio", this.audioNuevo);
      formData.append("nombre_archivo_audio", this.audioNuevo.name);
      if (this.audioNuevo.name != this.nombre_archivo_original_audio) {
        this.disabledBarraMultimedia = true
        this.btnsDisabledModalAudio(true)
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionAudio = true
        const response = await PreguntaService.storeFileAudio(formData);
        let {
          success,
          url_audio,
          message
        } = response.data;
        this.modalConfirmacion = false
        if (success) {
          this.enabledToolbarAudio = true
          if (this.primer_guardado_nameFileAudio === 0) {
            this.nombre_archivo_original_audio = this.audioNuevo.name
            this.primer_guardado_nameFileAudio = 1
          }
          let vm = this;
          this.url_audio = url_audio
          this.objAudio.src = this.url_audio
          this.objAudio.ontimeupdate = function () {
            vm.generateTime();
          };
          this.objAudio.onloadedmetadata = function () {
            vm.generateTime();
          };
        }
        ///this.toast('b-toaster-bottom-center', true, message, color);
        this.showNotificacionAudio(message, 'success')
        this.textobtnGuardarAudio = "Actualizar archivo"
        this.btnsDisabledModalAudio(false)
        this.audio_play = 1
        this.disabledBarraMultimedia = false
        this.modalConfirmacionAudio = false
        this.modalConfirmacion = false
      } else {
        //seleccionando el mismo archivo de audio
        this.showNotificacionAudio('¡El archivo seleccionado es el mismo que el almacenado. Favor de verificar!', 'danger')
        ///this.toast('b-toaster-bottom-center', true, '¡El archivo seleccionado es el mismo que el almacenado. Favor de verificar!', 'danger');
      }
    },
    async guardarImagen() {
      let formData = new FormData();
      formData.append("uuid_examen", this.FormExamen.uuid_examen);
      formData.append("archivo_imagen", this.imagenNueva);
      formData.append("nombre_archivo_imagen", this.imagenNueva.name);
      this.objImagen = this.imagenNueva
      if (this.imagenNueva.name != this.nombre_archivo_original_imagen) {
        this.disabledBarraMultimedia = true
        this.btnsDisabledModalImagen(true)
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacionImagen = true
        const response = await PreguntaService.storeFileImagen(formData);
        let {
          success,
          url_imagen,
          message
          , color
        } = response.data;
        this.modalConfirmacion = false
        if (success) {
          this.enabledToolbarImagen = true
          this.editarImagen = true
          this.url_imagen = url_imagen
          if (this.primer_guardado_nameFileImagen === 0) {
            this.nombre_archivo_original_imagen = this.imagenNueva.name
            this.primer_guardado_nameFileImagen = 1
          }
        }
        //this.toast('b-toaster-bottom-center', true, message, color);
        this.showNotificacionImagen(message, color)
        this.textobtnGuardarImagen = "Actualizar archivo"
        this.btnsDisabledModalImagen(false)
        this.disabledBarraMultimedia = false
        this.modalConfirmacionImagen = false
        this.modalConfirmacion = false
      } else {
        //seleccionando el mismo archivo de audio
        //this.toast('b-toaster-bottom-center', true, '¡El archivo seleccionado es el mismo que el almacenado. Favor de verificar!', 'danger');
        this.showNotificacion('¡El archivo seleccionado es el mismo que el almacenado. Favor de verificar!', 'danger')
      }

    },
    async guardarImagenRespuesta() {
      let formData = new FormData();
      formData.append("uuid_respuesta", this.uuid_respuesta);
      formData.append("uuid_examen", this.FormExamen.uuid_examen);
      formData.append("archivo_imagen", this.imagenNuevaRespuesta);
      formData.append("nombre_archivo_imagen", this.imagenNuevaRespuesta.name);
      this.objImagenRespuesta = this.imagenNuevaRespuesta
      if (this.imagenNuevaRespuesta.name != this.nombre_archivo_original_imagenRespuesta) {
        this.disabledBarraMultimediaRespuesta = true
        this.btnsDisabledModalImagenRespuesta(true)
        this.mensajeConfirmacion = "Favor de esperar, procesando informaciónxxx..."
        this.modalConfirmacionImagenRespuesta = true
        const response = await PreguntaService.storeFileImagenRespuesta(formData);
        let {
          success,
          url_imagen,
          message
          , color
          , respuestas
          , cantidadRespuestas
        } = response.data;
        this.modalConfirmacionImagenRespuesta = false
        if (success) {
          //update datagrid Respuestas
          this.resultSetRespuestas = []
          this.resultSetRespuestas = respuestas;
          this.FormPreguntas.totalRows = cantidadRespuestas;


          //this.enabledToolbarImagen = true
          this.editarImagenRespuesta = true
          this.url_imagenRespuesta = url_imagen
          this.showNotificacionImagenRespuesta(message, color)
          this.textobtnGuardarImagenRespuesta = "Actualizar archivo"
          this.btnsDisabledModalImagenRespuesta(false)
          this.disabledBarraMultimediaRespuesta = false
          this.modalConfirmacionImagenRespuesta = false
          if (this.primer_guardado_nameFileImagenRespuesta === 0) {
            this.nombre_archivo_original_imagenRespuesta = this.imagenNuevaRespuesta.name
            this.primer_guardado_nameFileImagenRespuesta = 1
          }
        }
        //this.toast('b-toaster-bottom-center', true, message, color);

        //this.modalConfirmacion = false
      } else {
        //seleccionando el mismo archivo de audio
        //this.toast('b-toaster-bottom-center', true, '¡El archivo seleccionado es el mismo que el almacenado. Favor de verificar!', 'danger');
        this.showNotificacion('¡El archivo seleccionado es el mismo que el almacenado. Favor de verificar!', 'danger')
      }

    },
    async guardarExamen() {
      if (this.FormExamen.titulo === '' || this.FormExamen.titulo === null || this.FormExamen.titulo.length === 0) {
        this.$refs.FormExamenTitulo.focus();
        this.showNotificacion('¡Debe capturar el titulo del examen !', 'danger');
        return false;
      }
      if (this.FormExamen.instrucciones === '' || this.FormExamen.instrucciones === null ||
          this.FormExamen.instrucciones.length === 0) {
        this.FormExamen.instrucciones = null
      }
      let formData = new FormData();
      formData.append("titulo", this.FormExamen.titulo);
      formData.append("instrucciones", this.FormExamen.instrucciones);
      formData.append("valorExamen", this.FormExamen.valorExamen);
      formData.append("tiempo_minutos_responder", this.FormExamen.tiempo_minutos_responder);
      formData.append("uuid_examen", this.FormExamen.uuid_examen);
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      this.disabledBtnGral = true
      const response = await ExamenService.storeExam(formData);
      let {
        success,
        uuid_examen,
        message
        , color
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        //this.getPreguntas()
        this.mensajeConfirmacion = "Favor de esperar, procesando información..."
        this.modalConfirmacion = true
        this.FormPreguntas.data = []
        const responsePreguntas = await PreguntaService.getPreguntas();
        let {
          preguntas,
          cantidadPreguntas
        } = responsePreguntas.data
        this.modalConfirmacion = false
        this.FormPreguntas.data = preguntas;
        this.FormPreguntas.totalRows = cantidadPreguntas;


        this.modalConfirmacion = false
        this.FormExamen.uuid_examen = uuid_examen
        this.disabledBtnGral = false
        this.textobtnGuardarExamen = "Actualizar examen"
        this.disabledTabPreguntas = false
      }
      this.showNotificacion(message, color)
    },

    showNotificacion(message, tipo) {
      this.$refs.notificacion.mostrar(message, tipo, 3000)
    },
    showNotificacionAudio(message, tipo) {
      this.$refs.notificacionAudio.mostrar(message, tipo, 3000)
    },
    showNotificacionRespuesta(message, tipo) {
      this.$refs.notificacionRespuesta.mostrar(message, tipo, 3000)
    },
    showNotificacionImagen(message, tipo) {
      this.$refs.notificacionImagen.mostrar(message, tipo, 3000)
    },
    showNotificacionImagenRespuesta(message, tipo) {
      this.$refs.notificacionImagenRespuesta.mostrar(message, tipo, 3000)
    },
    convertir_fecha(fechahora) {
      return moment(String(fechahora)).format('dddd DD/MM/YYYY hh:mm a')
    },

    subirAudio() {
      if (this.objAudio.volume < 1) {
        this.objAudio.volume += 0.1
      }
    },

    async getRespuestasByUuidPregunta($uuid_examen) {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacionImagen = true
      this.resultSetRespuestas = []
      const response = await PreguntaService.getRespuestasByUuidPregunta($uuid_examen);
      this.modalConfirmacion = false
      this.resultSetRespuestas = response.data.respuestas;
      this.FormPreguntas.totalRows = response.data.cantidadRespuestas;
      if (this.FormPreguntas.totalRows >= 1) {
        this.disabledBtnGuardarRespuesta = false
        this.disabledRespuesta = false
        this.textobtnGuardarRespuesta = "Actualizar Respuesta"
      } else {
        this.disabledBtnGuardarRespuesta = false
        this.disabledRespuesta = false
      }
    },

  },
  mounted() {
    //datos que se reciben desde dashboardExamenes
    /*
              id,
          tiempo_minutos_responder,
          titulo,
          uuid_examen,
          valorExamen
          instrucciones
     */
    if (this.$route.params.id != null) {
      this.FormExamen.id = this.$route.params.id
      this.FormExamen.tiempo_minutos_responder = this.$route.params.tiempo_minutos_responder
      this.FormExamen.titulo = this.$route.params.titulo
      this.FormExamen.uuid_examen = this.$route.params.uuid_examen
      this.FormExamen.valorExamen = this.$route.params.valorExamen
      if (this.$route.params.instrucciones != null) {
        this.FormExamen.instrucciones = ''
        this.disabledBtnGral = false
      }
      //llamar a la funcion q carga las preguntas
      this.getPreguntas()
      this.getExamenAvanza()
    } else {
      this.FormExamen.id = 0
      this.FormExamen.tiempo_minutos_responder = 0
      this.FormExamen.titulo = null
      this.FormExamen.uuid_examen = 0
      this.FormExamen.valorExamen = 0
      this.FormExamen.instrucciones = null
      this.disabledBtnGral = true

    }
  },

  computed: {}
}
</script>
<style lang="scss" scoped>
$studioAPPColor: #952f81;
.btn-studioAPP {
  color: $studioAPPColor !important;
  border: 1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active {
  background-color: #dadada !important;
  color: white !important;
}

.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color: #B2007B !important;
}

.alert-fixed {
  position: fixed;
  bottom: 2%;
  left: 30%;
  z-index: 9999;
  border-radius: 5px;
}


.player {
  background: #eef3f7;
  width: 310px;
  min-height: 30px;
  // box-shadow: 0px 55px 75px -10px rgba(76, 70, 124, 0.5);
  // box-shadow: 0px 55px 105px 10px rgba(76, 70, 124, 0.35);
  box-shadow: 0px 15px 35px -5px rgba(50, 88, 130, 0.32);
  border-radius: 15px;
  padding: 30px;
  @media screen and (max-width: 576px), (max-height: 500px) {
    width: 95%;
    padding: 10px;
    margin-top: 75px;
    min-height: initial;
    padding-bottom: 30px;
    max-width: 400px;
  }

  &__top {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 4;
    @media screen and (max-width: 576px), (max-height: 500px) {
      flex-wrap: wrap;
    }
  }

  &-cover {
    width: 300px;
    height: 300px;
    margin-left: -70px;
    flex-shrink: 0;
    position: relative;
    z-index: 2;
    border-radius: 15px;
    // transform: perspective(512px) translate3d(0, 0, 0);
    // transition: all .4s cubic-bezier(.125, .625, .125, .875);
    z-index: 1;

    @media screen and (max-width: 576px), (max-height: 500px) {
      margin-top: -70px;
      margin-bottom: 25px;
      width: 290px;
      height: 230px;
      margin-left: auto;
      margin-right: auto;
    }

    &__item {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      position: absolute;
      left: 0;
      top: 0;

      &:before {
        content: "";
        background: inherit;
        width: 100%;
        height: 100%;
        box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
        display: block;
        z-index: 1;
        position: absolute;
        top: 30px;
        transform: scale(0.9);
        filter: blur(10px);
        opacity: 0.9;
        border-radius: 15px;
      }

      &:after {
        content: "";
        background: inherit;
        width: 100%;
        height: 100%;
        box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
        display: block;
        z-index: 2;
        position: absolute;
        border-radius: 15px;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
      box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
      user-select: none;
      pointer-events: none;
    }
  }

  &-controls {
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 576px), (max-height: 500px) {
      flex-direction: row;
      padding-left: 0;
      width: 100%;
      flex: unset;
    }

    &__item {
      display: inline-flex;
      font-size: 30px;
      padding: 5px;
      margin-bottom: 10px;
      color: #acb8cc;
      cursor: pointer;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: all 0.3s ease-in-out;

      @media screen and (max-width: 576px), (max-height: 500px) {
        font-size: 26px;
        padding: 5px;
        margin-right: 10px;
        color: #acb8cc;
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #fff;
        transform: scale(0.5);
        opacity: 0;
        box-shadow: 0px 5px 10px 0px rgba(76, 70, 124, 0.2);
        transition: all 0.3s ease-in-out;
        transition: all 0.4s cubic-bezier(0.35, 0.57, 0.13, 0.88);
      }

      @media screen and (min-width: 500px) {
        &:hover {
          color: #532ab9;

          &::before {
            opacity: 1;
            transform: scale(1.3);
          }
        }
      }

      @media screen and (max-width: 576px), (max-height: 500px) {
        &:active {
          color: #532ab9;

          &::before {
            opacity: 1;
            transform: scale(1.3);
          }
        }
      }

      .icon {
        position: relative;
        z-index: 2;
      }

      &.-xl {
        margin-bottom: 0;
        font-size: 95px;
        // filter: drop-shadow(0 2px 8px rgba(172, 184, 204, 0.3));
        // filter: drop-shadow(0 9px 6px rgba(172, 184, 204, 0.35));
        filter: drop-shadow(0 11px 6px rgba(172, 184, 204, 0.45));
        color: #fff;
        width: auto;
        height: auto;
        display: inline-flex;
        @media screen and (max-width: 576px), (max-height: 500px) {
          margin-left: auto;
          font-size: 75px;
          margin-right: 0;
        }

        &:before {
          display: none;
        }
      }

      &.-favorite {
        &.active {
          color: red;
        }
      }
    }
  }
}

[v-cloak] {
  display: none;
}

[v-cloak] > * {
  display: none;
}

.progress {
  width: 100%;
  margin-top: -15px;
  user-select: none;

  &__top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__duration {
    margin-bottom: 6px;
    color: #71829e;
    font-weight: 700;
    font-size: 14px;
    opacity: 0.5;
  }

  &__time {
    margin-top: 6px;
    color: #71829e;
    font-weight: 700;
    font-size: 14px;
    opacity: 0.7;
  }
}

.progress__bar {
  height: 6px;
  width: 100%;
  cursor: pointer;
  background-color: #d0d8e6;
  display: inline-block;
  border-radius: 10px;
}

.progress__current {
  height: inherit;
  width: 0%;
  background-color: #a3b3ce;
  border-radius: 10px;
}

.album-info {
  color: #71829e;
  flex: 1;
  padding-right: 60px;
  user-select: none;

  @media screen and (max-width: 576px), (max-height: 500px) {
    padding-right: 30px;
  }

  &__name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
    line-height: 1.3em;
    @media screen and (max-width: 576px), (max-height: 500px) {
      font-size: 18px;
      margin-bottom: 9px;
    }
  }

  &__track {
    font-weight: 400;
    font-size: 20px;
    opacity: 0.7;
    line-height: 1.3em;
    min-height: 52px;
    @media screen and (max-width: 576px), (max-height: 500px) {
      font-size: 18px;
      min-height: 50px;
    }
  }
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  transition: all .3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }
}

//scale out

.scale-out-enter-active {
  transition: all .35s ease-in-out;
}

.scale-out-leave-active {
  transition: all .35s ease-in-out;
}

.scale-out-enter {
  transform: scale(.55);
  pointer-events: none;
  opacity: 0;
}

.scale-out-leave-to {
  transform: scale(1.2);
  pointer-events: none;
  opacity: 0;
}


//scale in

.scale-in-enter-active {
  transition: all .35s ease-in-out;
}

.scale-in-leave-active {
  transition: all .35s ease-in-out;
}

.scale-in-enter {
  transform: scale(1.2);
  pointer-events: none;
  opacity: 0;
}

.scale-in-leave-to {
  transform: scale(.55);
  pointer-events: none;
  opacity: 0;
}

.btn-primary.active, .btn-primary:active {
  background-color: #FFA500; /* Color de fondo cuando es pulsado */
  border-color: #FFA500; /* Color de borde cuando es pulsado */
}

.vue-editor__wrapper {
  height: 100px;
}

#editor1,
#editor2 {
  height: 550px;

  .custom-header th {
    font-size: 10px; /* Ajusta el tamaño de fuente según tus necesidades */
  }
}

/* Estilos para el reactivo de categorizacion*/
     .columns {
       display: flex;
     }

.column {
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}

.column-items {
  min-height: 50px;
  background-color: #f5f5f5;
  margin-top: 5px;
}

.item {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  margin: 5px;
  cursor: grab;
}

.unassigned {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}
/* FIN Estilos para el reactivo de categorizacion*/
</style>