<template>
  <div>
    <b-alert
        v-if="mostrarNotificacion"
        :variant="tipoNotificacion"
        :show="true"
        @dismissed="ocultarNotificacion"
        dismissible
    >
      <svg v-if="tipoNotificacion==='success'" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
           class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16"

      >
        <path
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
      </svg>

      <svg v-if="tipoNotificacion==='danger'" xmlns="http://www.w3.org/2000/svg" width="50"
           height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
      </svg>
      {{ mensajeNotificacion }}
    </b-alert>
  </div>
</template>

<script>
export default
{
  name: 'NotificacionModal',
  data () {
    return {
      mostrarNotificacion: false,
      mensajeNotificacion: '',
      tipoNotificacion: ''
    }
  },
  methods: {
    mostrar (mensaje, tipo, duracion) {
      this.mensajeNotificacion = mensaje
      this.tipoNotificacion = tipo
      this.mostrarNotificacion = true

      setTimeout(() => {
        this.mostrarNotificacion = false
      }, duracion)
    },
    ocultarNotificacion () {
      this.mostrarNotificacion = false
    }
  }
}
</script>
