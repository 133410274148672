<template>
  <b-container fluid>

<!-- ver correos del grupo-->
    <b-modal no-close-on-backdrop no-close-on-esc  centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             title= "Correos existentes en el grupo"
             v-model="visibleModalGruposCorreo"
    >
      <b-alert v-model="visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionModalMensaje"/>
      <b-card
          tag="article"
          class="mb-2">

        <b-card-text>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="nombre-grupo">Grupo:</label>
            </b-col>
            <b-col cols="5">
              <label for="titulo-grupo">{{tituloGrupo}}</label>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="total-grupo">Total correos:</label>
            </b-col>
            <b-col cols="5">
              <label for="total-grupo-calculo">{{dataTableModal != null ?  dataTableModal.length:0}}</label>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-end justify-content-end my-1">
            <div class="col-sm-12 my-2">
              <b-form-input
                  id="filter-input"
                  v-model="filterDataTableModal"
                  type="search"
                  placeholder="Buscar"
              >
              </b-form-input>
            </div>
          </b-row>
          <b-row>
            <b-col cols="12">

              <b-table
                  :items="dataTableModal"
                  :fields="dataTableModalGrupoCorreos"
                  :current-page="currentPageDataTableModal"
                  :per-page="perPageDataTableModal"
                  :filter="filterDataTableModal"
                  :filter-included-fields="filterOnDataTableModal"
              >

                <template #empty="scope">
                  <h5 class="text-center">
                    {{ scope.emptyText='Por el momento no existe información que mostrar.' }}
                  </h5>
                </template>
                <template #emptyfiltered="scope">
                  <h5 class="text-center">
                    {{ scope.emptyFilteredText='No existe información que coincida con los parámetros de búsqueda.' }}
                  </h5>
                </template>
              </b-table>
              <div class="d-flex justify-content-between row p-2">
                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                  <div>
                    <b-pagination
                        v-model="currentPageDataTableModal"
                        :total-rows="totalRowsModal"
                        :per-page="perPageDataTableModal"
                        align="fill"
                        size="sm"
                        class="my-0"
                    >
                    </b-pagination>
                  </div>
                </div>
              </div>
            </b-col>

          </b-row>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="visibleModalGruposCorreo=false"
                  :disabled="disabledBtnsCrearGrupo"
              >
                Cerrar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>

<!-- fin ver correos del grupo-->




    <!--modal crear grupo-->
    <b-modal no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="visibleModalCrearGrupo"
             title="Importar correos electrónicos"
             v-model="visibleModalCrearGrupo"
    >
      <b-alert v-model="visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionModalMensaje"/>
      <b-card
          tag="article"
          class="mb-2">

        <b-card-text>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="input-titulo">Nombre grupo:</label>
            </b-col>
            <b-col cols="8">
              <b-form-input
                  v-model="Form.nombre_grupo" id="nombre_grupo"
                  placeholder="Captura el nombre del grupo"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarGrupo()"
                  title="Guardar grupo"
                  :disabled="disabledBtnsCrearGrupo"
              >
                Guardar
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="visibleModalCrearGrupo=false"
                  :disabled="disabledBtnsCrearGrupo"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>
    <!-- fin modal  crear grupo-->

    <!--modal dataTable-->
    <b-modal no-close-on-backdrop no-close-on-esc  centered size="lg"
             hide-footer persistent
             ref="visibleModalDataTable"
             :title= tituloModalDataTable
             v-model="visibleModalDataTable"
    >
      <b-alert v-model="visibleModalConfirmacion" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <Notificacion ref="notificacionModalMensaje"/>
      <b-card
          tag="article"
          class="mb-2">

        <b-card-text>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="nombre-grupo">Grupo:</label>
            </b-col>
            <b-col cols="5">
              <label for="titulo-grupo">{{tituloGrupo}}</label>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col cols="4">
              <label for="input-titulo">Seleccionar importación:</label>
            </b-col>
            <b-col cols="8">
              <b-form-select
                  v-model="importacionSelected"
                  :options="dataImportaciones"
                  text-field="nombre_importacion"
                  value-field="uuid_importacion"
                  class="mb-3"
                  size="sm"
                  return-object
                  @change="getImportacionSelected(importacionSelected)"
              >
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-end justify-content-end my-1">
            <div class="col-sm-12 my-2">
              <b-form-input
                  id="filter-input"
                  v-model="filterDataTableModal"
                  type="search"
                  placeholder="Buscar"
              >
              </b-form-input>
            </div>
          </b-row>
          <b-row>
            <b-col cols="12">

              <b-table
                       :items="dataTableModal"
                       :fields="dataTableModalFields"
                       :current-page="currentPageDataTableModal"
                       :per-page="perPageDataTableModal"
                       :filter="filterDataTableModal"
                       :filter-included-fields="filterOnDataTableModal"
              >

                <template #empty="scope">
                  <h5 class="text-center">
                    {{ scope.emptyText='Por el momento no existe información que mostrar.' }}
                  </h5>
                </template>
                <template #emptyfiltered="scope">
                  <h5 class="text-center">
                    {{ scope.emptyFilteredText='No existe información que coincida con los parámetros de búsqueda.' }}
                  </h5>
                </template>
              </b-table>
              <div class="d-flex justify-content-between row p-2">
                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                  <div>
                    <b-pagination
                        v-model="currentPageDataTableModal"
                        :total-rows="totalRowsModal"
                        :per-page="perPageDataTableModal"
                        align="fill"
                        size="sm"
                        class="my-0"
                    >
                    </b-pagination>
                  </div>
                </div>
              </div>
            </b-col>

          </b-row>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="agregarCorreosImportacion()"
                  title="Agregar correos de importación"
                  :disabled="disabledBtnsCrearGrupo"
              >
                Agregar correos de importación
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="visibleModalDataTable=false"
                  :disabled="disabledBtnsCrearGrupo"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>
    <!--fin modal dataTable-->




    <!--modal confirmar eliminar imagen de respuesta-->
    <!--    <b-modal no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
                 hide-footer persistent
                 ref="visibleModalDataTable"
                 title="Confirmar eliminar pregunta"
                 v-model="visibleModalDataTable"
        >
          <b-alert v-model="visibleModalConfirmacion" variant="light" show>
            <b-button variant="primary" disabled>
              <b-spinner small type="grow"></b-spinner>
              {{ mensajeConfirmacion }}
            </b-button>
          </b-alert>
          <Notificacion ref="notificacionModalMensaje"/>
          <b-card
              tag="article"
              class="mb-2">
            <b-alert show variant="danger" class="p-3 text-justify">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                   class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
              </svg>
              ¿Desea eliminar la pregunta?
            </b-alert>
            <b-card-text>
              <b-row class="justify-content-end">
                <div class="d-flex align-items-right">
                  <b-button
                      variant="success"
                      size="sm"
                      class="float-right mx-2 my-1"
                      @click="eliminarPregunta()"
                      title="Eliminar pregunta"
                      :disabled="disabledBtnsEliminarPregunta"
                  >
                    Sí
                  </b-button>
                  <b-button
                      variant="primary"
                      size="sm"
                      class="float-right mx-2 my-1"
                      title="Cancelar"
                      @click="visibleModalDataTable=false"
                      :disabled="disabledBtnsEliminarPregunta"
                  >
                    No
                  </b-button>
                </div>
              </b-row>
            </b-card-text>
          </b-card>
        </b-modal>-->
    <!-- fin modal eliminar imagen de respuesta   -->
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body">
            <b-alert
                show
                variant="primary"
                dismissible>
              Grupos
            </b-alert>
            <!--ventana confirmacion general-->
            <b-alert v-model="modalConfirmacion" variant="light" show>
              <b-button variant="primary" disabled>
                <b-spinner small type="grow"></b-spinner>
                {{ mensajeConfirmacion }}
              </b-button>
            </b-alert>
            <Notificacion ref="notificacion"/>
            <!--cerrar ventana confirmacion general-->
          </div>
          <div class="col-sm-12 my-2">
            <div>
              <b-card no-body>
                <b-tabs card>
                  <!--INICIO TAB1-->
                  <b-tab title="Dashboard" active>
                    <b-card-text>
                      <b-form>
                        <b-row class="justify-content-end">
                          <b-col sm="12" >
                            <div class="col-sm-12">
                              <b-button
                                  variant="success"
                                  size="sm"
                                  @click="showModalCrearGrupo()"
                                  title="Crear grupo"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                     class="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                                </svg>
                              </b-button>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row class="d-flex align-items-end justify-content-end my-1">
                          <div class="col-sm-12 my-2">
                            <b-form-input
                                id="filter-input"
                                v-model="filterDataTable"
                                type="search"
                                placeholder="Buscar"
                            >
                            </b-form-input>
                          </div>
                        </b-row>
                        <b-row>
                          <b-col cols="12">

                            <b-table class="table data-table"
                                     :items="dataTable"
                                     :fields="dataTableFields"
                                     :current-page="currentPageDataTable"
                                     :per-page="perPageDataTable"
                                     :filter="filterDataTable"
                                     :filter-included-fields="filterOnDataTable"
                                     :striped="true"
                                     hover
                                     show-empty
                                     :small="true"
                            >
                              <template #empty="scope">
                                <h5 class="text-center">
                                  {{ scope.emptyText='Por el momento no hay preguntas que mostrar.' }}
                                </h5>
                              </template>
                              <template #emptyfiltered="scope">
                                <h5 class="text-center">
                                  {{ scope.emptyFilteredText='No hay preguntas que coincidan con los parámetros de búsqueda.' }}
                                </h5>
                              </template>
                              <template #cell(descripcion)="row">
                                <div v-html="row.value"></div>
                              </template>

                              <template #cell(actions)="row">
                                <div class="d-flex justify-content-around align-items-center">
                                  <b-button
                                      variant="primary"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="showModalDataTable(row.item)"
                                      class="mr-1"
                                      title="Agregar desde importación">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                                      <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z"/>                                    </svg>
                                  </b-button>

                                  <b-button
                                      variant="success"
                                      v-b-modal.modal-prevent-closing
                                      size="sm"
                                      @click="showModalCorreosGrupo(row.item)"
                                      class="mr-1"
                                      title="Ver correos del grupo">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    </svg>
                                  </b-button>

                                </div>
                              </template>

                            </b-table>
                            <div class="d-flex justify-content-between row p-2">
                              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div>
                                  <b-pagination
                                      v-model="currentPageDataTable"
                                      :total-rows="totalRows"
                                      :per-page="perPageDataTable"
                                      align="fill"
                                      size="sm"
                                      class="my-0"
                                  >
                                  </b-pagination>
                                </div>
                              </div>
                            </div>

                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>
                  </b-tab>
                  <!--FIN TAB1-->

                </b-tabs>
              </b-card>
              <!--FIN DEL CUERPO TABS-->
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import toast from "@/mixins/ToastMixin.js";
import Grupos from "@/services/Grupos";
import 'moment/locale/es';
import Notificacion from '@/components/Notificacion.vue'


export default {
  components: {
    Notificacion
  },
  mixins: [toast],
  data() {
    return {
      /* dataTable Modal*/
      selectedItems: [],
      visibleModalGruposCorreo:false,
      currentRowIndex: -1,
      dataTableModalGrupoCorreos: [
        {label: 'Correo', key: 'users.email', class: 'text-left'},

      ],




      dataTableModalFields: [
/*        {
          key: 'index',
          label: 'Index',
        },
        {label: '', key: 'selected', class: 'text-left'},
        {label: '#', key: 'id', class: 'text-left'},*/
        {label: 'Correo', key: 'email', class: 'text-left'},

      ],
      dataTableModal: [],
      totalRowsModal: 1,
      currentPageDataTableModal: 1,
      perPageDataTableModal: 9,
      filterDataTableModal: null,
      filterOnDataTableModal: [],
      tituloModalDataTable:null,
      selectedIds: [],
      /* dataTable Modal*/

      dataTableFields: [
        {label: '#', key: 'id', class: 'text-left'},
        {label: 'Grupo', key: 'nombre_grupo', class: 'text-left'},
        {label: 'Acciones', key: 'actions', class: 'text-center'}
      ],
      totalRows: 1,
      currentPageDataTable: 1,
      perPageDataTable: 5,
      filterDataTable: null,
      filterOnDataTable: [],
      //reactivos
      modalConfirmacion: false,
      mensajeConfirmacion: null,
      dataTable: [],
      activo: false,
      importacionSelected:null,
      idImportacionSelected:null,
      idGrupoSelected:null,
      dataImportaciones:[],
      visibleModalDataTable:false,
      visibleModalCrearGrupo:false,
      disabledBtnsCrearGrupo:false,
      visibleModalConfirmacion:false,
      uuid_preguntaEliminar:null,
      Form: {
        nombre_grupo: null,
      },
      tituloGrupo:null
    }
  },
  created() {

  },
  mounted() {
    this.getGrupos();
  },
  methods: {
    async guardarGrupo()
    {
      if (this.Form.nombre_grupo===null || this.Form.nombre_grupo.length === 0) {
        this.$refs.nombre_grupo.$el.focus();
        this.showNotificacion('¡Debe capturar el nombre del grupo!', 'danger');
        return false;
      }
      this.disabledBtnsCrearGrupo = true
      let formData = new FormData();
      formData.append("nombre_grupo", this.Form.nombre_grupo);
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.visibleModalConfirmacion = true
      this.disabledBtnGuardarPregunta = true
      const response = await Grupos.storeGrupo(formData);
      let {
        success,
        message,
        color,
        data,
        total
      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.dataTable = data
        this.totalRows = total;
      }
      this.visibleModalConfirmacion = false
      this.visibleModalCrearGrupo = false

      this.showNotificacion(message, color)
    },
    showModalCrearGrupo()
    {
      this.disabledBtnsCrearGrupo = false
      this.visibleModalCrearGrupo  = true
    },
    async showModalDataTable(item){
      this.mensajeConfirmacion ="Favor de esperar un momento, procesando información..."
      this.modalConfirmacion = true
      //console.log(item)
      this.dataImportaciones = []
      this.importacionSelected = null
      this.idGrupoSelected = null
      this.tituloGrupo = item.nombre_grupo
      this.idGrupoSelected = item.id
      const response = await Grupos.getImportaciones();
      this.dataImportaciones = response.data.data
      this.modalConfirmacion = false

      console.log("grupo selected---->")
      console.log(item)
      //console.log(item.users)
/*      this.filterOnDataTableModal = []
      this.dataTableModal = item.users
      this.totalRowsModal = this.dataTableModal.length*/
      this.tituloModalDataTable = "Agregar correos desde importación"
      this.visibleModalDataTable = true

    },

    async showModalCorreosGrupo(item){
      this.mensajeConfirmacion ="Favor de esperar un momento, procesando información..."
      this.modalConfirmacion = true
      //console.log(item)
      this.dataImportaciones = []
      this.importacionSelected = null
      this.idGrupoSelected = null
      this.tituloGrupo = item.nombre_grupo
      this.idGrupoSelected = item.id
      const response = await Grupos.getCorreosGrupo(this.idGrupoSelected)
      this.dataTableModal =  []
      this.dataTableModal = response.data.data
      this.modalConfirmacion = false

      this.totalRowsModal = this.dataTableModal != null ?  this.dataTableModal.length:0
      this.visibleModalGruposCorreo = true

    },










    getImportacionSelected(id){

      const importacionSelected = this.dataImportaciones.find((importacion) => importacion.uuid_importacion === id);
      this.idImportacionSelected = importacionSelected.id
      this.dataTableModal =  []
      this.dataTableModal = importacionSelected.users
      this.totalRowsModal = this.dataTableModal != null ?  this.dataTableModal.length:0
      //console.log(this.totalRowsModal)
    },
    async agregarCorreosImportacion(){
/*      console.log("id importacion")
      console.log(this.idImportacionSelected)

      console.log("id grupo")
      console.log(this.idGrupoSelected)*/
      let formData = new FormData();
      formData.append("idImportacion", this.idImportacionSelected);
      formData.append("idGrupo", this.idGrupoSelected);
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.visibleModalConfirmacion = true
      this.disabledBtnsCrearGrupo = true
      const response = await Grupos.storeGrupoImportacion(formData);
      let {
        success,
        message,
        color,

      } = response.data;
      this.modalConfirmacion = false
      if (success) {
        this.visibleModalDataTable = false
        //this.visibleModalCrearGrupo = false
      }
      this.showNotificacion(message, color)

    },
    showModalCorreosExistentes(item){
      // console.log(item.correos_existentes)
      this.filterOnDataTableModal = []
      this.dataTableModal = item.correos_existentes != null ? item.correos_existentes.map((email) => ({ email })): []
      this.totalRowsModal =  item.correos_existentes != null ? item.correos_existentes.count : 0
      this.tituloModalDataTable = "Correos existentes"
      this.visibleModalDataTable = true
    },
    isSelected(id) {
      return this.selectedIds.includes(id);
    },
    toggleSelection(id) {
      if (this.isSelected(id)) {
        this.selectedIds = this.selectedIds.filter((selectedId) => selectedId !== id);
      } else {
        this.selectedIds.push(id);
      }
      //console.log(this.selectedIds)
    },
    showNotificacion(message, tipo) {
      this.$refs.notificacion.mostrar(message, tipo, 3000)
    },

    async getGrupos() {
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      this.modalConfirmacion = true
      this.dataTable = []
      const response = await Grupos.getGrupos();

      this.modalConfirmacion = false
      this.dataTable = response.data.data;
      this.totalRows = response.data.totalRegistros;
    }
  }


}
</script>
<style lang="scss" scoped>
$studioAPPColor: #952f81;
.btn-studioAPP {
  color: $studioAPPColor !important;
  border: 1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active {
  background-color: #dadada !important;
  color: white !important;
}
.btn-primary.active, .btn-primary:active {
  background-color: #FFA500; /* Color de fondo cuando es pulsado */
  border-color: #FFA500; /* Color de borde cuando es pulsado */
}
.vue-editor__wrapper {
  height: 100px;
}
#editor1,
#editor2 {
  height: 550px;
}
</style>